
import { defineComponent, ref } from '@vue/composition-api'

import AuditForm from './audit-form.vue'
import { AuditInput, useCreateAudit } from '@/api/audits'
import { mdiPlus } from '@mdi/js'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

export default defineComponent({
  name: 'add-audit-dialog',
  components: {
    AuditForm,
  },
  setup: (_, { root, emit }) => {
    const { createAudit, isLoading } = useCreateAudit()

    const { addNotification } = useNotify()

    const addAuditForm = ref<ValidationForm | null>(null)

    const auditToAdd = ref<AuditInput>({
      auditee: null,
      auditor: root.$store.state.auth.currentUser.id,
      done: false,
      questionnaire: 0,
      scheduledDate: '',
      type: 'FIRST_AUDIT',
      zone: 0,
    })

    const addAudit = () => {
      if (addAuditForm.value?.validate()) {
        createAudit(auditToAdd.value)
          .then((newAudit) => {
            addNotification({
              type: 'success',
              text: root.$t('audits.create.success') as string,
            })
            emit('audit-added', newAudit)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('audits.create.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiPlus },
      isLoading,
      addAuditForm,
      auditToAdd,
      addAudit,
    }
  },
})
