
import { defineComponent, PropType } from '@vue/composition-api'

import { ZoneType, useDeleteZoneType } from '@/api/zoneTypes'
import { useNotify } from '@/store'

import { mdiDelete } from '@mdi/js'

export default defineComponent({
  name: 'delete-zone-type-dialog',
  props: {
    zoneType: {
      type: Object as PropType<ZoneType>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { deleteZoneType: deleteZoneTypeRequest, isLoading } = useDeleteZoneType()

    const { addNotification } = useNotify()

    const deleteZoneType = () => {
      deleteZoneTypeRequest(props.zoneType.id)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('zoneTypes.delete.success', {
              name: props.zoneType.name,
            }) as string,
          })
          emit('zone-type-deleted', props.zoneType)
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('zoneTypes.delete.error', {
            name: props.zoneType.name,
          })
          throw error
        })
    }

    return {
      icons: { mdiDelete },
      deleteZoneType,
      isLoading,
    }
  },
})
