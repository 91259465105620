
import { defineComponent, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { useCreateClient, ClientInput } from '@/api/clients'
import { ValidationForm } from '@/utils/validation'

import { mdiPlus } from '@mdi/js'

import ClientForm from './client-form.vue'

export default defineComponent({
  name: 'add-client-dialog',
  components: {
    ClientForm,
  },
  setup: (_, { root, emit }) => {
    const { createClient, isLoading } = useCreateClient()

    const { addNotification } = useNotify()

    const addClientForm = ref<ValidationForm | null>(null)

    const clientToAdd = ref<ClientInput>({
      name: '',
      auditingMode: 'FIVE_S',
      maxUsers: undefined,
    })

    const addClient = () => {
      if (addClientForm.value?.validate()) {
        createClient(clientToAdd.value)
          .then((newClient) => {
            addNotification({
              type: 'success',
              text: root.$t('clients.create.success', {
                name: newClient.name,
              }) as string,
            })
            emit('client-added', newClient)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('clients.create.error', {
              name: clientToAdd.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPlus },
      addClientForm,
      clientToAdd,
      addClient,
      isLoading,
    }
  },
})
