
import { defineComponent, ref, computed } from '@vue/composition-api'

import { tf } from '@/plugins/i18n'
import { useAuthGetters } from '@/store'
import { UserRole, useGetRoles } from '@/api/roles'
import { Rights } from '@/api/rights'
import { DataTableHeader } from 'vuetify'

import { mdiAccountPlus, mdiPencil, mdiDelete, mdiShieldLock } from '@mdi/js'
import { usePagination } from '@/utils/pagination'

import EditRoleDialog from './edit-role-dialog.vue'
import AddRoleDialog from './add-role-dialog.vue'
import DeleteRoleDialog from './delete-role-dialog.vue'

export default defineComponent({
  name: 'admin-roles-view',
  components: {
    EditRoleDialog,
    AddRoleDialog,
    DeleteRoleDialog,
  },
  setup(props, { root }) {
    const { getRoles: getRolesCall, data: roles, isLoading, paginationResponse } = useGetRoles()

    const totalRoles = computed(() => paginationResponse.value.totalElements)

    const { vuetifyTableOptions, paginationParams } = usePagination()

    const { hasRight } = useAuthGetters()
    const hasCreateRight = computed(() => hasRight.value(Rights.ROLE_CREATE))
    const hasUpdateRight = computed(() => hasRight.value(Rights.ROLE_UPDATE))
    const hasDeleteRight = computed(() => hasRight.value(Rights.ROLE_DELETE))

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const defaultHeaders: DataTableHeader[] = [
      {
        text: '',
        value: 'protected',
        sortable: false,
      },
      {
        text: root.$t('form.field.id') as string,
        value: 'id',
      },
      {
        text: root.$t('form.field.name') as string,
        value: 'name',
      },
      {
        text: root.$t('form.field.description') as string,
        value: 'description',
      },
      {
        text: root.$tc('form.field.right', 2) as string,
        value: 'rights',
        sortable: false,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const tableHeaders = computed<DataTableHeader[]>(() =>
      defaultHeaders.concat(
        hasUpdateRight.value || hasDeleteRight.value
          ? [
              {
                text: '',
                value: 'actions',
                sortable: false,
              },
            ]
          : []
      )
    )

    const getRoles = async () => {
      try {
        getRolesCall(paginationParams.value)
      } catch (error) {
        error.userMessage = root.$t('roles.get.error')

        throw error
      }
    }

    // Edit Role
    const isEditActive = ref(false)
    const roleToEdit = ref<UserRole | null>(null)

    const openEditDialog = (role: UserRole) => {
      if (hasUpdateRight.value) {
        roleToEdit.value = role
        isEditActive.value = true
      }
    }

    const onRoleEdited = (editedRole: UserRole) => {
      const index = roles.value.findIndex((role) => role.id === editedRole.id)
      if (index >= 0) {
        root.$set(roles.value, index, editedRole)
      }
      isEditActive.value = false
    }

    // Add Role
    const isCreateActive = ref(false)

    //Delete Role
    const isDeleteActive = ref(false)
    const roleToDelete = ref<UserRole | null>(null)

    const openDeleteDialog = (user: UserRole) => {
      if (hasDeleteRight.value) {
        roleToDelete.value = user
        isDeleteActive.value = true
      }
    }

    return {
      icons: { mdiAccountPlus, mdiPencil, mdiDelete, mdiShieldLock },
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      tableHeaders,
      vuetifyTableOptions,
      isLoading,
      roles,
      totalRoles,
      getRoles,
      isEditActive,
      roleToEdit,
      openEditDialog,
      onRoleEdited,
      isCreateActive,
      isDeleteActive,
      roleToDelete,
      openDeleteDialog,
      tf,
    }
  },
})
