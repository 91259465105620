import { computed } from '@vue/composition-api'
import store, { useClientsGetters } from '@/store'

export const useAuditingMode = () => {
  const { ownClient } = useClientsGetters(store)
  const isAuditingModeFiveS = computed(() => ownClient.value?.auditingMode === 'FIVE_S')

  return {
    isAuditingModeFiveS,
  }
}
