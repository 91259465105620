
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

import { useAuthGetters, useClientsActions } from '@/store'
import { User, useGetUsers } from '@/api/users'
import { Client } from '@/api/clients'
import { Rights } from '@/api/rights'
import { DataTableHeader } from 'vuetify'

interface TableHeader extends DataTableHeader {
  show: () => boolean
}

import { mdiAccountPlus, mdiPencil, mdiDelete } from '@mdi/js'

interface ClientWithAdmins extends Client {
  admins: User[]
}

export default defineComponent({
  name: 'admin-clients-view',
  components: {
    AddClientDialog: () => import('./add-client-dialog.vue'),
    EditClientDialog: () => import('./edit-client-dialog.vue'),
    DeleteClientDialog: () => import('./delete-client-dialog.vue'),
  },
  setup: (_, { root }) => {
    const { addClient, removeClient, updateClient } = useClientsActions(root.$store)
    const clients = computed<Client[]>(() => root.$store.state.clients.clients)
    const clientsLoading = computed<boolean>(() => root.$store.state.clients.isLoading)
    const adminsLoading = ref(false)
    const clientsWithAdmins = ref<ClientWithAdmins[]>([])

    const { getUsers } = useGetUsers()

    const { hasRights } = useAuthGetters(root.$store)
    const hasCreateRight = hasRights.value([Rights.CLIENT_CREATE])
    const hasUpdateRight = hasRights.value([Rights.CLIENT_UPDATE, Rights.CLIENT_CLIENT_UPDATE])
    const hasDeleteRight = hasRights.value([Rights.CLIENT_DELETE])

    const hasUserReadRight = hasRights.value([Rights.USER_READ, Rights.USER_CLIENT_READ])

    watch(
      clients,
      (val) => {
        if (hasUserReadRight) {
          clientsWithAdmins.value = []

          const promises = val.map((client) => getUsers({ page: 0, size: 9999 }, { client: client.id, role: 106 }))

          adminsLoading.value = true

          Promise.all(promises).then((users) => {
            for (const [index, client] of val.entries()) {
              clientsWithAdmins.value.push({ ...client, admins: users[index] })
            }

            adminsLoading.value = false
          })
        } else {
          clientsWithAdmins.value = val.map((client) => ({ ...client, admins: [] }))
        }
      },
      { immediate: true, deep: true }
    )

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const tableHeaders: TableHeader[] = [
      {
        text: root.$t('form.field.id') as string,
        value: 'id',
        show: () => true,
      },
      {
        text: root.$t('form.field.name') as string,
        value: 'name',
        show: () => true,
      },
      {
        text: root.$t('form.field.auditingMode') as string,
        value: 'auditingMode',
        show: () => true,
      },
      {
        text: root.$tc('form.field.admin', 2) as string,
        value: 'admins',
        show: () => hasUserReadRight,
      },
      {
        text: root.$tc('form.field.maxUsers', 2) as string,
        value: 'maxUsers',
        show: () => hasUserReadRight,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        show: () => hasUpdateRight || hasDeleteRight,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const filteredTableHeaders = tableHeaders.filter((header) => header.show())

    // Edit Client
    const isEditActive = ref(false)
    const clientToEdit = ref<Client | null>(null)

    const openEditDialog = (client: Client) => {
      if (hasUpdateRight) {
        clientToEdit.value = client
        isEditActive.value = true
      }
    }

    // Add Client
    const isCreateActive = ref(false)

    //Delete Client
    const isDeleteActive = ref(false)
    const clientToDelete = ref<Client | null>(null)

    const openDeleteDialog = (client: Client) => {
      if (hasDeleteRight) {
        clientToDelete.value = client
        isDeleteActive.value = true
      }
    }

    return {
      icons: { mdiAccountPlus, mdiPencil, mdiDelete },
      clientsLoading,
      adminsLoading,
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      filteredTableHeaders,
      clientsWithAdmins,
      isEditActive,
      clientToEdit,
      openEditDialog,
      isCreateActive,
      isDeleteActive,
      clientToDelete,
      openDeleteDialog,
      addClient,
      removeClient,
      updateClient,
    }
  },
})
