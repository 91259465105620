
import { computed, defineComponent, PropType, reactive, ref, toRefs } from '@vue/composition-api'

import { useAuthGetters } from '@/store'
import { useGetZoneTypes } from '@/api/clients'
import { useGetDivision, useGetDivisions } from '@/api/divisions'
import { ZoneInput } from '@/api/zones'
import { Client } from '@/api/clients'
import { Rights } from '@/api/rights'

import { required } from '@/utils/validation'

export default defineComponent({
  name: 'zone-form',
  props: {
    value: {
      type: Object as PropType<ZoneInput>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasDivisionReadRight = hasRights.value([
      Rights.DIVISION_READ,
      Rights.DIVISION_CLIENT_READ,
      Rights.DIVISION_DIVISION_READ,
    ])

    const zone = reactive(props.value)

    const updateZone = () => {
      emit('input', zone)
    }

    const clients = computed<Client[]>(() => root.$store.state.clients.clients)
    const selectedClient = ref<number | null>(zone.client ?? null)

    const { getDivisions, data: divisions } = useGetDivisions()

    const showClientDivisionSelect = ref(true)

    const { getZoneTypes, data: zoneTypes } = useGetZoneTypes()

    const onSelectedClientChanged = (clientId: number) => {
      zone.division = 0
      getDivisions(clientId)

      zone.type = 0
      getZoneTypes({ page: 0, size: 9999 }, clientId)

      updateZone()
    }

    const { getDivision } = useGetDivision()

    if (zone.division && zone.type) {
      showClientDivisionSelect.value = false

      //load zone types for the client of the selected division
      getDivision(zone.division).then((division) => getZoneTypes({ page: 0, size: 9999 }, division.client))
    } else {
      if (!hasClientReadRight) {
        selectedClient.value = root.$store.state.auth.currentUser.client
        getZoneTypes({ page: 0, size: 9999 }, root.$store.state.auth.currentUser.client)
      }
      if (!hasDivisionReadRight) {
        zone.division = root.$store.state.auth.currentUser.division
        updateZone()
      }
      if (!hasClientReadRight && hasDivisionReadRight) {
        getDivisions(root.$store.state.auth.currentUser.client)
      }
      if (hasClientReadRight && hasDivisionReadRight) {
        if (selectedClient.value !== null) {
          getDivisions(selectedClient.value)
          getZoneTypes({ page: 0, size: 9999 }, selectedClient.value)
        }
      }
    }

    return {
      ...toRefs(zone),
      showClientDivisionSelect,
      updateZone,
      required,
      hasClientReadRight,
      hasDivisionReadRight,
      selectedClient,
      clients,
      divisions,
      onSelectedClientChanged,
      zoneTypes,
    }
  },
})
