
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { InstanceAnswer } from './audit-summary.vue'
import { useAuditingMode, useDate } from '@/utils'

import { mdiThumbDown, mdiThumbUp, mdiCalendarClock, mdiAccountCircle } from '@mdi/js'
import { useGetActionsForAnswerAndAudit, useUpdateActionsForAnswerAndAudit } from '@/api/audits'
import { useGetUser, User } from '@/api/users'

export default defineComponent({
  name: 'answer-detail',

  props: {
    auditId: {
      type: Number,
      required: true,
    },
    instanceAnswer: {
      type: Object as PropType<InstanceAnswer>,
      required: true,
    },
    categoryIndex: {
      type: Number,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
  },

  setup: (props, { root }) => {
    const { addNotification } = useNotify()
    const { isAuditingModeFiveS } = useAuditingMode()
    const { toLocaleDateString } = useDate()

    const {
      getActionsForAnswerAndAudit,
      data: actions,
      isLoading: isActionsLoading,
    } = useGetActionsForAnswerAndAudit()

    const { updateActionsForAnswerAndAudit, isLoading: isActionsUpdateLoading } = useUpdateActionsForAnswerAndAudit()

    const { getUser } = useGetUser()
    const users = ref<{ [key: string]: User }>({})

    getActionsForAnswerAndAudit(props.auditId, props.instanceAnswer.answer.id).then((actions) => {
      const usersToFetch = [...new Set(actions.map((action) => action.responsibleUser))]

      usersToFetch.map((userId) =>
        userId
          ? getUser(userId).then((user) => {
              root.$set(users.value, user.id, user)
            })
          : ''
      )
    })

    const toggleAction = (toggledAction) => {
      updateActionsForAnswerAndAudit(props.auditId, props.instanceAnswer.answer.id, toggledAction)
        .then((editedAction) => {
          addNotification({
            type: 'success',
            text: root.$t('action.update.success', {
              name: editedAction.text,
            }) as string,
          })
        })
        .catch((error) => {
          error.userMessage = root.$t('action.update.error', {
            name: toggledAction.text,
          })
          const index = actions.value.indexOf(toggledAction)
          if (index) actions.value[index].done = !toggledAction.done
          throw error
        })
    }

    return {
      icons: { mdiThumbDown, mdiThumbUp, mdiCalendarClock, mdiAccountCircle },
      baseURL:
        process.env.VUE_APP_API_URL !== '/'
          ? process.env.VUE_APP_API_URL
          : `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
      images: props.instanceAnswer.answer.images,
      answer: props.instanceAnswer.answer,
      instance: props.instanceAnswer.instance,
      isAuditingModeFiveS,
      actions,
      isActionsLoading,
      users,
      toLocaleDateString,
      toggleAction,
      isActionsUpdateLoading,
    }
  },
})
