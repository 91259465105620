import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'

import { RootState } from '@/store'

import { RouteRecord } from 'vue-router'

type Types = 'info' | 'error' | 'success' | 'warning'

interface Action {
  text: string
  icon?: string
  type?: Types | 'primary'
  push?: RouteRecord
  onClick?: (...args: any) => void
}

export interface Notification {
  text: string
  timeout?: number
  type?: Types
  icon?: string
  actions?: Action[]
  sentryEventId?: string
}

export interface NotificationObject extends Notification {
  visible: boolean
  timeout: number
  id: number
}

export interface NotifyState {
  counter: number
  notifications: NotificationObject[]
}

export const state: NotifyState = {
  counter: 0,
  notifications: [],
}

export enum Getters {}

export const getters: GetterTree<NotifyState, RootState> = {}

enum Mutations {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
  INC_COUNTER = 'INC_COUNTER',
  SET_SENTRY_EVENT_ID = 'SET_SENTRY_EVENT_ID',
  SHIFT_NOTIFICATIONS = 'SHIFT_NOTIFICATIONS',
}

export const mutations: MutationTree<NotifyState> = {
  ADD_NOTIFICATION(state, { notification, id }: { notification: Notification; id: number }) {
    state.notifications.push({
      visible: true,
      id: id,
      text: notification.text,
      type: notification.type,
      icon: notification.icon,
      timeout: notification.timeout || 5000,
      actions: notification.actions || [],
      sentryEventId: notification.sentryEventId,
    })
  },
  DELETE_NOTIFICATION(state, id: number) {
    state.notifications = state.notifications.filter((notification) => notification.id !== id)
  },
  SHIFT_NOTIFICATIONS() {
    if (state.notifications.length) {
      state.notifications.shift()
    }
  },
  INC_COUNTER(state) {
    state.counter++
  },
}

export enum Actions {
  addNotification = 'notify/addNotification',
  deleteNotification = 'notify/deleteNotification',
  setSentryEventId = 'notify/setSentryEventId',
  shiftNotifications = 'notify/shiftNotifications',
}

export const actions: ActionTree<NotifyState, RootState> = {
  addNotification({ state, commit }, notification: Notification): number {
    const id = state.counter
    commit(Mutations.INC_COUNTER)
    commit(Mutations.ADD_NOTIFICATION, { notification, id })
    return id
  },
  deleteNotification({ commit }, id: number): void {
    commit(Mutations.DELETE_NOTIFICATION, id)
  },
  shiftNotifications({ commit }): void {
    commit(Mutations.SHIFT_NOTIFICATIONS)
  },
}

const notify: Module<NotifyState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default notify
