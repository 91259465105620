
import { defineComponent, computed, reactive, ref } from '@vue/composition-api'

import { meApi } from '@/api'
import { userToDto, UserUpdate, User } from '@/api/users'
import { useAuthActions, useLocaleActions } from '@/store'
import { ValidationForm, required, email as emailRule } from '@/utils/validation'

import { debounce } from 'lodash-es'
import { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiCheck } from '@mdi/js'

import CommonFlagIcon from '@/components/common/common-flag-icon.vue'
import { resolveCountry } from '@/plugins/i18n'

export default defineComponent({
  name: 'profile-card',
  components: {
    CommonFlagIcon,
  },
  setup(props, { root }) {
    const { updateCurrentUser } = meApi.useUpdateCurrentUser()
    const { setCurrentUser } = useAuthActions()
    const { setCurrentLocale } = useLocaleActions()

    const profileForm = ref<ValidationForm | null>(null)

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const currentUser = computed<User>(() => root.$store.state.auth.currentUser)

    let editUser: UserUpdate = reactive(userToDto(currentUser.value))

    const isDebouncing = ref(false)

    const debouncedEditCurrentUser = () => {
      isDebouncing.value = true
      editCurrentUser({ ...editUser })
    }

    const editCurrentUser = debounce<(updatedUser: UserUpdate) => void>((updatedUser: UserUpdate) => {
      if (profileForm.value?.validate()) {
        if (updatedUser.locale) setCurrentLocale(updatedUser.locale)
        updateCurrentUser({ ...updatedUser })
          .then((user) => {
            setCurrentUser(user)
          })
          .catch((error) => {
            error.userMessage = root.$t('profile.update.error')
            throw error
          })
          .finally(() => {
            isDebouncing.value = false
          })
      } else {
        isDebouncing.value = false
      }
    }, 500)

    return {
      icons: { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiCheck },
      profileForm,
      required,
      emailRule,
      setCurrentLocale,
      editUser,
      implementedLocales,
      debouncedEditCurrentUser,
      isDebouncing,
      resolveCountry,
    }
  },
})
