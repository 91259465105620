
import { Question, QuestionCategories, useGetQuestions } from '@/api/questions'
import { Rights } from '@/api/rights'
import {
  AddQuestionInput,
  QuestionInstanceWithQuestion,
  Questionnaire,
  useAddQuestion,
  useDeleteQuestion,
  useUpdateQuestions,
  useUpdateQuestion,
} from '@/api/questionnaires'
import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { useClientsGetters } from '@/store/modules/clients/useClientsModule'
import { useAuthGetters } from '@/store'
import { EnrichedUser } from '@/store/modules/auth'

import { roles } from '@/api/roles'

import Draggable from 'vuedraggable'

import { mdiClose, mdiChevronUp, mdiChevronDown, mdiPlus, mdiDelete, mdiPencil } from '@mdi/js'

export default defineComponent({
  name: 'question-category',

  components: {
    Draggable,
    AddQuestionDialog: () => import('@/views/questions/add-question-dialog.vue'),
    EditPoolQuestionDialog: () => import('@/views/questions/edit-question-dialog.vue'),
    DeletePoolQuestionDialog: () => import('@/views/questions/delete-question-dialog.vue'),
  },

  props: {
    questionnaire: {
      type: Object as PropType<Questionnaire>,
      required: true,
    },
    category: {
      type: String as PropType<QuestionCategories>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    questions: {
      type: Array as PropType<QuestionInstanceWithQuestion[]>,
      required: true,
    },
    poolQuestions: {
      type: Array as PropType<Question[]>,
      required: true,
    },
  },

  setup: (props, { root }) => {
    const { ownClient } = useClientsGetters(root.$store)
    const { addQuestion: addQuestionRequest } = useAddQuestion()
    const { deleteQuestion } = useDeleteQuestion()
    const { updateQuestions } = useUpdateQuestions()
    const { updateQuestion } = useUpdateQuestion()
    const { getQuestions, data: categoryPoolQuestions } = useGetQuestions()
    const { hasRights } = useAuthGetters(root.$store)

    const currentUser: EnrichedUser = root.$store.state.auth.currentUser
    const isAdmin = currentUser.role.name === roles.ADMIN ? true : false

    const hasAddQuestionRight = hasRights.value([Rights.QUESTION_CREATE, Rights.QUESTION_CLIENT_CREATE])
    const hasEditPoolQuestionRight = hasRights.value([Rights.QUESTION_UPDATE, Rights.QUESTION_CLIENT_UPDATE])
    const hasDeletePoolQuestionRight = hasRights.value([Rights.QUESTION_DELETE, Rights.QUESTION_CLIENT_DELETE])
    const hasEditQuestionnaireRight =
      hasRights.value([Rights.QUESTIONNAIRE_UPDATE, Rights.QUESTIONNAIRE_CLIENT_UPDATE]) &&
      (isAdmin || props.questionnaire.client)

    const sortInstances = (a: QuestionInstanceWithQuestion, b: QuestionInstanceWithQuestion) => {
      if (a.position > b.position) return 1
      else if (a.position < b.position) return -1
      else return 0
    }

    const innerQuestions = ref([...props.questions].sort(sortInstances).map((instance) => instance.template))
    const innerPoolQuestions = ref([...props.poolQuestions])

    const questionInstances: {
      [key: number]: QuestionInstanceWithQuestion
    } = reactive({})
    for (const questionInstanceWithQuestion of props.questions) {
      questionInstances[questionInstanceWithQuestion.template.id] = questionInstanceWithQuestion
    }

    //add PoolQuestion
    const isAddQuestionActive = ref(false)

    const onQuestionAdded = (question: Question) => {
      innerPoolQuestions.value.push(question)
    }

    const getPoolQuestions = () => {
      getQuestions(
        { page: 0, size: 9999 },
        {
          zoneType: props.questionnaire.zoneType,
          category: props.category,
        }
      )
        .then((categoryPoolQuestions) => (innerPoolQuestions.value = filterPoolQuestions(categoryPoolQuestions)))
        .catch((error) => {
          error.userMessage = root.$t('questions.get.error')

          throw error
        })
    }

    const filterPoolQuestions = (questions: Question[]) => {
      const questionsInQuestionnaireIds = innerQuestions.value.map((question) => question.id)
      return questions.filter((question) => !questionsInQuestionnaireIds.includes(question.id))
    }

    //edit PoolQuestion
    const isEditPoolQuestionActive = ref(false)
    const poolQuestionToEdit = ref<Question | null>(null)

    const openEditPoolQuestionDialog = (question: Question) => {
      if (hasEditPoolQuestionRight) {
        poolQuestionToEdit.value = question
        isEditPoolQuestionActive.value = true
      }
    }

    const onPoolQuestionEdited = () => {
      getPoolQuestions()
      isEditPoolQuestionActive.value = false
    }

    //delete PoolQuestion
    const isDeletePoolQuestionActive = ref(false)

    const poolQuestionToDelete = ref<Question | null>(null)

    const openDeletePoolQuestionDialog = (question: Question) => {
      if (hasDeletePoolQuestionRight) {
        poolQuestionToDelete.value = question
        isDeletePoolQuestionActive.value = true
      }
    }

    const onPoolQuestionDeleted = () => {
      getPoolQuestions()
      isDeletePoolQuestionActive.value = false
    }

    const isLoading = ref(false)

    //remove question from questionnaire
    const removeQuestion = (question: Question): Promise<any> => {
      return deleteQuestion(props.questionnaire.id, questionInstances[question.id].id)
        .then(() => {
          // Remove from Instance Object
          root.$delete(questionInstances, question.id)
        })
        .catch((error) => {
          error.userMessage = root.$t('questionnaire.removeQuestion.error')

          throw error
        })
    }

    const addQuestion = (question: Question, newIndex: number) => {
      return addQuestionRequest(props.questionnaire.id, {
        optional: true,
        position: newIndex,
        question: question.id,
      })
        .then((questionInstanceWithQuestion) => {
          root.$set(questionInstances, questionInstanceWithQuestion.template.id, questionInstanceWithQuestion)

          return questionInstanceWithQuestion
        })
        .catch((error) => {
          error.userMessage = root.$t('questionnaire.addQuestion.error')

          throw error
        })
    }

    const updatePositions = () => {
      // Data of Questions to Update
      const updatedQuestionInstances: (AddQuestionInput & {
        id: number
      })[] = []

      innerQuestions.value.forEach((questionToUpdate, index) => {
        const questionInstance = questionInstances[questionToUpdate.id]
        updatedQuestionInstances.push({
          id: questionInstance.id,
          optional: questionInstance.optional,
          position: index,
          question: questionToUpdate.id,
        })
      })

      return updateQuestions(props.questionnaire.id, updatedQuestionInstances)
        .then(() => {
          innerQuestions.value.forEach((questionToUpdate, index) => {
            questionInstances[questionToUpdate.id].position = index
          })
        })
        .catch((error) => {
          error.userMessage = root.$t('questionnaire.moveQuestion.error')

          throw error
        })
    }

    const onAddQuestionClicked = (question: Question, index: number) => {
      isLoading.value = true
      addQuestion(question, innerQuestions.value.length)
        .then((questionInstanceWithQuestion) => {
          innerQuestions.value.push(questionInstanceWithQuestion.template)
          innerPoolQuestions.value.splice(index, 1)
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const onDeleteQuestionClicked = (question: Question, index: number) => {
      isLoading.value = true
      // Remove question from innerQuestions
      innerQuestions.value.splice(index, 1)

      const promises: Promise<any>[] = [removeQuestion(question), updatePositions()]

      Promise.all(promises)
        .then(() => {
          // Add question back to the question pool
          innerPoolQuestions.value.push(question)

          // remove question instance
          root.$delete(questionInstances, question.id)
        })
        .catch((error) => {
          // readd question on error
          innerQuestions.value.splice(index, 0, question)

          throw error
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const flipQuestions = (indexA: number, indexB: number) => {
      isLoading.value = true
      // Flip Questions
      ;[innerQuestions.value[indexA], innerQuestions.value[indexB]] = [
        innerQuestions.value[indexB],
        innerQuestions.value[indexA],
      ]

      updatePositions()
        .catch((error) => {
          // Flip Questions to original position
          ;[innerQuestions.value[indexA], innerQuestions.value[indexB]] = [
            innerQuestions.value[indexB],
            innerQuestions.value[indexA],
          ]

          throw error
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const onMoveQuestionUpClicked = (index: number) => {
      flipQuestions(index - 1, index)
    }

    const onMoveQuestionDownClicked = (index: number) => {
      flipQuestions(index, index + 1)
    }

    const onInnerQuestionsChanged = (event: any) => {
      isLoading.value = true
      if (event.removed) {
        const promises: Promise<any>[] = [removeQuestion(event.removed.element), updatePositions()]

        Promise.all(promises)
          .then(() => {
            // remove question instance
            root.$delete(questionInstances, event.removed.element.id)
          })
          .catch(() => {
            // readd question on error
            innerQuestions.value.splice(event.removed.oldIndex, 0, event.removed.element)

            // Remove question from the question pool
            const poolIndex = innerPoolQuestions.value.findIndex(
              (question) => question.id === event.removed.element.id
            )
            if (typeof poolIndex === 'number') innerPoolQuestions.value.splice(poolIndex, 1)
          })
          .finally(() => {
            isLoading.value = false
          })
      } else if (event.moved) {
        updatePositions()
          .catch((error) => {
            innerQuestions.value.splice(event.moved.newIndex, 1)
            innerQuestions.value.splice(event.moved.oldIndex, 0, event.moved.element)

            throw error
          })
          .finally(() => {
            isLoading.value = false
          })
      } else if (event.added) {
        addQuestion(event.added.element, event.added.newIndex)
          .then(() => {
            updatePositions().finally(() => {
              isLoading.value = false
            })
          })
          .catch(() => {
            innerQuestions.value.splice(event.added.newIndex, 1)
            innerPoolQuestions.value.push(event.added.element)
          })
      }
    }

    const onOptionalClicked = (question: Question) => {
      isLoading.value = true
      updateQuestion(props.questionnaire.id, questionInstances[question.id].id, {
        position: questionInstances[question.id].position,
        question: question.id,
        optional: !questionInstances[question.id].optional,
      })
        .then((questionInstanceWithQuestion) => {
          questionInstances[question.id].optional = questionInstanceWithQuestion.optional
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    return {
      icons: { mdiClose, mdiChevronUp, mdiChevronDown, mdiPlus, mdiDelete, mdiPencil },
      isAuditingModeFiveS: computed(() => ownClient.value?.auditingMode === 'FIVE_S'),
      isDragging: ref(false),
      isAdmin,
      innerQuestions,
      innerPoolQuestions,
      hasAddQuestionRight,
      hasEditPoolQuestionRight,
      openEditPoolQuestionDialog,
      onPoolQuestionEdited,
      poolQuestionToEdit,
      hasDeletePoolQuestionRight,
      openDeletePoolQuestionDialog,
      onPoolQuestionDeleted,
      poolQuestionToDelete,
      hasEditQuestionnaireRight,
      isAddQuestionActive,
      isEditPoolQuestionActive,
      isDeletePoolQuestionActive,
      onQuestionAdded,
      isLoading,
      questionInstances,
      onAddQuestionClicked,
      onDeleteQuestionClicked,
      onInnerQuestionsChanged,
      onMoveQuestionUpClicked,
      onMoveQuestionDownClicked,
      onOptionalClicked,
    }
  },
})
