import vuexStore from '@/store'

import { Actions } from './index'

export const useLocaleActions = (store = vuexStore) => {
  const setCurrentLocale = (locale: string) => {
    store.dispatch(Actions.setCurrentLocale, locale)
  }

  const addLocale = (locale: string) => {
    store.dispatch(Actions.addLocale, locale)
  }

  const init = () => {
    store.dispatch(Actions.init)
  }

  return {
    setCurrentLocale,
    addLocale,
    init,
  }
}
