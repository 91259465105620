
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'common-footer',
  setup() {
    const currentYear = new Date().getFullYear()
    return { currentYear }
  },
})
