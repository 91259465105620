import { useAxios, useAxiosArray, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { BaseModelDeletable } from '.'
import { QuestionInstanceWithQuestion } from './questionnaires'

const version = 'v1'
const resource = 'audits'

export type AuditType = 'FIRST_AUDIT' | 'RE_AUDIT'

export interface AuditInput {
  auditee: number | null
  auditor: number
  done: boolean
  questionnaire: number
  scheduledDate: string
  type: AuditType
  zone: number
}
export interface Audit extends BaseModelDeletable {
  auditee: number | null
  auditor: number
  done: boolean
  scheduledDate: string
  type: AuditType
  zone: number
  signature: string
  actualDate: string
  questionnaire: {
    id: number
    name: string
  }
}

export interface AuditFilters {
  after?: string
  auditor?: number
  before?: string
  done?: boolean
  zone?: number
}

export const useGetAudits = () => {
  const axios = useAxiosPaginated<Audit>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getAudits = (paginationParams: PaginationParams, filters?: AuditFilters) =>
    axios.exec({ params: { ...paginationParams, ...filters } })

  return {
    ...axios,
    getAudits,
  }
}

export const useCreateAudit = () => {
  const axios = useAxios<Audit>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createAudit = (newAudit: AuditInput) => axios.exec({ data: newAudit })

  return {
    ...axios,
    createAudit,
  }
}

export const useGetAudit = () => {
  const axios = useAxios<Audit>({
    method: 'get',
  })

  const getAudit = (auditId: number) => axios.exec({ url: `/${version}/${resource}/${auditId}` })

  return {
    ...axios,
    getAudit,
  }
}

export const useUpdateAudit = () => {
  const axios = useAxios<Audit>({
    method: 'put',
  })

  const updateAudit = (auditId: number, audit: AuditInput) =>
    axios.exec({ url: `/${version}/${resource}/${auditId}`, data: audit })

  return {
    ...axios,
    updateAudit,
  }
}

export const useDeleteAudit = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteAudit = (auditId: number) => axios.exec({ url: `/${version}/${resource}/${auditId}` })

  return {
    ...axios,
    deleteAudit,
  }
}

export interface CommentInput {
  text: string
}

export interface Comment extends BaseModelDeletable, CommentInput {}

export interface AnswerInput {
  actions: number[]
  badPractice: boolean
  bestPractice: boolean
  comments: Comment[]
  images: string[]
  questionInstance: number
  rating: number
  skipped: boolean
}

export interface Answer extends BaseModelDeletable, AnswerInput {}

export const useGetAnswersForAudit = () => {
  const axios = useAxiosArray<Answer>({
    method: 'get',
  })

  const getAnswersForAudit = (auditId: number) => axios.exec({ url: `/${version}/${resource}/${auditId}/answers` })

  return {
    ...axios,
    getAnswersForAudit,
  }
}

export interface ActionInput {
  answer: number
  done: boolean
  responsibleUser: number
  text: string
  dueDate: string
}

export interface Action extends BaseModelDeletable, ActionInput {}

export interface ActionFilters {
  done?: boolean
}

export const useGetActionsForAnswerAndAudit = () => {
  const axios = useAxiosArray<Action>({ method: 'get' })

  const getActionsForAnswerAndAudit = (auditId: number, answerId: number, filters?: ActionFilters) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/actions`,
      params: { ...filters },
    })

  return {
    ...axios,
    getActionsForAnswerAndAudit,
  }
}

export const useCreateActionsForAnswerAndAudit = () => {
  const axios = useAxios<Action>({ method: 'post' })

  const createActionsForAnswerAndAudit = (auditId: number, answerId: number, actionInput: ActionInput) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/actions`,
      data: actionInput,
    })

  return {
    ...axios,
    createActionsForAnswerAndAudit,
  }
}

export const useUpdateActionsForAnswerAndAudit = () => {
  const axios = useAxios<Action>({ method: 'put' })

  const updateActionsForAnswerAndAudit = (auditId: number, answerId: number, action: Action) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/actions/${action.id}`,
      data: action,
    })

  return {
    ...axios,
    updateActionsForAnswerAndAudit,
  }
}

export const useDeleteActionsForAnswerAndAudit = () => {
  const axios = useAxios<void>({ method: 'delete' })

  const deleteActionsForAnswerAndAudit = (auditId: number, answerId: number, actionId: number) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/actions/${actionId}`,
    })

  return {
    ...axios,
    deleteActionsForAnswerAndAudit,
  }
}

export const useAddCommentForAnswerAndAudit = () => {
  const axios = useAxios<Comment>({ method: 'post' })

  const addCommentForAnswerAndAudit = (auditId: number, answerId: number, commentInput: CommentInput) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/comments`,
      data: commentInput,
    })

  return {
    ...axios,
    addCommentForAnswerAndAudit,
  }
}

export const useDeleteCommentForAnswerAndAudit = () => {
  const axios = useAxios<void>({ method: 'delete' })

  const deleteCommentForAnswerAndAudit = (auditId: number, answerId: number, commentId: number) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/comments/${commentId}`,
    })

  return {
    ...axios,
    deleteCommentForAnswerAndAudit,
  }
}

export interface ImageInput {
  fileId: string
}

export interface Image extends BaseModelDeletable, ImageInput {}

export const useAddImageForAnswerAndAudit = () => {
  const axios = useAxios<Image>({ method: 'post' })

  const addImageForAnswerAndAudit = (auditId: number, answerId: number, imageInput: ImageInput) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/images`,
      data: imageInput,
    })

  return {
    ...axios,
    addImageForAnswerAndAudit,
  }
}

export const useDeleteImageForAnswerAndAudit = () => {
  const axios = useAxios<void>({ method: 'delete' })

  const deleteImageForAnswerAndAudit = (auditId: number, answerId: number, imageId: string) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/answers/${answerId}/images/${imageId}`,
    })

  return {
    ...axios,
    deleteImageForAnswerAndAudit,
  }
}

export const useGetQuestionsForAudit = () => {
  const axios = useAxiosArray<QuestionInstanceWithQuestion>({ method: 'get' })

  const getQuestionsForAudit = (auditId: number) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/questions`,
    })

  return {
    ...axios,
    getQuestionsForAudit,
  }
}

export const useGetQuestionForAudit = () => {
  const axios = useAxios<QuestionInstanceWithQuestion>({ method: 'get' })

  const getQuestionForAudit = (auditId: number, questionId: number) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/questions/${questionId}`,
    })

  return {
    ...axios,
    getQuestionForAudit,
  }
}

export const useGetQuestionAnswerForAuditAndQuestion = () => {
  const axios = useAxiosArray<Answer>({ method: 'get' })

  const getQuestionAnswerForAuditAndQuestion = (auditId: number, questionId: number) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/questions/${questionId}/answer`,
    })

  return {
    ...axios,
    getQuestionAnswerForAuditAndQuestion,
  }
}

export const useSetQuestionAnswerForAuditAndQuestion = () => {
  const axios = useAxios<Answer>({ method: 'put' })

  const setQuestionAnswerForAuditAndQuestion = (auditId: number, questionId: number, answer: AnswerInput) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/questions/${questionId}/answer`,
      data: answer,
    })

  return {
    ...axios,
    setQuestionAnswerForAuditAndQuestion,
  }
}

export const useDeleteQuestionAnswerForAuditAndQuestion = () => {
  const axios = useAxios<void>({ method: 'delete' })

  const deleteQuestionAnswerForAuditAndQuestion = (auditId: number, questionId: number) =>
    axios.exec({
      url: `/${version}/${resource}/${auditId}/questions/${questionId}/answer`,
    })

  return {
    ...axios,
    deleteQuestionAnswerForAuditAndQuestion,
  }
}

export const useGetAuditReport = () => {
  const axios = useAxios({ method: 'get' })

  const getAuditReport = (auditId: number) => axios.exec({ url: `/${version}/${resource}/${auditId}/report` })

  return {
    ...axios,
    getAuditReport,
  }
}

export interface AuditSummary {
  numActions: number
  numAnswers: number
  numBadPractices: number
  numBestPractices: number
  numComments: number
  numQuestions: number
  rating: {
    categoryRating: {
      SORT: number
      SET_IN_ORDER: number
      SHINE: number
      STANDARDIZE: number
      SUSTAIN: number
    }
    meanRating: number
  }
}

export const useGetAuditSummary = () => {
  const axios = useAxios<AuditSummary>({ method: 'get' })

  const getAuditSummary = (auditId: number) => axios.exec({ url: `/${version}/${resource}/${auditId}/summary` })

  return {
    ...axios,
    getAuditSummary,
  }
}
