
import { defineComponent, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { NewUserRole, useCreateRole } from '@/api/roles'
import { Translation, useUpdateSingleTranslation } from '@/api/translations'
import { ValidationForm } from '@/utils/validation'

import { mdiCheck } from '@mdi/js'

import RoleForm from './role-form.vue'

export default defineComponent({
  name: 'add-role-dialog',
  components: {
    RoleForm,
  },
  setup(props, { root, emit }) {
    const { createRole, isLoading } = useCreateRole()

    const { addNotification } = useNotify()

    const addRoleForm = ref<ValidationForm | null>(null)

    const editRole = ref<NewUserRole>({
      name: '',
      description: '',
      rights: [],
    })

    const { updateSingleTranslation } = useUpdateSingleTranslation()
    const translations = ref<Translation[]>([])

    const addRole = () => {
      if (addRoleForm.value?.validate()) {
        const promises: Promise<any>[] = [
          createRole(editRole.value),
          ...translations.value.map((translation) => updateSingleTranslation(translation)),
        ]

        Promise.all(promises)
          .then((response) => {
            addNotification({
              type: 'success',
              text: root.$t('roles.create.success', {
                name: response[0].name,
              }) as string,
            })
            emit('role-added', response[0])
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('roles.create.error', {
              name: editRole.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      addRoleForm,
      editRole,
      addRole,
      isLoading,
      translations,
    }
  },
})
