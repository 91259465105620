var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 primary onPrimary--text"},[_vm._v(" "+_vm._s(_vm.$t('admin.users.title'))+" "),_c('v-spacer'),(_vm.hasCreateRight)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.isCreateActive = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('form.action.addUser'))+" ")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"hoverable":"","items":_vm.users,"loading":_vm.isLoading,"server-items-length":_vm.totalUsers,"options":_vm.vuetifyTableOptions},on:{"update:options":[function($event){_vm.vuetifyTableOptions=$event},function($event){return _vm.getUsers()}]},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.enabled ? _vm.icons.mdiCheck : _vm.icons.mdiClose))])]}},{key:"item.locale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("locale." + (item.locale))))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.clients[item.client] ? _vm.clients[item.client].name : '')+" ")]}},{key:"item.division",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.divisions[item.division] ? _vm.divisions[item.division].name : '')+" ")]}},{key:"item.zone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.zones[item.zone] ? _vm.zones[item.zone].name : '')+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tf(("roles.names." + (item.role.name)), item.role.name))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasUpdateRight)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e(),(_vm.hasDeleteRight)?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"scrollable":"","width":"1000"},model:{value:(_vm.isCreateActive),callback:function ($$v) {_vm.isCreateActive=$$v},expression:"isCreateActive"}},[(_vm.isCreateActive)?_c('add-user-dialog',{on:{"user-added":function($event){return _vm.getUsers()},"close":function($event){_vm.isCreateActive = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"scrollable":"","width":"1000"},model:{value:(_vm.isEditActive),callback:function ($$v) {_vm.isEditActive=$$v},expression:"isEditActive"}},[(_vm.isEditActive)?_c('edit-user-dialog',{attrs:{"user":_vm.userToEdit},on:{"user-edited":_vm.onUserEdited,"close":function($event){_vm.isEditActive = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.isDeleteActive),callback:function ($$v) {_vm.isDeleteActive=$$v},expression:"isDeleteActive"}},[(_vm.isDeleteActive)?_c('delete-user-dialog',{attrs:{"user":_vm.userToDelete},on:{"user-deleted":function($event){return _vm.getUsers()},"close":function($event){_vm.isDeleteActive = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }