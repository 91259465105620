import store from '@/store'

export const useDate = () => {
  const toLocaleDateString = (date: Date) =>
    date.toLocaleDateString(store.state.locale.currentLocale.replace('_', '-'), {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

  return { toLocaleDateString }
}
