
import { computed, defineComponent, ref } from '@vue/composition-api'

import { User } from '@/api/users'
import { mdiSend } from '@mdi/js'
import { useSendFeedback } from '@/api/sentry'
import { ValidationForm, required, email as emailRule } from '@/utils/validation'

export default defineComponent({
  name: 'sentry-feedback-dialog',
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { sendFeedback: sendSentryFeedback, isLoading } = useSendFeedback()

    // Form Ref
    const sendFeedbackForm = ref<ValidationForm | null>(null)

    const currentUser = computed<User | null>(() => root.$store.state.auth.currentUser)

    // Input Refs
    const name = ref(currentUser.value ? `${currentUser.value.firstName} ${currentUser.value.lastName}` : '')
    const email = ref(currentUser.value ? currentUser.value.email : '')
    const feedback = ref('')

    const sendFeedback = () => {
      if (sendFeedbackForm.value?.validate()) {
        sendSentryFeedback({
          event_id: props.eventId,
          name: name.value,
          email: email.value,
          comments: feedback.value,
        })
          .then(() => {
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('sentryFeedback.send.success')

            throw error
          })
      }
    }

    return {
      icons: { mdiSend },
      sendFeedbackForm,
      required,
      name,
      email,
      emailRule,
      feedback,
      isLoading,
      sendFeedback,
    }
  },
})
