
import { defineComponent, ref } from '@vue/composition-api'

import CommonAppBar from '@/layouts/common/app-bar.vue'
import CommonFooter from '@/layouts/common/common-footer.vue'
import Notify from '@/components/notify/notify.vue'

import { useGetQuestionnaires, Questionnaire } from '@/api/questionnaires'
import { useAuthGetters } from '@/store'
import { Rights } from '@/api/rights'

import { mdiPlus } from '@mdi/js'

import AddQuestionnaireDialog from '@/views/questionnaires/add-questionnaire-dialog.vue'

export default defineComponent({
  name: 'admin-layout',
  components: {
    CommonAppBar,
    CommonFooter,
    AddQuestionnaireDialog,
    Notify,
  },
  setup: (_, { root }) => {
    const { getQuestionnaires, data: questionnaires } = useGetQuestionnaires()
    const { hasRights } = useAuthGetters(root.$store)

    getQuestionnaires({ page: 0, size: 9999 })

    const hasQuestionnaireCreateRight = hasRights.value([
      Rights.QUESTIONNAIRE_CREATE,
      Rights.QUESTIONNAIRE_CLIENT_CREATE,
    ])

    const isCreateActive = ref(false)

    const onQuestionnaireAdded = (questionnaire: Questionnaire) => {
      questionnaires.value.push(questionnaire)
      root.$router.push(`/questionnaires/${questionnaire.id}`)
    }

    return {
      icons: { mdiPlus },
      isNavOpen: ref(true),
      breakpoint: root.$vuetify.breakpoint,
      questionnaires,
      hasQuestionnaireCreateRight,
      isCreateActive,
      onQuestionnaireAdded,
    }
  },
})
