import { useAxios, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { BaseModel } from '.'

const version = 'v1'
const resource = 'questions'

export enum QuestionCategories {
  SORT = 'SORT',
  SET_IN_ORDER = 'SET_IN_ORDER',
  SHINE = 'SHINE',
  STANDARDIZE = 'STANDARDIZE',
  SUSTAIN = 'SUSTAIN',
}

export interface QuestionInput {
  category: QuestionCategories
  client?: number
  description: string
  paragons: number[]
  text: string
  zoneType: number
}

export interface Question extends BaseModel, QuestionInput {
  deleted: boolean
}

export interface QuestionFilter {
  category?: QuestionCategories
  public?: boolean
  zoneType?: number
  client?: number
}

export const useGetQuestions = () => {
  const axios = useAxiosPaginated<Question>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getQuestions = (paginationParams: PaginationParams, filter: QuestionFilter = {}) =>
    axios.exec({ params: { ...paginationParams, ...filter } })

  return {
    ...axios,
    getQuestions,
  }
}

export const useGetQuestion = () => {
  const axios = useAxios<Question>({
    method: 'get',
  })

  const getQuestion = (questionId: number) => axios.exec({ url: `/${version}/${resource}/${questionId}` })

  return {
    ...axios,
    getQuestion,
  }
}

export const useCreateQuestion = () => {
  const axios = useAxios<Question>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createQuestion = (newQuestion: QuestionInput) => axios.exec({ data: newQuestion })

  return {
    ...axios,
    createQuestion,
  }
}

export const useUpdateQuestion = () => {
  const axios = useAxios<Question>({
    method: 'put',
  })

  const updateQuestion = (question: Question) =>
    axios.exec({ url: `/${version}/${resource}/${question.id}`, data: question })

  return {
    ...axios,
    updateQuestion,
  }
}

export const useDeleteQuestion = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteQuestion = (questionId: number) => axios.exec({ url: `/${version}/${resource}/${questionId}` })

  return {
    ...axios,
    deleteQuestion,
  }
}
