
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'

import { DataOptions } from 'vuetify'
import { TableHeader } from '@/types'
import { Audit } from '@/api/audits'
import { useGetUser, User } from '@/api/users'
import { useGetZone, Zone } from '@/api/zones'

import { useDate } from '@/utils'
import { mdiPencil, mdiDelete, mdiCheck, mdiClose, mdiAccount } from '@mdi/js'

export default defineComponent({
  name: 'audits-table',

  props: {
    audits: {
      type: Array as PropType<Audit[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
    options: {
      type: Object as PropType<DataOptions>,
      required: true,
    },
  },

  setup: (props, { root, emit }) => {
    const { toLocaleDateString } = useDate()

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const defaultHeaders: TableHeader[] = [
      {
        text: root.$t('form.field.id') as string,
        value: 'id',
        show: () => true,
      },
      {
        text: root.$t('form.field.questionnaire') as string,
        value: 'questionnaire',
        show: () => true,
      },
      {
        text: root.$t('form.field.auditor') as string,
        value: 'auditor',
        show: () => true,
      },
      {
        text: root.$t('form.field.auditee') as string,
        value: 'auditee',
        show: () => true,
      },
      {
        text: root.$tc('form.field.zone', 1) as string,
        value: 'zone',
        show: () => true,
      },
      {
        text: root.$t('form.field.scheduledDate') as string,
        value: 'scheduledDate',
        show: () => true,
      },
      {
        text: root.$t('form.field.done') as string,
        value: 'done',
        sortable: false,
        show: () => true,
      },
      {
        text: root.$tc('form.field.actualDate') as string,
        value: 'actualDate',
        show: () => true,
      },
      {
        text: root.$tc('form.field.auditType') as string,
        value: 'type',
        show: () => true,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const tableHeaders = defaultHeaders.filter((header) => header.show())

    const onRowClicked = (audit: Audit) => {
      root.$router.push(`/audits/${audit.id}`)
    }

    const vuetifyTableOptions = computed({
      get: () => props.options,
      set: (val) => {
        emit('update:options', val)
      },
    })

    const { getUser, isLoading: isUserLoading } = useGetUser()
    const { getZone, isLoading: isZoneLoading } = useGetZone()

    const zones = ref<{ [key: string]: Zone }>({})
    const users = ref<{ [key: string]: User }>({})

    watch(
      () => props.audits,
      (audits) => {
        const zonesToFetch = [...new Set(audits.map((audit) => audit.zone))]

        const usersToFetch = [
          ...new Set([...audits.map((audit) => audit.auditor), ...audits.map((audit) => audit.auditee)]),
        ]

        zonesToFetch.map((zoneId) =>
          zones.value[zoneId]
            ? ''
            : getZone(zoneId).then((zone) => {
                root.$set(zones.value, zone.id, zone)
              })
        )

        usersToFetch.map((userId) =>
          userId === null || users.value[userId]
            ? ''
            : getUser(userId).then((user) => {
                root.$set(users.value, user.id, user)
              })
        )
      },
      { deep: true }
    )

    return {
      icons: { mdiPencil, mdiDelete, mdiCheck, mdiClose, mdiAccount },
      toLocaleDateString,
      vuetifyTableOptions,
      tableHeaders,
      onRowClicked,
      isUserLoading,
      isZoneLoading,
      users,
      zones,
    }
  },
})
