import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'

import { RootState } from '@/store'

import { Client } from '@/api/clients'

export interface ClientsState {
  isLoading: boolean
  clients: Client[]
}

export const state: ClientsState = {
  isLoading: false,
  clients: [],
}

export enum Getters {
  ownClient = 'clients/ownClient',
}

export const getters: GetterTree<ClientsState, RootState> = {
  ownClient: (state, _, rootState) => {
    return state.clients.find((client) => client.id === rootState.auth.currentUser?.client)
  },
}

export enum Mutations {
  SET_LOADING = 'SET_LOADING',
  SET_CLIENTS = 'SET_CLIENTS',
  ADD_CLIENT = 'ADD_CLIENT',
  REMOVE_CLIENT = 'REMOVE_CLIENT',
  UPDATE_CLIENT = 'UPDATE_CLIENT',
}

export const mutations: MutationTree<ClientsState> = {
  SET_LOADING(state, newIsLoading: boolean) {
    state.isLoading = newIsLoading
  },
  SET_CLIENTS(state, clients: Client[]) {
    state.clients = clients
  },
  ADD_CLIENT(state, client: Client) {
    state.clients.push(client)
  },
  REMOVE_CLIENT(state, clientToRemove: Client) {
    const index = state.clients.findIndex((client) => client.id === clientToRemove.id)
    if (index >= 0) state.clients.splice(index, 1)
  },
  UPDATE_CLIENT(state, updatedClient: Client) {
    const index = state.clients.findIndex((client) => client.id === updatedClient.id)
    if (index >= 0) state.clients.splice(index, 1, updatedClient)
  },
}

export enum Actions {
  setLoading = 'clients/setLoading',
  setClients = 'clients/setClients',
  addClient = 'clients/addClient',
  removeClient = 'clients/removeClient',
  updateClient = 'clients/updateClient',
}

export const actions: ActionTree<ClientsState, RootState> = {
  setLoading({ commit }, newIsLoading: boolean) {
    commit(Mutations.SET_LOADING, newIsLoading)
  },
  setClients({ commit }, clients: Client[]) {
    commit(Mutations.SET_CLIENTS, clients)
  },
  addClient({ commit }, client: Client) {
    commit(Mutations.ADD_CLIENT, client)
  },
  removeClient({ commit }, client: Client) {
    commit(Mutations.REMOVE_CLIENT, client)
  },
  updateClient({ commit }, client: Client) {
    commit(Mutations.UPDATE_CLIENT, client)
  },
}

const clients: Module<ClientsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default clients
