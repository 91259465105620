
import { defineComponent, PropType } from '@vue/composition-api'

import { Division, useDeleteDivision } from '@/api/divisions'
import { useNotify } from '@/store'

import { mdiDelete } from '@mdi/js'

export default defineComponent({
  name: 'delete-division-dialog',
  props: {
    division: {
      type: Object as PropType<Division>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { deleteDivision: deleteDivisionRequest, isLoading } = useDeleteDivision()

    const { addNotification } = useNotify()

    const deleteDivision = () => {
      deleteDivisionRequest(props.division.id)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('divisions.delete.success', {
              name: props.division.name,
            }) as string,
          })
          emit('division-deleted', props.division)
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('divisions.delete.error', {
            name: props.division.name,
          })
          throw error
        })
    }

    return {
      icons: { mdiDelete },
      deleteDivision,
      isLoading,
    }
  },
})
