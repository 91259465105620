
import { defineComponent, PropType, reactive, toRefs } from '@vue/composition-api'

import { ZoneTypeInput } from '@/api/zoneTypes'

import { required } from '@/utils/validation'
import { useGetClients } from '@/api/clients'
import { useAuthGetters } from '@/store'
import { Rights } from '@/api/rights'

export default defineComponent({
  name: 'zone-type-form',
  props: {
    value: {
      type: Object as PropType<ZoneTypeInput>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const zoneType = reactive(props.value)

    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])

    const { exec: getClients, data: clients } = useGetClients()

    const updateZoneType = () => {
      emit('input', zoneType)
    }

    if (hasClientReadRight) {
      getClients()
    } else if (!zoneType.client) {
      zoneType.client = root.$store.state.auth.currentUser.clientId
      updateZoneType()
    }

    return {
      ...toRefs(zoneType),
      updateZoneType,
      required,
      clients,
      hasClientReadRight,
    }
  },
})
