
import { computed, defineComponent } from '@vue/composition-api'

import { mdiAccountTie, mdiFinance, mdiMonitorCellphoneStar, mdiFileQuestion, mdiHelp } from '@mdi/js'

import { useAuthGetters } from '@/store'
import routes from '@/router/routes'
import { Rights } from '@/api/rights'
import { User } from '@/api/users'

export default defineComponent({
  name: 'common-nav-tabs',
  setup: (_, { root }) => {
    const { hasRights } = useAuthGetters(root.$store)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const adminRights: Rights[] = routes
      .filter((route) => route.path === '/admin')![0]
      .children!.map((route) => {
        if (route.meta?.rights) return route.meta.rights
      })
      .flat()

    const hasAnyAdminRight = computed(() => hasRights.value(adminRights))

    const currentUser: User = root.$store.state.auth.currentUser

    const isAdmin = computed(() => currentUser?.role.name === 'ADMIN' )


    return {
      icons: {
        mdiAccountTie,
        mdiFinance,
        mdiMonitorCellphoneStar,
        mdiFileQuestion,
        mdiHelp,
      },
      hasAnyAdminRight,
      isAdmin,
      breakpoint: root.$vuetify.breakpoint,
    }
  },
})
