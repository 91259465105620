
import { defineComponent, PropType } from '@vue/composition-api'

import { Client, useDeleteClient } from '@/api/clients'
import { useNotify } from '@/store'

import { mdiDelete } from '@mdi/js'

export default defineComponent({
  name: 'delete-client-dialog',
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { deleteClient: deleteClientRequest, isLoading } = useDeleteClient()

    const { addNotification } = useNotify()

    const deleteClient = () => {
      deleteClientRequest(props.client.id)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('clients.delete.success', {
              name: props.client.name,
            }) as string,
          })
          emit('client-deleted', props.client)
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('clients.delete.error', {
            name: props.client.name,
          })
          throw error
        })
    }

    return {
      icons: { mdiDelete },
      deleteClient,
      isLoading,
    }
  },
})
