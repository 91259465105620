
import { defineComponent, ref } from '@vue/composition-api'

import { mdiAccountPlus } from '@mdi/js'

import AddAuditDialog from '@/views/audits/add-audit-dialog.vue'
import AuditsTable from './audits-table.vue'
import { useAuthGetters } from '@/store'
import { Rights } from '@/api/rights'
import { useGetAudits } from '@/api/audits'
import { usePagination } from '@/utils/pagination'

export default defineComponent({
  name: 'audits-view',

  components: {
    AddAuditDialog,
    AuditsTable,
  },

  setup: (_, { root }) => {
    const {
      getAudits: getAuditsRequest,
      data: audits,
      isLoading: isAuditsLoading,
      paginationResponse,
    } = useGetAudits()

    const { vuetifyTableOptions, paginationParams } = usePagination({
      page: root.$route.query.page ? +root.$route.query.page + 1 : 1,
      itemsPerPage: root.$route.query.size ? +root.$route.query.size : 15,
      sortBy: root.$route.query.sort ? [root.$route.query.sort.toString().split(',')[0]] : ['id'],
      sortDesc: root.$route.query.sort ? [root.$route.query.sort.toString().split(',')[1] === 'desc'] : [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true,
    })

    const { hasRights } = useAuthGetters()
    const hasCreateRight = hasRights.value([
      Rights.AUDIT_CREATE,
      Rights.AUDIT_CLIENT_CREATE,
      Rights.AUDIT_DIVISION_CREATE,
      Rights.AUDIT_ZONE_CREATE,
    ])
    const hasUpdateRight = hasRights.value([
      Rights.AUDIT_UPDATE,
      Rights.AUDIT_CLIENT_UPDATE,
      Rights.AUDIT_DIVISION_UPDATE,
      Rights.AUDIT_ZONE_UPDATE,
    ])
    const hasDeleteRight = hasRights.value([
      Rights.AUDIT_DELETE,
      Rights.AUDIT_CLIENT_DELETE,
      Rights.AUDIT_DIVISION_DELETE,
      Rights.AUDIT_ZONE_DELETE,
    ])

    const getAudits = () => {
      getAuditsRequest(paginationParams.value).then(() => {
        // don't update route query if its the save as pagination params (e.g. on reload of the site)
        if (
          paginationParams.value.page !== +root.$route.query.page ||
          paginationParams.value.size !== +root.$route.query.size ||
          paginationParams.value.sort !== root.$route.query.sort
        ) {
          root.$router.replace({ query: paginationParams.value as any })
        }
      })
    }

    const isCreateActive = ref(false)

    return {
      icons: { mdiAccountPlus },
      paginationResponse,
      vuetifyTableOptions,
      audits,
      isAuditsLoading,
      getAudits,
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      isCreateActive,
    }
  },
})
