
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { ZoneType, useUpdateZoneType } from '@/api/zoneTypes'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

import { mdiPencil } from '@mdi/js'

import ZoneTypeForm from './zone-type-form.vue'

export default defineComponent({
  name: 'edit-zone-type-dialog',
  components: {
    ZoneTypeForm,
  },
  props: {
    zoneType: {
      type: Object as PropType<ZoneType>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { updateZoneType, isLoading } = useUpdateZoneType()

    const { addNotification } = useNotify()

    const editZoneTypeForm = ref<ValidationForm | null>(null)

    const zoneTypeToEdit = ref<ZoneType>({ ...props.zoneType })

    const editZoneType = () => {
      if (editZoneTypeForm.value?.validate()) {
        updateZoneType(zoneTypeToEdit.value)
          .then((editedZoneType) => {
            addNotification({
              type: 'success',
              text: root.$t('zoneTypes.update.success', {
                name: editedZoneType.name,
              }) as string,
            })
            emit('zone-type-edited', editedZoneType)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('zoneTypes.update.error', {
              name: zoneTypeToEdit.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPencil },
      editZoneTypeForm,
      zoneTypeToEdit,
      editZoneType,
      isLoading,
    }
  },
})
