
import { defineComponent, PropType, computed, ref } from '@vue/composition-api'

import { UserRoleUpdate, useUpdateRole } from '@/api/roles'
import { Translation, useDeleteTranslation, useUpdateSingleTranslation } from '@/api/translations'
import { usersApi } from '@/api'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

import { mdiCheck } from '@mdi/js'

import RoleForm from './role-form.vue'

export default defineComponent({
  name: 'edit-role-dialog',
  components: {
    RoleForm,
  },
  props: {
    role: {
      type: Object as PropType<UserRoleUpdate>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { updateRole, isLoading } = useUpdateRole()
    const { getUsers, paginationResponse } = usersApi.useGetUsers()

    const { addNotification } = useNotify()

    const editRoleForm = ref<ValidationForm | null>(null)

    const roleToEdit = ref<UserRoleUpdate>({ ...props.role })

    // use total elements to count the amount of users for the given role
    getUsers(
      {
        page: 0,
        size: 1,
      },
      { role: props.role.id }
    )

    const usersWithRoleCount = computed(() => paginationResponse.value.totalElements)

    const { deleteTranslation } = useDeleteTranslation()
    const { updateSingleTranslation } = useUpdateSingleTranslation()
    const translations = ref<Translation[]>([])

    const editRole = () => {
      if (editRoleForm.value?.validate()) {
        const promises: Promise<any>[] = []

        promises.push(updateRole(roleToEdit.value))

        translations.value.forEach((translation) => promises.push(updateSingleTranslation(translation)))

        if (roleToEdit.value.name !== props.role.name)
          promises.push(deleteTranslation(`roles.names.${props.role.name}`))

        Promise.all(promises)
          .then((response) => {
            addNotification({
              type: 'success',
              text: root.$t('roles.update.success', {
                name: response[0].name,
              }) as string,
            })
            emit('role-edited', response[0])
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('roles.update.error', {
              name: roleToEdit.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      editRoleForm,
      roleToEdit,
      usersWithRoleCount,
      editRole,
      isLoading,
      translations,
    }
  },
})
