
import { defineComponent } from '@vue/composition-api'

import AppBar from './app-bar.vue'
import CommonFooter from '@/layouts/common/common-footer.vue'

import Notify from '@/components/notify/notify.vue'

export default defineComponent({
  name: 'public-layout',
  components: {
    AppBar,
    Notify,
    CommonFooter,
  },
})
