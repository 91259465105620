
import { defineComponent } from '@vue/composition-api'

import CommonAppBar from '@/layouts/common/app-bar.vue'
import CommonFooter from '@/layouts/common/common-footer.vue'
import Notify from '@/components/notify/notify.vue'

export default defineComponent({
  name: 'default-layout',
  components: { CommonAppBar, Notify, CommonFooter },
})
