
import { defineComponent, ref, PropType } from '@vue/composition-api'

import { useNotify } from '@/store'
import { useCreateDivision, DivisionInput, Division } from '@/api/divisions'
import { Client } from '@/api/clients'
import { ValidationForm } from '@/utils/validation'

import { mdiPlus } from '@mdi/js'

import DivisionForm from './division-form.vue'

export default defineComponent({
  name: 'add-division-dialog',
  components: {
    DivisionForm,
  },
  props: {
    parent: {
        type: Object as PropType<Division>,
        required: false,
      },
      client: {
        type: Number,
      }
  },

  setup: (props, { root, emit }) => {
    const { createDivision, isLoading } = useCreateDivision()

    const { addNotification } = useNotify()

    const addDivisionForm = ref<ValidationForm | null>(null)

    const divisionToAdd = ref<DivisionInput>({
      name: '',
      client: props.parent?.client ?? props.client ?? root.$store.state.auth.currentUser.client,
      parentDivision: props.parent?.id ?? null,
      responsibleUser: null,
      site: '',
    })

    const addDivision = () => {
      if (addDivisionForm.value?.validate()) {
        createDivision(divisionToAdd.value)
          .then((newDivision) => {
            addNotification({
              type: 'success',
              text: root.$t('divisions.create.success', {
                name: newDivision.name,
              }) as string,
            })
            emit('division-added', newDivision)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('divisions.create.error', {
              name: divisionToAdd.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPlus },
      addDivisionForm,
      divisionToAdd,
      addDivision,
      isLoading,
    }
  },
})
