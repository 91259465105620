
import { defineComponent, watch } from '@vue/composition-api'

import { clientsApi } from '@/api'
import { useAuthGetters, useClientsActions } from '@/store'

export default defineComponent({
  name: 'app',
  setup: (_, { root }) => {
    const { isAuthenticated } = useAuthGetters(root.$store)

    const { getClients } = clientsApi.useGetClients()
    const { setClients, setLoading: setClientsLoading } = useClientsActions(root.$store)

    /**
     * Initializes / clears data when the app is loaded or the authentication status changes
     */
    watch(
      isAuthenticated,
      (val) => {
        if (val) {
          setClientsLoading(true)
          getClients({ page: 0, size: 2000 })
            .then((clients) => {
              setClients(clients)
            })
            .catch((error) => {
              error.userMessage = root.$t('clients.get.error')

              throw error
            })
            .finally(() => {
              setClientsLoading(false)
            })
        } else {
          setClients([])
        }
      },
      { immediate: true }
    )
  },
})
