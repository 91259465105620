
import { computed, defineComponent, ref } from '@vue/composition-api'

import { useAuthGetters } from '@/store'
import { useGetZoneTypes, ZoneType } from '@/api/zoneTypes'
import { useGetClients, useGetZoneTypes as useGetZoneTypesForClient } from '@/api/clients'
import { Rights } from '@/api/rights'

import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js'
import { TableHeader } from '@/types'
import { usePagination } from '@/utils/pagination'

export default defineComponent({
  name: 'admin-zone-types-view',
  components: {
    AddZoneTypeDialog: () => import('./add-zone-type-dialog.vue'),
    EditZoneTypeDialog: () => import('./edit-zone-type-dialog.vue'),
    DeleteZoneTypeDialog: () => import('./delete-zone-type-dialog.vue'),
  },
  setup: (_, { root }) => {
    const { getZoneTypes: getZoneTypesRequest, isLoading: zoneTypesLoading, paginationResponse } = useGetZoneTypes()
    const {
      getZoneTypes: getZoneTypesForClientRequest,
      isLoading: zoneTypesForClientLoading,
      paginationResponse: paginationResponseForClient,
    } = useGetZoneTypesForClient()
    const { exec: getClients, data: clients } = useGetClients()

    const { vuetifyTableOptions, paginationParams } = usePagination()

    const totalElements = computed(() =>
      selectedClient.value ? paginationResponseForClient.value.totalElements : paginationResponse.value.totalElements
    )

    const zoneTypes = ref<ZoneType[]>([])
    const selectedClient = ref<number | null>(null)

    const getZoneTypes = () => {
      getZoneTypesRequest(paginationParams.value).then((newZoneTypes) => {
        zoneTypes.value = newZoneTypes
      })
    }

    const getZoneTypesForClient = (clientId: number) => {
      getZoneTypesForClientRequest(paginationParams.value, clientId).then((newZoneTypes) => {
        zoneTypes.value = newZoneTypes
      })
    }

    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasCreateRight = hasRights.value([Rights.ZONE_TYPE_CREATE, Rights.ZONE_TYPE_CLIENT_CREATE])
    const hasUpdateRight = hasRights.value([Rights.ZONE_TYPE_UPDATE, Rights.ZONE_TYPE_CLIENT_UPDATE])
    const hasDeleteRight = hasRights.value([Rights.ZONE_TYPE_DELETE, Rights.ZONE_TYPE_CLIENT_DELETE])

    const onClientSelected = (clientId: number | undefined) => {
      vuetifyTableOptions.value.page = 0
      if (clientId) {
        getZoneTypesForClient(clientId)
      } else {
        getZoneTypes()
      }
    }

    const onPaginationParamsChanged = () => {
      if (selectedClient.value) {
        getZoneTypesForClient(selectedClient.value)
      } else {
        getZoneTypes()
      }
    }

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const tableHeaders: TableHeader[] = [
      {
        text: root.$t('form.field.id') as string,
        value: 'id',
        show: () => true,
      },
      {
        text: root.$t('form.field.name') as string,
        value: 'name',
        show: () => true,
      },
      {
        text: root.$tc('form.field.client') as string,
        value: 'client',
        show: () => hasClientReadRight,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        show: () => hasUpdateRight || hasDeleteRight,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const filteredTableHeaders = tableHeaders.filter((header) => header.show())

    // Edit ZoneType
    const isEditActive = ref(false)
    const zoneTypeToEdit = ref<ZoneType | null>(null)

    const openEditDialog = (zoneType: ZoneType) => {
      if (hasUpdateRight) {
        zoneTypeToEdit.value = zoneType
        isEditActive.value = true
      }
    }

    const updateZoneType = (editedZone: ZoneType) => {
      const index = zoneTypes.value.findIndex((zone) => zone.id === editedZone.id)
      if (index >= 0) zoneTypes.value.splice(index, 1, editedZone)
    }

    // Add ZoneType
    const isCreateActive = ref(false)

    //Delete ZoneType
    const isDeleteActive = ref(false)
    const zoneTypeToDelete = ref<ZoneType | null>(null)

    const openDeleteDialog = (zoneType: ZoneType) => {
      if (hasDeleteRight) {
        zoneTypeToDelete.value = zoneType
        isDeleteActive.value = true
      }
    }

    // created
    if (hasClientReadRight) {
      getClients()
      getZoneTypes()
    } else {
      selectedClient.value = root.$store.state.auth.currentUser.client
      getZoneTypesForClient(root.$store.state.auth.currentUser.client)
    }

    return {
      icons: { mdiPlus, mdiPencil, mdiDelete },
      hasClientReadRight,
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      zoneTypes,
      clients,
      selectedClient,
      filteredTableHeaders,
      totalElements,
      vuetifyTableOptions,
      isEditActive,
      zoneTypeToEdit,
      openEditDialog,
      isCreateActive,
      isDeleteActive,
      zoneTypeToDelete,
      openDeleteDialog,
      onClientSelected,
      zoneTypesForClientLoading,
      zoneTypesLoading,
      onPaginationParamsChanged,
      updateZoneType,
    }
  },
})
