
import { defineComponent, PropType } from '@vue/composition-api'

import { useNotify } from '@/store'
import { Question, useDeleteQuestion } from '@/api/questions'

export default defineComponent({
  name: 'delete-question-dialog',
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
  },
  setup: (props, { emit, root }) => {
    const { deleteQuestion: deleteQuestionRequest, isLoading } = useDeleteQuestion()

    const { addNotification } = useNotify()

    const deleteQuestion = () => {
      deleteQuestionRequest(props.question.id)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('questions.delete.success') as string,
          })
          emit('question-deleted')
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('questions.delete.error')
          throw error
        })
    }

    return {
      deleteQuestion,
      isLoading,
    }
  },
})
