
import { computed, defineComponent, ref } from '@vue/composition-api'

import {
  mdiThemeLightDark,
  mdiWeatherSunny,
  mdiWeatherNight,
  mdiLogout,
  mdiAccountCircle,
  mdiDotsVertical,
} from '@mdi/js'
import { useAuthActions, useAuthGetters, useLocaleActions, useNotify } from '@/store'

import { User } from '@/api/users'

type Theme = 'dark' | 'light' | 'auto'

import CommonNavTabs from './nav-tabs.vue'

export default defineComponent({
  name: 'app-bar',
  components: {
    CommonNavTabs,
  },
  props: {
    isNavOpen: {
      type: Boolean,
      required: false,
    },
    hasNavDrawer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup: (props, { root }) => {
    const { isAuthenticated } = useAuthGetters()

    const { setCurrentLocale } = useLocaleActions()

    const currentLocale = computed(() => root.$store.state.locale.currentLocale)
    const implementetLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const theme = ref<Theme>('light')

    const themeButton = computed(() => {
      if (theme.value === 'auto') {
        return { title: 'Theme Auto', icon: mdiThemeLightDark }
      } else if (theme.value === 'light') {
        return { title: 'Theme Light', icon: mdiWeatherSunny }
      } else {
        return { title: 'Theme Dark', icon: mdiWeatherNight }
      }
    })

    const prefersDark = ref(window.matchMedia('(prefers-color-scheme: dark)').matches)

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    darkModeMediaQuery.addListener((e) => {
      prefersDark.value = e.matches
    })

    const themeAuto = () => {
      localStorage.removeItem('theme')
      theme.value = 'auto'
      if (prefersDark.value) {
        root.$vuetify.theme.dark = true
      } else {
        root.$vuetify.theme.dark = false
      }
    }

    const themeDark = () => {
      theme.value = 'dark'
      localStorage.setItem('theme', 'dark')
      root.$vuetify.theme.dark = true
    }

    const themeLight = () => {
      theme.value = 'light'
      localStorage.setItem('theme', 'light')
      root.$vuetify.theme.dark = false
    }

    const initTheme = () => {
      const theme = localStorage.getItem('theme')
      if (theme === 'dark') themeDark()
      else if (theme === 'light') themeLight()
      else themeAuto()
    }

    initTheme()

    const toggleTheme = () => {
      if (theme.value === 'auto') themeLight()
      else if (theme.value === 'light') themeDark()
      else if (theme.value === 'dark') themeAuto()
    }

    const { logout: logoutAction } = useAuthActions()
    const { addNotification } = useNotify()

    const logout = () => {
      logoutAction().then(() => {
        addNotification({
          type: 'success',
          text: String(root.$t('logout.success')),
        })

        if (root.$route.name !== 'home') root.$router.push('/')
      })
    }

    const currentUser = computed((): User | null => root.$store.state.auth.currentUser)

    const title = computed(() => (root.$route.meta?.title ? root.$t(root.$route.meta?.title) : ''))

    return {
      icons: {
        mdiLogout,
        mdiAccountCircle,
        mdiDotsVertical,
      },
      title,
      isAuthenticated,
      currentLocale,
      setCurrentLocale,
      implementetLocales,
      themeButton,
      theme,
      toggleTheme,
      logout,
      currentUser,
      breakpoint: root.$vuetify.breakpoint,
    }
  },
})
