
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'navigation-drawer',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup: (_, { root }) => {
    return {
      breakpoint: root.$vuetify.breakpoint,
    }
  },
})
