
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { Client, useUpdateClient } from '@/api/clients'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

import { mdiAccountEdit } from '@mdi/js'

import ClientForm from './client-form.vue'

export default defineComponent({
  name: 'edit-client-dialog',
  components: {
    ClientForm,
  },
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { updateClient, isLoading } = useUpdateClient()

    const { addNotification } = useNotify()

    const editClientForm = ref<ValidationForm | null>(null)

    const clientToEdit = ref<Client>({ ...props.client })

    const editClient = () => {
      if (editClientForm.value?.validate()) {
        updateClient(clientToEdit.value)
          .then((editedClient) => {
            addNotification({
              type: 'success',
              text: root.$t('clients.update.success', {
                name: editedClient.name,
              }) as string,
            })
            emit('client-edited', editedClient)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('clients.update.error', {
              name: clientToEdit.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiAccountEdit },
      editClientForm,
      clientToEdit,
      editClient,
      isLoading,
    }
  },
})
