import { useAxios, useAxiosArray, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { BaseModel } from '.'
import { Division } from './divisions'
import { Question, QuestionFilter } from './questions'
import { Zone } from './zones'
import { ZoneType } from './zoneTypes'

const version = 'v1'
const resource = 'clients'

export type AuditingMode = 'FIVE_A' | 'FIVE_S'

export interface ClientInput {
  auditingMode: AuditingMode
  name: string
  maxUsers?: number
}

export interface Client extends BaseModel, ClientInput {}

export const useGetClients = () => {
  const axios = useAxiosPaginated<Client>({
    method: 'get',
    url: `/${version}/${resource}`,
    params: {
      page: 0,
      size: 200,
    },
  })

  const getClients = (paginationParams: PaginationParams) => axios.exec({ params: { ...paginationParams } })

  return {
    ...axios,
    getClients,
  }
}

export const useCreateClient = () => {
  const axios = useAxios<Client>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createClient = (newClient: ClientInput) => axios.exec({ data: newClient })

  return {
    ...axios,
    createClient,
  }
}

export const useGetClient = () => {
  const axios = useAxios<Client>({
    method: 'get',
  })

  const getClient = (clientId: number) => axios.exec({ url: `/${version}/${resource}/${clientId}` })

  return {
    ...axios,
    getClient,
  }
}

export const useUpdateClient = () => {
  const axios = useAxios<Client>({
    method: 'put',
  })

  const updateClient = (client: Client) => axios.exec({ url: `/${version}/${resource}/${client.id}`, data: client })

  return {
    ...axios,
    updateClient,
  }
}

export const useDeleteClient = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteClient = (clientId: number) => axios.exec({ url: `/${version}/${resource}/${clientId}` })

  return {
    ...axios,
    deleteClient,
  }
}

export interface UpdateClientPartialInput {
  id: number
  name?: string
  auditingMode?: AuditingMode
}

export const useUpdateClientPartial = () => {
  const axios = useAxios<Client>({
    method: 'delete',
  })

  const updateClientPartial = (client: UpdateClientPartialInput) =>
    axios.exec({ url: `/${version}/${resource}/${client.id}`, data: client })

  return {
    ...axios,
    updateClientPartial,
  }
}

export const useGetDivisions = () => {
  const axios = useAxiosArray<Division>({ method: 'get' })

  const getDivisions = (clientId: number) => axios.exec({ url: `/${version}/${resource}/${clientId}/divisions` })

  return {
    ...axios,
    getDivisions,
  }
}

export const useGetDivisionZones = () => {
  const axios = useAxiosArray<Zone>({ method: 'get' })

  const getDivisionZones = (clientId: number, divisionId: number) =>
    axios.exec({ url: `/${version}/${resource}/${clientId}/divisions/${divisionId}/zones` })

  return {
    ...axios,
    getDivisionZones,
  }
}

export const useGetZoneTypes = () => {
  const axios = useAxiosPaginated<ZoneType>({ method: 'get' })

  const getZoneTypes = (paginationParams: PaginationParams, clientId: number) =>
    axios.exec({ url: `/${version}/${resource}/${clientId}/zonetypes`, params: { ...paginationParams } })

  return {
    ...axios,
    getZoneTypes,
  }
}

export const useGetQuestions = () => {
  const axios = useAxiosPaginated<Question>({ method: 'get' })

  const getQuestions = (paginationParams: PaginationParams, clientId: number, filter: QuestionFilter = {}) =>
    axios.exec({ url: `/${version}/${resource}/${clientId}/questions`, params: { ...paginationParams, ...filter } })

  return {
    ...axios,
    getQuestions,
  }
}
