
import { computed, defineComponent, PropType, reactive, toRefs } from '@vue/composition-api'

import { DivisionInput } from '@/api/divisions'
import { useGetDivisions } from '@/api/divisions'

import { required } from '@/utils/validation'
import { useAuthGetters } from '@/store'
import { Rights } from '@/api/rights'
import { Client } from '@/api/clients'

export default defineComponent({
  name: 'division-form',
  props: {
    value: {
      type: Object as PropType<DivisionInput>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const division = reactive(props.value)

    const updateDivision = () => {
      emit('input', division)
    }

    const clients = computed<Client[]>(() => root.$store.state.clients.clients)

    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])

    const { getDivisions, data: divisions } = useGetDivisions()

    if (!hasClientReadRight) {
      division.client = root.$store.state.auth.currentUser.client
      updateDivision()
      getDivisions(division.client)
    } else {
      if (typeof division.client === 'number') getDivisions(division.client)
    }

    const onSelectedClientChanged = (clientId: number) => {
      getDivisions(clientId)
    }

    return {
      ...toRefs(division),
      updateDivision,
      required,
      hasClientReadRight,
      divisions,
      clients,
      onSelectedClientChanged,
    }
  },
})
