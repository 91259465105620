import { useAxios } from '@/utils/axios'

const version = 'v1'
const resource = 'translations'

export interface Translation {
  key: string
  value: string
  locale: string
}

export interface TranslationGroup {
  key: string
  translations: Translation[]
}

export interface TranslationMap {
  [key: string]: Translation[]
}

export interface TranslationNestedMap {
  [key: string]: TranslationMap
}

export const useGetTranslations = () => {
  const axios = useAxios<TranslationMap>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getTranslations = () => axios.exec()

  return {
    ...axios,
    getTranslations,
  }
}

export const useGetLocales = () => {
  const axios = useAxios<TranslationNestedMap>({
    method: 'get',
    url: `/${version}/${resource}/locales`,
  })

  const getLocales = () => axios.exec()

  return {
    ...axios,
    getLocales,
  }
}

export const useUpdateTranslation = () => {
  const axios = useAxios<Translation>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const updateTranslation = (translation: Translation) => axios.exec({ data: translation })

  return {
    ...axios,
    updateTranslation,
  }
}

export const useUploadTranslationsFile = () => {
  const axios = useAxios<void>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const uploadTranslationsFile = (file: File, locale: string) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('locale', locale)

    return axios.exec({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  return {
    ...axios,
    uploadTranslationsFile,
  }
}

export const useUpdateSingleTranslation = () => {
  const axios = useAxios<Translation>({
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
  })

  const updateSingleTranslation = (translation: Translation) =>
    axios.exec({
      url: `/${version}/${resource}/${translation.locale}/${translation.key}`,
      data: translation.value,
    })

  return {
    ...axios,
    updateSingleTranslation,
  }
}

export const useDeleteTranslation = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteTranslation = (translationKey: string) =>
    axios.exec({ url: `/${version}/${resource}/delete/${translationKey}` })

  return {
    ...axios,
    deleteTranslation,
  }
}
