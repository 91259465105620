<script>
  import { computed, defineComponent, ref } from '@vue/composition-api'

  import {
    mdiAccount,
    mdiTranslate,
    mdiShieldAccount,
    mdiAccountGroup,
    mdiAccountSupervisor,
    mdiGraph,
    mdiViewDashboard,
    mdiShape,
  } from '@mdi/js'

  import CommonAppBar from '@/layouts/common/app-bar.vue'
  import CommonNavigationDrawer from '@/layouts/common/navigation-drawer.vue'
  import CommonFooter from '@/layouts/common/common-footer.vue'
  import Notify from '@/components/notify/notify.vue'

  import { useAuthGetters } from '@/store'
  import routes from '@/router/routes'

  export default defineComponent({
    name: 'admin-layout',
    components: {
      CommonAppBar,
      CommonNavigationDrawer,
      CommonFooter,
      Notify,
    },
    setup: () => {
      const { hasRights } = useAuthGetters()

      const adminRoutes = routes.find((route) => route.path === '/admin')?.children

      const navItems = [
        {
          title: 'admin.users.title',
          to: '/admin/users',
          icon: mdiAccount,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'users').meta.rights),
        },
        {
          title: 'admin.translations.title',
          to: '/admin/translations',
          icon: mdiTranslate,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'translations').meta.rights),
        },
        {
          title: 'admin.roles.title',
          to: '/admin/roles',
          icon: mdiAccountGroup,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'roles').meta.rights),
        },
        {
          title: 'admin.rights.title',
          to: '/admin/rights',
          icon: mdiShieldAccount,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'rights').meta.rights),
        },
        {
          title: 'admin.clients.title',
          to: '/admin/clients',
          icon: mdiAccountSupervisor,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'clients').meta.rights),
        },
        {
          title: 'admin.divisions.title',
          to: '/admin/divisions',
          icon: mdiGraph,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'divisions').meta.rights),
        },
        {
          title: 'admin.zones.title',
          to: '/admin/zones',
          icon: mdiViewDashboard,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'zones').meta.rights),
        },
        {
          title: 'admin.zoneTypes.title',
          to: '/admin/zoneTypes',
          icon: mdiShape,
          isShown: () => hasRights.value(adminRoutes.find((route) => route.path === 'zoneTypes').meta.rights),
        },
      ]

      const filteredNavItems = computed(() => navItems.filter((navItem) => navItem.isShown()))

      return {
        isNavOpen: ref(true),
        filteredNavItems,
      }
    },
  })
</script>

<template>
  <v-app>
    <common-app-bar :is-nav-open.sync="isNavOpen" has-nav-drawer />

    <notify />

    <common-navigation-drawer v-model="isNavOpen">
      <v-list subheader>
        <v-subheader>
          {{ $t('admin.title') }}
        </v-subheader>

        <v-list-item v-for="navItem in filteredNavItems" :key="navItem.title" :to="navItem.to">
          <v-list-item-icon>
            <v-icon>
              {{ navItem.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(navItem.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </common-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
    <common-footer />
  </v-app>
</template>

<style scoped></style>
