/* eslint-disable @typescript-eslint/no-non-null-assertion */
import vuexStore from '@/store'
import { computed } from '@vue/composition-api'

import { User } from '@/api/users'
import { LoginCredentials } from '@/api/auth'
import { Rights } from '@/api/rights'

import { Actions, Getters } from './index'

export const useAuthGetters = (store = vuexStore) => {
  const userRights = computed((): string[] => store.getters[Getters.userRights])

  const isAuthenticated = computed((): boolean => store.getters[Getters.isAuthenticated])

  const hasRight = computed((): ((right: Rights) => boolean) => store.getters[Getters.hasRight])
  const hasRights = computed((): ((rights: Rights[], every?: boolean) => boolean) => store.getters[Getters.hasRights])

  return {
    userRights,
    isAuthenticated,
    hasRight,
    hasRights,
  }
}

export const useAuthActions = (store = vuexStore) => {
  const getCurrentUser = (): Promise<void> => {
    return store.dispatch(Actions.getCurrentUser)
  }

  const setCurrentUser = (user: User | null): void => {
    store.dispatch(Actions.setCurrentUser, user)
  }

  const login = (credentials: LoginCredentials): Promise<void> => {
    return store.dispatch(Actions.login, credentials)
  }

  const logout = (): Promise<void> => {
    return store.dispatch(Actions.logout)
  }

  const init = () => {
    store.dispatch(Actions.init)
  }

  return {
    getCurrentUser,
    setCurrentUser,
    login,
    logout,
    init,
  }
}
