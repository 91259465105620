
import { defineComponent, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { useCreateZone, ZoneInput } from '@/api/zones'
import { ValidationForm } from '@/utils/validation'

import { mdiPlus } from '@mdi/js'

import ZoneForm from './zone-form.vue'

export default defineComponent({
  name: 'add-zone-dialog',
  components: {
    ZoneForm,
  },
  props: {
      client: {
        type: Number,
        required: false,
      },
      division: {
        type: Number,
      }
  },
  setup: (props, { root, emit }) => {
    const { createZone, isLoading } = useCreateZone()

    const { addNotification } = useNotify()

    const addZoneForm = ref<ValidationForm | null>(null)

    const zoneToAdd = ref<ZoneInput>({
      client: props.client,
      name: '',
      unofficialName: '',
      division: props.division ?? 0,
      responsibleUser: null,
      type: 0,
    })

    const addZone = () => {
      if (addZoneForm.value?.validate()) {
        createZone(zoneToAdd.value)
          .then((newZone) => {
            addNotification({
              type: 'success',
              text: root.$t('zones.create.success', {
                name: newZone.name,
              }) as string,
            })
            emit('zone-added', newZone)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('zones.create.error', {
              name: zoneToAdd.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPlus },
      addZoneForm,
      zoneToAdd,
      addZone,
      isLoading,
    }
  },
})
