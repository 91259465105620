
import { defineComponent, PropType } from '@vue/composition-api'

import { Zone, useDeleteZone } from '@/api/zones'
import { useNotify } from '@/store'

import { mdiDelete } from '@mdi/js'

export default defineComponent({
  name: 'delete-zone-dialog',
  props: {
    zone: {
      type: Object as PropType<Zone>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { deleteZone: deleteZoneRequest, isLoading } = useDeleteZone()

    const { addNotification } = useNotify()

    const deleteZone = () => {
      deleteZoneRequest(props.zone.id)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('zones.delete.success', {
              name: props.zone.name,
            }) as string,
          })
          emit('zone-deleted', props.zone)
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('zones.delete.error', {
            name: props.zone.name,
          })
          throw error
        })
    }

    return {
      icons: { mdiDelete },
      deleteZone,
      isLoading,
    }
  },
})
