import { i18n } from '@/plugins/i18n'

export interface ValidationForm {
  reset: () => void
  resetValidation: () => void
  validate: () => boolean
}

export const required = (value: any, name = '') => !!value || (i18n.t('form.validation.required', { name }) as string)

export const email = (value: string, name = '') => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return value ? pattern.test(value) || (i18n.t('form.validation.email', { name }) as string) : true
}

export const passwordsMatch = (password: string, repeatPassword: string) =>
  password === repeatPassword || (i18n.t('form.validation.passwordsMatch') as string)

export const isPasswordStrong = (value: string) => {
  /*
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
  return pattern.test(value)
  */
 return value.length > 8 || (i18n.t('form.validation.passwordStrength') as string)
}
