
import { defineComponent, PropType } from '@vue/composition-api'

import { useNotify } from '@/store'
import { User, useDeleteUser } from '@/api/users'

export default defineComponent({
  name: 'delete-user-dialog',
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup: (props, { emit, root }) => {
    const { deleteUser: deleteUserRequest, isLoading } = useDeleteUser()

    const { addNotification } = useNotify()

    const deleteUser = () => {
      deleteUserRequest(props.user.id)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('users.delete.success', {
              name: `${props.user.firstName} ${props.user.lastName}`,
            }) as string,
          })
          emit('user-deleted')
          emit('close')
        })
        .catch((error) => {
          error.userMessage = root.$t('users.delete.error', {
            name: `${props.user.firstName} ${props.user.lastName}`,
          })
          throw error
        })
    }

    return {
      deleteUser,
      isLoading,
    }
  },
})
