
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { User, UserUpdate, useUpdateUser, userToDto } from '@/api/users'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

import { mdiCheck } from '@mdi/js'

import UserForm from './user-form.vue'

export default defineComponent({
  name: 'admin-user-detail',
  components: {
    UserForm,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()
    const { updateUser, isLoading } = useUpdateUser()

    const editUserForm = ref<ValidationForm | null>(null)

    const userToEdit = ref<UserUpdate>(userToDto(props.user))

    const editUser = () => {
      if (editUserForm.value?.validate()) {
        const userFullName = `${userToEdit.value.firstName} ${userToEdit.value.lastName}`

        updateUser(props.user.id as number, {
          ...userToEdit.value,
          client: userToEdit.value.client,
          division: userToEdit.value.division,
        })
          .then((editedUser) => {
            addNotification({
              type: 'success',
              text: root.$t('users.update.success', {
                name: userFullName,
              }) as string,
            })
            emit('user-edited', editedUser)
          })
          .catch((error) => {
            error.userMessage = root.$t('users.update.error', {
              name: userFullName,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      editUserForm,
      userToEdit,
      editUser,
      isLoading,
    }
  },
})
