import { useAxios } from '@/utils/axios'

const version = 'v1'
const resource = 'rating_graph'

export type EntityType = 'CLIENT' | 'DIVISION' | 'ZONE'

export interface Entity {
  entityId: number
  entityName: string
  entityType: EntityType
  weight: number
  rating: {
    meanRating: number
    categoryRating: {
      SET_IN_ORDER: number
      SHINE: number
      SORT: number
      STANDARDIZE: number
      SUSTAIN: number
    }
  }
  children: Array<ClientEntity | DivisionEntity | ZoneEntity> | null
}

export interface ClientEntity extends Entity {
  entityType: 'CLIENT'
  children: Array<DivisionEntity>
}

export interface DivisionEntity extends Entity {
  entityType: 'DIVISION'
  children: Array<DivisionEntity | ZoneEntity>
}

export interface ZoneEntity extends Entity {
  entityType: 'ZONE'
  children: null
}

export const useGetRatingGraph = () => {
  const axios = useAxios<ClientEntity | DivisionEntity | ZoneEntity>({
    method: 'get',
  })

  const getRatingGraph = (entityId: number, entityType: EntityType, auditedOnly = false) =>
    axios.exec({ url: `/${version}/${resource}`, params: { entityId, entityType, auditedOnly } })

  return {
    ...axios,
    getRatingGraph,
  }
}
