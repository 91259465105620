import { useAxios, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { UserRole } from './roles'

const version = 'v1'
const resource = 'users'

export interface User {
  id: number
  username: string
  updatedBy: string
  updatedAt: string
  enabled: boolean
  email: string
  firstName: string
  lastName: string
  locale: string
  phone: string
  mobile: string
  role: UserRole

  client: number
  division: number
  zone: number
  image: null
}

export interface UserUpdate {
  id?: number
  username?: string
  email?: string
  firstName?: string
  lastName?: string
  locale?: string
  phone?: string
  mobile?: string
  enabled?: boolean
  role?: number
  password?: string

  client?: number
  division?: number
  zone?: number
  image?: null
}

export function userToDto(user: User): UserUpdate {
  return {
    id: user.id,
    username: user.username,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    locale: user.locale,
    phone: user.phone,
    mobile: user.mobile,
    enabled: user.enabled,
    role: user.role.id,
    client: user.client,
    division: user.division,
    zone: user.zone,
    image: user.image,
  }
}

export interface UserFilterParams {
  client?: number
  division?: number
  email?: string
  name?: string
  role?: number | string
  zone?: number
}

export const useGetUsers = () => {
  const axios = useAxiosPaginated<User>({
    method: 'get',
    url: `${version}/${resource}`,
  })

  const getUsers = (paginationParams?: PaginationParams, params?: UserFilterParams) =>
    axios.exec({ params: { ...paginationParams, ...params } })

  return {
    ...axios,
    getUsers,
  }
}

export const useGetUser = () => {
  const axios = useAxios<User>({
    method: 'get',
  })

  const getUser = (userId: number) => axios.exec({ url: `${version}/${resource}/${userId}` })

  return {
    ...axios,
    getUser,
  }
}

export const useCreateUser = () => {
  const axios = useAxios<User>({
    method: 'post',
    url: `${version}/${resource}`,
  })

  const createUser = (user: UserUpdate) => axios.exec({ data: user })

  return {
    ...axios,
    createUser,
  }
}
export const useUpdateUser = () => {
  const axios = useAxios<User>({
    method: 'put',
  })

  const updateUser = (userId: number, user: UserUpdate) =>
    axios.exec({ url: `${version}/${resource}/${userId}`, data: user })

  return {
    ...axios,
    updateUser,
  }
}
export const useDeleteUser = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteUser = (userId: number) => axios.exec({ url: `${version}/${resource}/${userId}` })

  return {
    ...axios,
    deleteUser,
  }
}
