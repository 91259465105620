
import { Answer, Audit, useGetAnswersForAudit, useGetAuditSummary, useGetQuestionsForAudit } from '@/api/audits'
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'

import { QuestionCategories } from '@/api/questions'
import { useClientsGetters } from '@/store'
import { QuestionInstanceWithQuestion } from '@/api/questionnaires'

import {
  mdiCommentText,
  mdiThumbUp,
  mdiThumbDown,
  mdiCheckboxMarked,
  mdiCamera,
  mdiAccount,
  mdiArrowLeft,
} from '@mdi/js'

import { useDate } from '@/utils'
import { useGetUser, User } from '@/api/users'
import { useGetZone } from '@/api/zones'

import AnswerDetail from './answer-detail.vue'

export interface InstanceAnswer {
  instance: QuestionInstanceWithQuestion
  answer: Answer
}

export default defineComponent({
  name: 'audit-summary',

  components: {
    AnswerDetail,
  },

  props: {
    audit: {
      type: Object as PropType<Audit>,
      required: true,
    },
  },

  setup: (props, { root }) => {
    const { toLocaleDateString } = useDate()
    const { ownClient } = useClientsGetters(root.$store)

    const { getAnswersForAudit, data: answers, isLoading: isAnswersLoading } = useGetAnswersForAudit()
    const { getAuditSummary, data: summary, isLoading: isAuditLoading } = useGetAuditSummary()
    const { getQuestionsForAudit, data: questionInstances, isLoading: isQuestionsLoading } = useGetQuestionsForAudit()
    const { getUser, isLoading: isUserLoading } = useGetUser()
    const { getZone, data: zone, isLoading: isZoneLoading } = useGetZone()

    const isInitialDataLoading = computed(
      () =>
        isAnswersLoading.value ||
        isAuditLoading.value ||
        isQuestionsLoading.value ||
        isUserLoading.value ||
        isZoneLoading.value
    )

    const answeredSummary = reactive({
      overallAnswered: 0,
      overall: 25,
      SORT: {
        total: 5,
        answered: 0,
        questions: [] as InstanceAnswer[],
      },
      SET_IN_ORDER: {
        total: 5,
        answered: 0,
        questions: [] as InstanceAnswer[],
      },
      SHINE: {
        total: 5,
        answered: 0,
        questions: [] as InstanceAnswer[],
      },
      STANDARDIZE: {
        total: 5,
        answered: 0,
        questions: [] as InstanceAnswer[],
      },
      SUSTAIN: {
        total: 5,
        answered: 0,
        questions: [] as InstanceAnswer[],
      },
    })

    watch(isInitialDataLoading, (val) => {
      if (!val && answers && questionInstances) {
        // Returns only valid answers
        const answerFilterAnswered = (answer: Answer) =>
          answer.skipped !== true && answer.rating !== 0 && answer.deleted !== true
        const questionInstancesAnswered = answers.value
          .filter(answerFilterAnswered)
          .map((answer) => answer.questionInstance)

        const answeredIncludesFilter = (id: number) => questionInstancesAnswered.includes(id)

        // returns questionInstanceIds of given Categories
        const mapQuestions = (category: string) =>
          questionInstances.value
            .filter((questionInstance) => questionInstance.template.category === category)
            .sort((a, b) => (a.position > b.position ? 1 : -1))

        answeredSummary.overall = answers.value.length
        answeredSummary.overallAnswered = questionInstancesAnswered.length

        for (const category of questionCategories) {
          const categoryQuestions = mapQuestions(category)

          const instanceIds = categoryQuestions.map((questionInstance) => questionInstance.id)

          answeredSummary[category].total = instanceIds.length
          answeredSummary[category].answered = instanceIds.filter(answeredIncludesFilter).length
          answeredSummary[category].questions = categoryQuestions.map((questionInstance) => ({
            instance: questionInstance,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            answer: answers.value.find((answer) => answer.questionInstance === questionInstance.id)!,
          }))
        }
      }
    })

    getAnswersForAudit(props.audit.id)
    getAuditSummary(props.audit.id)
    getQuestionsForAudit(props.audit.id)

    // Get Auditor
    const auditor = ref<User | null>(null)
    getUser(props.audit.auditor).then((user) => {
      auditor.value = user
    })

    // Get Auditee
    const auditee = ref<User | null>(null)
    if (props.audit.auditee) {
      getUser(props.audit.auditee).then((user) => {
        auditee.value = user
      })
    }

    // Get Zone
    getZone(props.audit.zone)

    const getRatingColor = (rating: number) => {
      if (rating < 2.5) return 'error'
      else if (rating < 4) return 'warning'
      else return 'success'
    }

    const questionCategories = [
      QuestionCategories.SORT,
      QuestionCategories.SET_IN_ORDER,
      QuestionCategories.SHINE,
      QuestionCategories.STANDARDIZE,
      QuestionCategories.SUSTAIN,
    ]

    const onAnswerClicked = (category: any, categoryIndex: number, questionIndex: number) => {
      selectedCategoryIndex.value = categoryIndex
      selectedQuestionIndex.value = questionIndex
      selectedInstanceAnswer.value = answeredSummary[category].questions[questionIndex]
      isDetailViewOpen.value = true
    }

    const isDetailViewOpen = ref(false)

    const selectedInstanceAnswer = ref<InstanceAnswer>()
    const selectedCategoryIndex = ref(0)
    const selectedQuestionIndex = ref(0)

    return {
      icons: { mdiCommentText, mdiThumbUp, mdiThumbDown, mdiCheckboxMarked, mdiCamera, mdiAccount, mdiArrowLeft },
      isAuditingModeFiveS: computed(() => ownClient.value?.auditingMode === 'FIVE_S'),
      isInitialDataLoading,
      isDetailViewOpen,
      answers,
      summary,
      questionInstances,
      QuestionCategories,
      getRatingColor,
      answeredSummary,
      questionCategories,
      onAnswerClicked,
      toLocaleDateString,
      auditor,
      auditee,
      zone,
      selectedInstanceAnswer,
      selectedCategoryIndex,
      selectedQuestionIndex,
    }
  },
})
