import { useAxios, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { UserRight } from './rights'

const version = 'v1'
const resource = 'roles'

export const roles = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  USER_PLUS: 'USER_PLUS',
  AUDITOR: 'AUDITOR',
  INTERNAL_AUDITOR: 'INTERNAL_AUDITOR',
  ZONE_ADMIN: 'ZONE_ADMIN',
  DIVISION_ADMIN: 'DIVISION_ADMIN',
  CLIENT_ADMIN: 'CLIENT_ADMIN',
}

export interface NewUserRole {
  name: string
  description?: string
  rights: number[]
}

export interface UserRole {
  id: number
  name: string
  description?: string
  rights: UserRight[]
  protected: boolean
}

export interface UserRoleUpdate extends NewUserRole {
  id: number
  protected: boolean
}

export interface UserRoleDisplay extends UserRole {
  displayName: string
}

export const useGetRoles = () => {
  const axios = useAxiosPaginated<UserRole>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getRoles = (paginationParams?: PaginationParams) => axios.exec({ params: paginationParams })

  return {
    ...axios,
    getRoles,
  }
}

export const useGetRole = () => {
  const axios = useAxios<UserRole>({
    method: 'get',
  })

  const getRole = (roleId: string) => axios.exec({ url: `/${version}/${resource}/${roleId}` })

  return {
    ...axios,
    getRole,
  }
}
export const useCreateRole = () => {
  const axios = useAxios<UserRole>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createRole = (role: NewUserRole) => axios.exec({ data: role })

  return {
    ...axios,
    createRole,
  }
}
export const useUpdateRole = () => {
  const axios = useAxios<UserRole>({
    method: 'put',
  })

  const updateRole = (role: UserRoleUpdate) => axios.exec({ url: `/${version}/${resource}/${role.id}`, data: role })

  return {
    ...axios,
    updateRole,
  }
}
export const useDeleteRole = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteRole = (roleId: number) => axios.exec({ url: `/${version}/${resource}/${roleId}` })

  return {
    ...axios,
    deleteRole,
  }
}
