
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AuditInput } from '@/api/audits'
import { computed, defineComponent, PropType, reactive, ref, toRefs } from '@vue/composition-api'

import { mdiCalendar } from '@mdi/js'
import { useDate } from '@/utils'
import { User, useGetUsers } from '@/api/users'
import { useGetClients, useGetDivisionZones } from '@/api/clients'
import { useGetDivisions } from '@/api/divisions'
import { useAuthGetters } from '@/store'
import { Rights } from '@/api/rights'
import { roles } from '@/api/roles'
import { required } from '@/utils/validation'
import { useGetQuestionnaires } from '@/api/questionnaires'
import { EnrichedUser } from '@/store/modules/auth'
import { useGetZone } from '@/api/zones'

interface UserWithFullName extends User {
  fullName: string
}

export default defineComponent({
  name: 'audit-form',

  props: {
    value: {
      type: Object as PropType<AuditInput>,
      required: true,
    },
    isCreate: {
      type: Boolean,
      required: false,
    },
  },

  setup: (props, { root, emit }) => {
    // Utils
    const { toLocaleDateString } = useDate()

    // Api Methods
    const { getUsers } = useGetUsers()
    const { data: clients, getClients, isLoading: isClientsLoading } = useGetClients()
    const { data: divisions, getDivisions, isLoading: isDivisionsLoading } = useGetDivisions()
    const { data: zones, getDivisionZones, isLoading: isZonesLoading } = useGetDivisionZones()
    const { data: questionnaires, getQuestionnaires, isLoading: isQuestionnairesLoading } = useGetQuestionnaires()
    const { getZone } = useGetZone()

    // Rights
    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasDivisionReadRight = hasRights.value([Rights.DIVISION_READ, Rights.DIVISION_CLIENT_READ])
    const hasZoneReadRight = hasRights.value([Rights.ZONE_READ, Rights.ZONE_CLIENT_READ, Rights.ZONE_DIVISION_READ])

    // Main Form Object
    const audit = reactive({ ...props.value })
    const updateAudit = () => {
      emit('input', audit)
    }

    // Current User Object for initializing some data and checking the users role
    const currentUser: EnrichedUser = root.$store.state.auth.currentUser

    // Date Input of scheduled date
    const showDatepicker = ref(false)

    // init with current date
    const date = ref(
      !audit.scheduledDate ? new Date().toISOString().substr(0, 10) : audit.scheduledDate.substr(0, 10)
    )
    // update form with initiated value
    audit.scheduledDate = new Date(date.value).toISOString()
    updateAudit()

    // returns a readable string to show in the date picker input text
    const localeDate = computed(() => toLocaleDateString(new Date(date.value)))

    // update scheduled date for audit and close datepicker
    function onDatePickerInput(newDate: string) {
      audit.scheduledDate = new Date(newDate).toISOString()
      updateAudit()
      showDatepicker.value = false
    }

    const auditors = ref<UserWithFullName[]>([
      { ...currentUser, fullName: `${currentUser.firstName} ${currentUser.lastName}` },
    ])
    const isAuditorsLoading = ref(false)

    /**
     * Loads the possible auditors for an audit.
     *
     * This function requires the zone (audit.zone), division, and client to be set first
     *
     * Everyone with a role other than User can be an auditor
     * but a creator can only choose other users with rights equal or lower than himself.
     * Aditionally every user except superAdmins has to be in the corresponding zone / division / client
     */
    const loadAuditors = () => {
      isAuditorsLoading.value = true
      auditors.value = []

      const userPlusOfZone = getUsers({ page: 0, size: 9999 }, { role: roles.USER_PLUS, zone: audit.zone })
      const zoneAdmins = getUsers({ page: 0, size: 9999 }, { role: roles.ZONE_ADMIN, zone: audit.zone })
      const auditorsOfZone = getUsers({ page: 0, size: 9999 }, { role: roles.AUDITOR, zone: audit.zone })
      const internalAuditorsOfZone = getUsers(
        { page: 0, size: 9999 },
        { role: roles.INTERNAL_AUDITOR, zone: audit.zone }
      )

      const promises: Promise<User[]>[] = [userPlusOfZone, zoneAdmins, auditorsOfZone, internalAuditorsOfZone]

      // divisionAdminsOfDivision
      if ([roles.DIVISION_ADMIN, roles.CLIENT_ADMIN, roles.ADMIN].includes(currentUser.role.name))
        promises.push(getUsers({ page: 0, size: 9999 }, { role: roles.DIVISION_ADMIN, division: division.value }))

      // clientAdminsOfClient
      if ([roles.CLIENT_ADMIN, roles.ADMIN].includes(currentUser.role.name))
        promises.push(getUsers({ page: 0, size: 9999 }, { role: roles.CLIENT_ADMIN, client: client.value }))

      // superAdmins
      if (currentUser.role.name === roles.ADMIN)
        promises.push(getUsers({ page: 0, size: 9999 }, { role: roles.ADMIN }))

      Promise.all(promises)
        .then((allUsers) => {
          allUsers.forEach((users) => {
            auditors.value.push(
              ...users.map((user) => ({
                ...user,
                fullName: `${user.firstName} ${user.lastName}`,
              }))
            )
          })
        })
        .finally(() => {
          isAuditorsLoading.value = false
        })
    }

    // Auditees
    const auditees = ref<UserWithFullName[]>([])
    const isAuditeesLoading = ref(false)
    const loadAuditees = async (zoneId: number) => {
      isAuditeesLoading.value = true
      const users = await getUsers({ page: 0, size: 9999 }, { zone: zoneId })

      auditees.value = users.map((user) => ({ ...user, fullName: `${user.firstName} ${user.lastName}` }))
      isAuditeesLoading.value = false
    }

    // Client / Division Inputs and methods
    const client = ref<number | undefined>(undefined)
    const division = ref<number | undefined>(undefined)

    const onClientInput = (clientId: number | undefined) => {
      division.value = undefined
      audit.zone = 0
      audit.auditor = currentUser.id
      audit.auditee = null
      updateAudit()

      if (clientId) getDivisions(clientId)
    }

    const onDivisionInput = (divisionId: number | undefined) => {
      audit.zone = 0
      audit.auditor = currentUser.id
      audit.auditee = null
      updateAudit()

      if (divisionId && client.value) getDivisionZones(client.value, divisionId)
    }

    const onZoneInput = (zoneId: number) => {
      audit.auditor = currentUser.id
      audit.auditee = null
      audit.questionnaire = 0
      updateAudit()
      loadAuditors()
      loadAuditees(zoneId)

      const zoneObject = zones.value.find((zone) => zone.id === zoneId)!
      getQuestionnaires({ page: 0, size: 9999 }, { type: zoneObject.type.id })
    }

    // Prefill Client / Division / Zone based on user rights
    if (props.isCreate) {
      if (hasClientReadRight) {
        getClients({ page: 0, size: 9999 })
      } else {
        client.value = currentUser.client
        if (hasDivisionReadRight) {
          getDivisions(currentUser.client)
        } else {
          division.value = currentUser.division
          if (hasZoneReadRight) {
            getDivisionZones(currentUser.client, currentUser.division)
          } else {
            audit.zone = currentUser.zone
            updateAudit()
            loadAuditors()
            loadAuditees(currentUser.zone)
            getQuestionnaires({ page: 0, size: 9999 }, { type: currentUser.zoneObject.type.id })
          }
        }
      }
    } else {
      loadAuditors()
      loadAuditees(audit.zone)
      getZone(audit.zone).then((zone) => {
        client.value = zone.client
        division.value = zone.division

        if (hasClientReadRight) getClients({ page: 0, size: 9999 })
        if (hasDivisionReadRight) getDivisions(client.value)
        if (hasZoneReadRight) getDivisionZones(client.value, division.value)

        getQuestionnaires({ page: 0, size: 9999 }, { type: zone.type.id })
      })
    }

    return {
      breakpoint: root.$vuetify.breakpoint,
      icons: { mdiCalendar },
      ...toRefs(audit),
      updateAudit,
      hasClientReadRight,
      hasDivisionReadRight,
      hasZoneReadRight,
      required,
      client,
      clients,
      isClientsLoading,
      onClientInput,
      division,
      divisions,
      isDivisionsLoading,
      onDivisionInput,
      zones,
      isZonesLoading,
      onZoneInput,
      auditors,
      isAuditorsLoading,
      auditees,
      isAuditeesLoading,
      questionnaires,
      isQuestionnairesLoading,
      date,
      localeDate,
      showDatepicker,
      onDatePickerInput,
    }
  },
})
