
import { computed, defineComponent } from '@vue/composition-api'

import { useAuthGetters } from '@/store'

import PublicLayout from '@/layouts/public/public-layout.vue'
import DefaultLayout from '@/layouts/default/default-layout.vue'

export default defineComponent({
  name: 'home-layout',
  setup: (_, { root }) => {
    const { isAuthenticated } = useAuthGetters(root.$store)

    const layout = computed(() => (isAuthenticated.value ? DefaultLayout : PublicLayout))

    return {
      layout,
    }
  },
})
