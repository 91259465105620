
import { computed, defineComponent, ref } from '@vue/composition-api'

import { useGetDivisionZones } from '@/api/clients'
import { useGetDivisions } from '@/api/divisions'
import { Division } from '@/api/divisions'
import { Rights } from '@/api/rights'
import { useAuthGetters } from '@/store'
import { TableHeader } from '@/types'

import { mdiPencil, mdiDelete, mdiPlus } from '@mdi/js'
import { Zone } from '@/api/zones'

export default defineComponent({
  name: 'admin-zones-view',
  components: {
    AddZoneDialog: () => import('./add-zone-dialog.vue'),
    EditZoneDialog: () => import('./edit-zone-dialog.vue'),
    DeleteZoneDialog: () => import('./delete-zone-dialog.vue'),
  },
  setup: (_, { root }) => {
    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasDivisionReadRight = hasRights.value([Rights.DIVISION_READ, Rights.DIVISION_CLIENT_READ])
    const hasCreateRight = hasRights.value([
      Rights.ZONE_CREATE,
      Rights.ZONE_CLIENT_CREATE,
      Rights.ZONE_DIVISION_CREATE,
    ])
    const hasUpdateRight = hasRights.value([
      Rights.ZONE_UPDATE,
      Rights.ZONE_CLIENT_UPDATE,
      Rights.ZONE_DIVISION_UPDATE,
    ])
    const hasDeleteRight = hasRights.value([
      Rights.ZONE_DELETE,
      Rights.ZONE_CLIENT_DELETE,
      Rights.ZONE_DIVISION_CREATE,
    ])

    // Client select for superadmins
    const clients = computed(() => root.$store.state.clients.clients)
    const selectedClients = ref<number[]>([])

    const { getDivisions, isLoading: divisionsLoading } = useGetDivisions()
    const divisions: Division[] = []
    const selectedDivisions = ref<number[]>([])

    const { getDivisionZones, isLoading: zonesLoading } = useGetDivisionZones()
    const zones: Zone[] = []

    // Divisions filtered by selected clients
    const filteredDivisions = ref<Division[]>([])

    const filteredZones = ref<Zone[]>([])

    // update divisions to display
    const filterDivisions = () => {
      filteredDivisions.value = divisions.filter((division) => selectedClients.value.includes(division.client))

      filterSelectedDivisions()
    }

    // Filter selected Divisions to remove every division that isn't in filteredDivisions
    const filterSelectedDivisions = () => {
      selectedDivisions.value = selectedDivisions.value.filter((divisionId) =>
        filteredDivisions.value.map((division) => division.id).includes(divisionId)
      )

      filterZones()
    }

    const filterZones = () => {
      filteredZones.value = zones.filter((zone) => selectedDivisions.value.includes(zone.division))
    }

    // fetch new divisions
    const loadDivisions = (clientId: number) => {
      clientIdsOfLoadedDivisions.push(clientId)
      getDivisions(clientId).then((loadedDivisions) => {
        divisions.push(...loadedDivisions)
        filterDivisions()
      })
    }

    const loadZones = (clientId: number, divisionId: number) => {
      divisonIdsOfLoadedZones.push(divisionId)
      getDivisionZones(clientId, divisionId).then((loadedZones) => {
        zones.splice(0)
        zones.push(...loadedZones)
        filterZones()
      })
    }

    // list of clients for which divisions have been loaded
    const clientIdsOfLoadedDivisions: number[] = []

    const divisonIdsOfLoadedZones: number[] = []

    // load divisions for every client not already loaded
    const onSelectedClientsChanged = (clientIds: number[]) => {
      filterDivisions()

      for (const clientId of clientIds) {
        if (!clientIdsOfLoadedDivisions.includes(clientId)) {
          loadDivisions(clientId)
        }
      }
    }

    const onSelectedDivisionChanged = (divisionIds: number[]) => {
      filterZones()

      for (const divisionId of divisionIds) {
        if (!divisonIdsOfLoadedZones.includes(divisionId)) {
          const clientId = divisions.find((division) => division.id === divisionId)?.client
          if (typeof clientId === 'number') {
            loadZones(clientId, divisionId)
          }
        }
      }
    }

    // if user can't read clients use the users client
    if (!hasClientReadRight) {
      selectedClients.value = [root.$store.state.auth.currentUser.client as number]
      loadDivisions(root.$store.state.auth.currentUser.client)
    }
    if (!hasDivisionReadRight) {
      selectedDivisions.value = [root.$store.state.auth.currentUser.division as number]
      loadZones(root.$store.state.auth.currentUser.client, root.$store.state.auth.currentUser.division)
    }

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const tableHeaders: TableHeader[] = [
      {
        text: root.$t('form.field.name') as string,
        value: 'name',
        show: () => true,
      },
      {
        text: root.$t('form.field.unofficialName') as string,
        value: 'unofficialName',
        show: () => true,
      },
      {
        text: root.$t('form.field.zoneType') as string,
        value: 'type',
        show: () => true,
      },
      {
        text: root.$tc('form.field.division', 1) as string,
        value: 'division',
        show: () => true,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        show: () => hasUpdateRight || hasDeleteRight,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const filteredTableHeaders = tableHeaders.filter((header) => header.show())

    // Edit Division
    const isEditActive = ref(false)
    const zoneToEdit = ref<Zone | null>(null)

    const openEditDialog = (zone: Zone) => {
      if (hasUpdateRight) {
        zoneToEdit.value = zone
        isEditActive.value = true
      }
    }

    const updateZone = (editedZone: Zone) => {
      const index = zones.findIndex((zone) => zone.id === editedZone.id)
      if (index >= 0) zones.splice(index, 1, editedZone)
      filterZones()
    }

    // Add Division
    const isCreateActive = ref(false)

    const addZone = (newZone: Zone) => {
      zones.push(newZone)
      filterZones()
    }

    //Delete Division
    const isDeleteActive = ref(false)
    const zoneToDelete = ref<Zone | null>(null)

    const openDeleteDialog = (zone: Zone) => {
      if (hasDeleteRight) {
        zoneToDelete.value = zone
        isDeleteActive.value = true
      }
    }

    const removeZone = (zoneToRemove: Zone) => {
      const index = zones.findIndex((zone) => zone.id === zoneToRemove.id)
      if (index >= 0) zones.splice(index, 1)
      filterZones()
    }

    return {
      icons: { mdiPencil, mdiDelete, mdiPlus },
      selectedClients,
      selectedDivisions,
      clients,
      divisionsLoading,
      filteredDivisions,
      filteredZones,
      zonesLoading,
      onSelectedClientsChanged,
      onSelectedDivisionChanged,
      filteredTableHeaders,
      hasClientReadRight,
      hasDivisionReadRight,
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      openEditDialog,
      updateZone,
      isCreateActive,
      addZone,
      isEditActive,
      zoneToEdit,
      isDeleteActive,
      zoneToDelete,
      openDeleteDialog,
      removeZone,
    }
  },
})
