
import { defineComponent, PropType, reactive, toRefs } from '@vue/composition-api'

import { ClientInput } from '@/api/clients'

import { required } from '@/utils/validation'

export default defineComponent({
  name: 'client-form',
  props: {
    value: {
      type: Object as PropType<ClientInput>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const client = reactive(props.value)

    const updateClient = () => {
      emit('input', client)
    }

    return {
      ...toRefs(client),
      updateClient,
      required,
    }
  },
})
