import { useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'

const version = 'v1'
const resource = 'rights'

export interface UserRight {
  id: number
  name: string
  description?: string
}

export enum Rights {
  ACTION_CREATE = 'ACTION_CREATE',
  ACTION_DELETE = 'ACTION_DELETE',
  ACTION_READ = 'ACTION_READ',
  ACTION_STATE_OWN_UPDATE = 'ACTION_STATE_OWN_UPDATE',
  ACTION_UPDATE = 'ACTION_UPDATE',
  ANSWER_CREATE = 'ANSWER_CREATE',
  ANSWER_DELETE = 'ANSWER_DELETE',
  API_DEVELOPER = 'API_DEVELOPER',
  AUDIT_CLIENT_CREATE = 'AUDIT_CLIENT_CREATE',
  AUDIT_CLIENT_DELETE = 'AUDIT_CLIENT_DELETE',
  AUDIT_CLIENT_READ = 'AUDIT_CLIENT_READ',
  AUDIT_CLIENT_UPDATE = 'AUDIT_CLIENT_UPDATE',
  AUDIT_CREATE = 'AUDIT_CREATE',
  AUDIT_DELETE = 'AUDIT_DELETE',
  AUDIT_DIVISION_CREATE = 'AUDIT_DIVISION_CREATE',
  AUDIT_DIVISION_DELETE = 'AUDIT_DIVISION_DELETE',
  AUDIT_DIVISION_READ = 'AUDIT_DIVISION_READ',
  AUDIT_DIVISION_UPDATE = 'AUDIT_DIVISION_UPDATE',
  AUDIT_READ = 'AUDIT_READ',
  AUDIT_UPDATE = 'AUDIT_UPDATE',
  AUDIT_ZONE_CREATE = 'AUDIT_ZONE_CREATE',
  AUDIT_ZONE_DELETE = 'AUDIT_ZONE_DELETE',
  AUDIT_ZONE_READ = 'AUDIT_ZONE_READ',
  AUDIT_ZONE_UPDATE = 'AUDIT_ZONE_UPDATE',
  CLIENT_CLIENT_READ = 'CLIENT_CLIENT_READ',
  CLIENT_CLIENT_UPDATE = 'CLIENT_CLIENT_UPDATE',
  CLIENT_CREATE = 'CLIENT_CREATE',
  CLIENT_DELETE = 'CLIENT_DELETE',
  CLIENT_READ = 'CLIENT_READ',
  CLIENT_UPDATE = 'CLIENT_UPDATE',
  COMMENT_CREATE = 'COMMENT_CREATE',
  COMMENT_DELETE = 'COMMENT_DELETE',
  DIVISION_CLIENT_CREATE = 'DIVISION_CLIENT_CREATE',
  DIVISION_CLIENT_DELETE = 'DIVISION_CLIENT_DELETE',
  DIVISION_CLIENT_READ = 'DIVISION_CLIENT_READ',
  DIVISION_CLIENT_UPDATE = 'DIVISION_CLIENT_UPDATE',
  DIVISION_CREATE = 'DIVISION_CREATE',
  DIVISION_DELETE = 'DIVISION_DELETE',
  DIVISION_DIVISION_CREATE = 'DIVISION_DIVISION_CREATE',
  DIVISION_DIVISION_DELETE = 'DIVISION_DIVISION_DELETE',
  DIVISION_DIVISION_READ = 'DIVISION_DIVISION_READ',
  DIVISION_DIVISION_UPDATE = 'DIVISION_DIVISION_UPDATE',
  DIVISION_READ = 'DIVISION_READ',
  DIVISION_UPDATE = 'DIVISION_UPDATE',
  FILE_DELETE = 'FILE_DELETE',
  FILE_UPLOAD = 'FILE_UPLOAD',
  GRAPH_CLIENT_READ = 'GRAPH_CLIENT_READ',
  GRAPH_DIVISION_READ = 'GRAPH_DIVISION_READ',
  GRAPH_READ = 'GRAPH_READ',
  GRAPH_ZONE_READ = 'GRAPH_ZONE_READ',
  QUESTIONNAIRE_CLIENT_CREATE = 'QUESTIONNAIRE_CLIENT_CREATE',
  QUESTIONNAIRE_CLIENT_DELETE = 'QUESTIONNAIRE_CLIENT_DELETE',
  QUESTIONNAIRE_CLIENT_READ = 'QUESTIONNAIRE_CLIENT_READ',
  QUESTIONNAIRE_CLIENT_UPDATE = 'QUESTIONNAIRE_CLIENT_UPDATE',
  QUESTIONNAIRE_CREATE = 'QUESTIONNAIRE_CREATE',
  QUESTIONNAIRE_DELETE = 'QUESTIONNAIRE_DELETE',
  QUESTIONNAIRE_READ = 'QUESTIONNAIRE_READ',
  QUESTIONNAIRE_UPDATE = 'QUESTIONNAIRE_UPDATE',
  QUESTION_CLIENT_CREATE = 'QUESTION_CLIENT_CREATE',
  QUESTION_CLIENT_DELETE = 'QUESTION_CLIENT_DELETE',
  QUESTION_CLIENT_READ = 'QUESTION_CLIENT_READ',
  QUESTION_CLIENT_UPDATE = 'QUESTION_CLIENT_UPDATE',
  QUESTION_CREATE = 'QUESTION_CREATE',
  QUESTION_DELETE = 'QUESTION_DELETE',
  QUESTION_READ = 'QUESTION_READ',
  QUESTION_UPDATE = 'QUESTION_UPDATE',
  RIGHT_READ = 'RIGHT_READ',
  RIGHT_UPDATE = 'RIGHT_UPDATE',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_DELETE = 'ROLE_DELETE',
  ROLE_READ = 'ROLE_READ',
  ROLE_UPDATE = 'ROLE_UPDATE',
  TRANSLATION_CREATE = 'TRANSLATION_CREATE',
  TRANSLATION_DELETE = 'TRANSLATION_DELETE',
  TRANSLATION_READ = 'TRANSLATION_READ',
  TRANSLATION_UPDATE = 'TRANSLATION_UPDATE',
  USER_CLIENT_CREATE = 'USER_CLIENT_CREATE',
  USER_CLIENT_DELETE = 'USER_CLIENT_DELETE',
  USER_CLIENT_READ = 'USER_CLIENT_READ',
  USER_CLIENT_UPDATE = 'USER_CLIENT_UPDATE',
  USER_CREATE = 'USER_CREATE',
  USER_DELETE = 'USER_DELETE',
  USER_DIVISION_CREATE = 'USER_DIVISION_CREATE',
  USER_DIVISION_DELETE = 'USER_DIVISION_DELETE',
  USER_DIVISION_READ = 'USER_DIVISION_READ',
  USER_DIVISION_UPDATE = 'USER_DIVISION_UPDATE',
  USER_READ = 'USER_READ',
  USER_UPDATE = 'USER_UPDATE',
  USER_ZONE_CREATE = 'USER_ZONE_CREATE',
  USER_ZONE_DELETE = 'USER_ZONE_DELETE',
  USER_ZONE_READ = 'USER_ZONE_READ',
  USER_ZONE_UPDATE = 'USER_ZONE_UPDATE',
  ZONE_CLIENT_CREATE = 'ZONE_CLIENT_CREATE',
  ZONE_CLIENT_DELETE = 'ZONE_CLIENT_DELETE',
  ZONE_CLIENT_READ = 'ZONE_CLIENT_READ',
  ZONE_CLIENT_UPDATE = 'ZONE_CLIENT_UPDATE',
  ZONE_CREATE = 'ZONE_CREATE',
  ZONE_DELETE = 'ZONE_DELETE',
  ZONE_DIVISION_CREATE = 'ZONE_DIVISION_CREATE',
  ZONE_DIVISION_DELETE = 'ZONE_DIVISION_DELETE',
  ZONE_DIVISION_READ = 'ZONE_DIVISION_READ',
  ZONE_DIVISION_UPDATE = 'ZONE_DIVISION_UPDATE',
  ZONE_READ = 'ZONE_READ',
  ZONE_TYPE_CLIENT_CREATE = 'ZONE_TYPE_CLIENT_CREATE',
  ZONE_TYPE_CLIENT_DELETE = 'ZONE_TYPE_CLIENT_DELETE',
  ZONE_TYPE_CLIENT_READ = 'ZONE_TYPE_CLIENT_READ',
  ZONE_TYPE_CLIENT_UPDATE = 'ZONE_TYPE_CLIENT_UPDATE',
  ZONE_TYPE_CREATE = 'ZONE_TYPE_CREATE',
  ZONE_TYPE_DELETE = 'ZONE_TYPE_DELETE',
  ZONE_TYPE_DIVISION_READ = 'ZONE_TYPE_DIVISION_READ',
  ZONE_TYPE_READ = 'ZONE_TYPE_READ',
  ZONE_TYPE_UPDATE = 'ZONE_TYPE_UPDATE',
  ZONE_UPDATE = 'ZONE_UPDATE',
  ZONE_ZONE_READ = 'ZONE_ZONE_READ',
  ZONE_ZONE_UPDATE = 'ZONE_ZONE_UPDATE',
}

export const useGetRights = () => {
  const axios = useAxiosPaginated<UserRight>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getRights = (paginationParams?: PaginationParams) => axios.exec({ params: paginationParams })

  return {
    ...axios,
    getRights,
  }
}
