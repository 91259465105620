
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'common-flag-icon',
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const country = computed(() => props.locale.slice(-2).toLowerCase())
    return {
      country,
    }
  },
})
