
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { Division, useUpdateDivision } from '@/api/divisions'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

import { mdiPencil } from '@mdi/js'

import DivisionForm from './division-form.vue'

export default defineComponent({
  name: 'edit-division-dialog',
  components: {
    DivisionForm,
  },
  props: {
    division: {
      type: Object as PropType<Division>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { updateDivision, isLoading } = useUpdateDivision()

    const { addNotification } = useNotify()

    const editDivisionForm = ref<ValidationForm | null>(null)

    const divisionToEdit = ref<Division>({ ...props.division })

    const editDivision = () => {
      if (editDivisionForm.value?.validate()) {
        updateDivision(divisionToEdit.value)
          .then((editedDivision) => {
            addNotification({
              type: 'success',
              text: root.$t('divisions.update.success', {
                name: editedDivision.name,
              }) as string,
            })
            emit('division-edited', editedDivision)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('divisions.update.error', {
              name: divisionToEdit.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPencil },
      editDivisionForm,
      divisionToEdit,
      editDivision,
      isLoading,
    }
  },
})
