
import { computed, defineComponent, ref } from '@vue/composition-api'

import { useAuthGetters, useClientsGetters } from '@/store'
import { Rights } from '@/api/rights'
import { Question, useGetQuestions } from '@/api/questions'
import { usePagination } from '@/utils/pagination'
import { TableHeader } from '@/types'

import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js'

export default defineComponent({
  name: 'questions-view',

  components: {
    AddQuestionDialog: () => import('./add-question-dialog.vue'),
    EditQuestionDialog: () => import('./edit-question-dialog.vue'),
    DeleteQuestionDialog: () => import('./delete-question-dialog.vue'),
  },

  setup: (_, { root }) => {
    const { ownClient } = useClientsGetters(root.$store)
    const { hasRights } = useAuthGetters(root.$store)

    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasCreateRight = hasRights.value([Rights.QUESTION_CREATE, Rights.QUESTION_CLIENT_CREATE])
    const hasUpdateRight = hasRights.value([Rights.QUESTION_UPDATE, Rights.QUESTION_CLIENT_UPDATE])
    const hasDeleteRight = hasRights.value([Rights.QUESTION_DELETE, Rights.QUESTION_CLIENT_DELETE])

    const { getQuestions: getQuestionsRequest, data: questions, isLoading, paginationResponse } = useGetQuestions()
    const { vuetifyTableOptions, paginationParams } = usePagination()

    const getQuestions = () => {
      getQuestionsRequest(paginationParams.value).catch((error) => {
        error.userMessage = root.$t('questions.get.error')

        throw error
      })
    }

    getQuestions()

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const defaultHeaders: TableHeader[] = [
      {
        text: root.$t('form.field.question') as string,
        value: 'text',
        show: () => true,
      },
      {
        text: root.$t('form.field.description') as string,
        value: 'description',
        show: () => true,
      },
      {
        text: root.$t('form.field.category') as string,
        value: 'category',
        show: () => true,
      },
      {
        text: root.$t('form.field.zoneType') as string,
        value: 'zoneType',
        show: () => true,
      },
      {
        text: root.$tc('form.field.client') as string,
        value: 'client',
        show: () => hasClientReadRight,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        show: () => hasUpdateRight || hasDeleteRight,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const tableHeaders = defaultHeaders.filter((header) => header.show())

    // Edit Question
    const isEditActive = ref(false)
    const questionToEdit = ref<Question | null>(null)

    const openEditDialog = (question: Question) => {
      if (hasUpdateRight) {
        questionToEdit.value = question
        isEditActive.value = true
      }
    }

    const onQuestionEdited = () => {
      getQuestions()
      isEditActive.value = false
    }

    // Add Question
    const isCreateActive = ref(false)

    // Delete Question
    const isDeleteActive = ref(false)
    const questionToDelete = ref<Question | null>(null)

    const openDeleteDialog = (question: Question) => {
      if (hasDeleteRight) {
        questionToDelete.value = question
        isDeleteActive.value = true
      }
    }

    return {
      icons: { mdiPlus, mdiPencil, mdiDelete },
      isAuditingModeFiveS: computed(() => ownClient.value?.auditingMode === 'FIVE_S'),
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      questions,
      getQuestions,
      paginationResponse,
      isLoading,
      vuetifyTableOptions,
      tableHeaders,
      isEditActive,
      questionToEdit,
      openEditDialog,
      onQuestionEdited,
      isCreateActive,
      isDeleteActive,
      questionToDelete,
      openDeleteDialog,
    }
  },
})
