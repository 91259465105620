import { useAxios, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { BaseModelDeletable } from '.'

const version = 'v1'
const resource = 'zonetypes'

export interface ZoneTypeInput {
  client?: number
  name: string
}

export interface ZoneType extends BaseModelDeletable, ZoneTypeInput {}

export interface ZoneTypeFilter {
  public?: boolean
}

export const useGetZoneTypes = () => {
  const axios = useAxiosPaginated<ZoneType>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getZoneTypes = (paginationParams: PaginationParams, filter: ZoneTypeFilter = {}) =>
    axios.exec({ params: { ...paginationParams, ...filter } })

  return {
    ...axios,
    getZoneTypes,
  }
}

export const useGetZoneType = () => {
  const axios = useAxios<ZoneType>({
    method: 'get',
  })

  const getZoneType = (zoneTypeId: number) => axios.exec({ url: `/${version}/${resource}/${zoneTypeId}` })

  return {
    ...axios,
    getZoneType,
  }
}

export const useCreateZoneType = () => {
  const axios = useAxios<ZoneType>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createZoneType = (newZoneType: ZoneTypeInput) => axios.exec({ data: newZoneType })

  return {
    ...axios,
    createZoneType,
  }
}

export const useUpdateZoneType = () => {
  const axios = useAxios<ZoneType>({
    method: 'put',
  })

  const updateZoneType = (zoneType: ZoneType) =>
    axios.exec({ url: `/${version}/${resource}/${zoneType.id}`, data: zoneType })

  return {
    ...axios,
    updateZoneType,
  }
}

export const useDeleteZoneType = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteZoneType = (zoneTypeId: number) => axios.exec({ url: `/${version}/${resource}/${zoneTypeId}` })

  return {
    ...axios,
    deleteZoneType,
  }
}

export interface UpdateZoneTypePartialInput {
  client?: number
  deleted?: boolean
  id?: number
  name?: string
}

export const useUpdateZoneTypePartial = () => {
  const axios = useAxios<ZoneType>({
    method: 'patch',
  })

  const updateZoneTypePartial = (zoneType: UpdateZoneTypePartialInput) =>
    axios.exec({ url: `/${version}/${resource}/${zoneType.id}`, data: zoneType })

  return {
    ...axios,
    updateZoneTypePartial,
  }
}
