import { render, staticRenderFns } from "./navigation-drawer.vue?vue&type=template&id=0130aab0&scoped=true&"
import script from "./navigation-drawer.vue?vue&type=script&lang=ts&"
export * from "./navigation-drawer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0130aab0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VImg,VNavigationDrawer,VToolbar})
