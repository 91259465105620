import Vue from 'vue'
import VueI18n, { TranslateResult } from 'vue-i18n'
import { axiosInstance } from '@/plugins/axios'

Vue.use(VueI18n)

const fallbackLocale = 'de'

const commonLocaleMap = {
  en: 'en_US',
  de: 'de_DE',
  fr: 'fr_FR',
  es: 'es_ES',
}


export function loadLanguageAsync(lang: string, reload = false) {
  if (i18n.locale !== lang || reload) {
    if (!Object.keys(i18n.getLocaleMessage(lang)).length || reload) {
      // should not require auth as there are also pages not requiring it
      return axiosInstance
        .get('/v1/translations/' + lang.toLowerCase())
        .then(({ data }) => {
          i18n.setLocaleMessage(lang, data)
          return setI18nLanguage(lang)
        })
        .catch(() => {
          return setI18nLanguage(fallbackLocale)
        })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

export function reloadLanguage(lang?: string) {
  return loadLanguageAsync(lang || i18n.locale, true)
}

export const resolveCountry = (lang: string) => {
  return commonLocaleMap[lang] || ''
}

function setI18nLanguage(lang: string) {
  i18n.locale = lang
  axiosInstance.defaults.headers['Accept-Language'] = lang
  const doc = document && document.querySelector('html')
  if (doc) {
    doc.setAttribute('lang', lang)
  }
  return lang
}

export const i18n = new VueI18n({})

// https://github.com/kazupon/vue-i18n/issues/444#issuecomment-430853066
export function tf(str: string, fallbackStr: string): TranslateResult {
  return i18n.t && i18n.te ? (i18n.te(str) ? i18n.t(str) : fallbackStr) : fallbackStr ? fallbackStr : str
}
