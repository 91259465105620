import { useAxios } from '@/utils/axios'

const resource = 'sentry'

export interface FeedbackData {
  event_id: string
  name: string
  email: string
  comments: string
}

export const useSendFeedback = () => {
  const axios = useAxios<void>({
    method: 'post',
    url: `${resource}/feedback`,
  })

  const sendFeedback = (feedback: FeedbackData) => axios.exec({ data: feedback })

  return {
    ...axios,
    sendFeedback,
  }
}
