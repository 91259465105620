import vuexStore from '@/store'

import { Actions, Getters } from './index'

import { Client } from '@/api/clients'
import { computed } from '@vue/composition-api'

export const useClientsActions = (store = vuexStore) => {
  const setLoading = (newIsLoading: boolean) => {
    store.dispatch(Actions.setLoading, newIsLoading)
  }
  const setClients = (clients: Client[]) => {
    store.dispatch(Actions.setClients, clients)
  }
  const addClient = (client: Client) => {
    store.dispatch(Actions.addClient, client)
  }
  const removeClient = (client: Client) => {
    store.dispatch(Actions.removeClient, client)
  }
  const updateClient = (client: Client) => {
    store.dispatch(Actions.updateClient, client)
  }

  return {
    setLoading,
    setClients,
    addClient,
    removeClient,
    updateClient,
  }
}

export const useClientsGetters = (store = vuexStore) => {
  const ownClient = computed<Client | undefined>(() => store.getters[Getters.ownClient])

  return {
    ownClient,
  }
}
