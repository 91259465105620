
import { computed, defineComponent, PropType, reactive, ref, toRefs } from '@vue/composition-api'

import { useGetRights, UserRight } from '@/api/rights'
import { NewUserRole } from '@/api/roles'
import { Translation, useGetTranslations } from '@/api/translations'

import { required } from '@/utils/validation'

import CommonFlagIcon from '@/components/common/common-flag-icon.vue'

export default defineComponent({
  name: 'role-form',
  components: {
    CommonFlagIcon,
  },
  props: {
    value: {
      type: Object as PropType<NewUserRole>,
      required: true,
    },
    translations: {
      type: Array as PropType<Translation[]>,
      required: true,
    },
  },
  setup(props, { root, emit }) {
    const { getRights, data: rights } = useGetRights()

    const sortedRights = computed(() => {
      const tempRights = [...rights.value]
      return tempRights.sort((a, b) => a.name.localeCompare(b.name))
    })

    // TODO: ugly 9999 size, find a way to get all results unpaginated
    getRights({
      size: 9999,
      page: 0,
    })

    const role = reactive(props.value)

    const updateRole = () => {
      role.rights = role.rights.map((r: number | UserRight) =>
        typeof r === 'number' ? (r as number) : (r as UserRight).id
      )
      emit('input', role)
    }

    const { getTranslations } = useGetTranslations()
    const implementedLocales: string[] = root.$store.state.locale.implementedLocales

    const newTranslations = ref<Translation[]>(
      implementedLocales.map((locale) => ({
        locale,
        key: `roles.names.${role.name}`,
        value: '',
      }))
    )

    // load translatios to prefill translation fields if role is edit
    if (role.name) {
      getTranslations().then((translationMap) => {
        if (translationMap[`roles.names.${role.name}`])
          newTranslations.value = translationMap[`roles.names.${role.name}`]

        emit('update:translations', newTranslations.value)
      })
    } else {
      emit('update:translations', newTranslations.value)
    }

    const onNameChanged = (name: string) => {
      newTranslations.value.map((translation) => {
        translation.key = `roles.names.${name}`
      })
      emit('update:translations', newTranslations.value)

      updateRole()
    }

    return {
      ...toRefs(role),
      updateRole,
      sortedRights,
      required,
      onNameChanged,
      newTranslations,
    }
  },
})
