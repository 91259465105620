
import { defineComponent, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { useCreateZoneType, ZoneTypeInput } from '@/api/zoneTypes'
import { ValidationForm } from '@/utils/validation'

import { mdiPlus } from '@mdi/js'

import ZoneTypeForm from './zone-type-form.vue'

export default defineComponent({
  name: 'add-zone-type-dialog',
  components: {
    ZoneTypeForm,
  },
  setup: (_, { root, emit }) => {
    const { createZoneType, isLoading } = useCreateZoneType()

    const { addNotification } = useNotify()

    const addZoneTypeForm = ref<ValidationForm | null>(null)

    const zoneTypeToAdd = ref<ZoneTypeInput>({
      name: '',
      client: undefined,
    })

    const addZoneType = () => {
      if (addZoneTypeForm.value?.validate()) {
        createZoneType(zoneTypeToAdd.value)
          .then((newZoneType) => {
            addNotification({
              type: 'success',
              text: root.$t('zoneTypes.create.success', {
                name: newZoneType.name,
              }) as string,
            })
            emit('zone-type-added', newZoneType)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('zoneTypes.create.error', {
              name: zoneTypeToAdd.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPlus },
      addZoneTypeForm,
      zoneTypeToAdd,
      addZoneType,
      isLoading,
    }
  },
})
