import { RouteConfig } from 'vue-router'

import { Rights } from '@/api/rights'

import PublicLayout from '@/layouts/public/public-layout.vue'
import DefaultLayout from '@/layouts/default/default-layout.vue'
import HomeLayout from '@/layouts/home/home-layout.vue'
import QuestionnairesLayout from '@/layouts/questionnaires/questionnaires-layout.vue'
import QuestionsLayout from '@/layouts/questions/questions-layout.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            /* webpackPrefetch: true */
            '@/views/home/home-view.vue'
          ),
      },
    ],
  },
  {
    path: '/login',
    component: PublicLayout,
    children: [
      {
        path: '/',
        name: 'login',
        component: () =>
          import(
            /* webpackChunkName: "Login" */
            /* webpackPrefetch: true */ '@/views/login/login-view.vue'
          ),
      },
    ],
  },
  {
    path: '/contact',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'contact',
        component: () => import(/* webpackChunkName: "Contact" */ '@/views/contact/contact-view.vue'),
      },
    ],
  },
  {
    path: '/resetPassword',
    component: PublicLayout,
    children: [
      {
        path: '',
        name: 'resetPassword',
        props: true,
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/password/reset-password-view.vue'),
      },
    ],
  },
  {
    path: '/setPassword',
    component: PublicLayout,
    children: [
      {
        path: '',
        name: 'setPassword',
        props: (route) => ({ token: route.query.token }),
        component: () => import(/* webpackChunkName: "SetPassword" */ '@/views/password/set-password-view.vue'),
      },
    ],
  },
  {
    path: '/signUp',
    component: PublicLayout,
    children: [
      {
        path: '/',
        name: 'signUp',
        component: () => import(/* webpackChunkName: "SignUp" */ '@/views/signUp/sign-up-view.vue'),
      },
    ],
  },
  {
    path: '/profile',
    component: DefaultLayout,
    meta: {
      title: 'profile.title',
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import(/* webpackChunkName: "Profile" */ '@/views/profile/profile-view.vue'),
      },
    ],
  },
  {
    path: '/admin',
    redirect: '/admin/users',
    component: () => import(/* webpackChunkName: "admin" */ '@/layouts/admin/admin-layout.vue'),
    children: [
      {
        path: 'users',
        name: 'adminUsers',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/admin-users-view.vue'),
        meta: {
          authRequired: true,
          title: 'admin.users.title',
          rights: [Rights.USER_READ, Rights.USER_CLIENT_READ, Rights.USER_DIVISION_READ, Rights.USER_ZONE_READ],
        },
      },
      {
        path: 'rights',
        name: 'adminRights',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/rights/admin-rights-view.vue'),
        meta: {
          authRequired: true,
          title: 'admin.rights.title',
          rights: [Rights.RIGHT_READ],
        },
      },
      {
        path: 'roles',
        name: 'adminRoles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/roles/admin-roles-view.vue'),
        meta: {
          authRequired: true,
          title: 'admin.roles.title',
          rights: [Rights.ROLE_READ],
        },
      },
      {
        path: 'translations',
        name: 'adminTranslations',
        component: () =>
          import(/* webpackChunkName: "admin" */ '@/views/admin/translations/admin-translations-view.vue'),
        meta: {
          authRequired: true,
          title: 'admin.translations.title',
          rights: [Rights.TRANSLATION_READ],
        },
      },
      {
        path: 'clients',
        name: 'adminClients',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/clients/admin-clients-view.vue'),
        meta: {
          title: 'admin.clients.title',
          rights: [Rights.CLIENT_READ],
        },
      },
      {
        path: 'divisions',
        name: 'adminDivisions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/divisions/admin-divisions-view.vue'),
        meta: {
          title: 'admin.divisions.title',
          rights: [Rights.DIVISION_READ, Rights.DIVISION_CLIENT_READ, Rights.DIVISION_DIVISION_READ],
        },
      },
      {
        path: 'zones',
        name: 'adminZones',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/zones/admin-zones-view.vue'),
        meta: {
          title: 'admin.zones.title',
          rights: [Rights.ZONE_READ, Rights.ZONE_CLIENT_READ, Rights.ZONE_DIVISION_READ],
        },
      },
      {
        path: 'zoneTypes',
        name: 'adminZoneTypes',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/zone-types/admin-zone-types-view.vue'),
        meta: {
          title: 'admin.zoneTypes.title',
          rights: [Rights.ZONE_TYPE_READ, Rights.ZONE_TYPE_CLIENT_READ],
        },
      },
    ],
  },

  {
    path: '/audits',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'audits',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/audits/audits-view.vue'),
        meta: {
          title: 'audits.title',
          authRequired: true,
        },
      },
      {
        path: ':id',
        name: 'audit',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/audits/audit-view.vue'),
        meta: {
          title: 'audits.title',
          authRequired: true,
        },
        props: (route) => ({ id: +route.params.id }),
      },
    ],
  },
  {
    path: '/questionnaires',
    component: QuestionnairesLayout,
    children: [
      {
        path: '',
        name: 'questionnaires',
        component: () =>
          import(/* webpackChunkName: "questionnaire" */ '@/views/questionnaires/questionnaires-view.vue'),
        meta: {
          title: 'questionnaires.title',
          authRequired: true,
        },
      },
      {
        path: ':id',
        name: 'questionnaire',
        component: () =>
          import(/* webpackChunkName: "questionnaire" */ '@/views/questionnaires/questionnaire/questionnaire-view.vue'),
        meta: {
          title: 'questionnaires.title',
          authRequired: true,
        },
        props: (route) => ({ id: +route.params.id }),
      },
    ],
  },
  {
    path: '/questions',
    component: QuestionsLayout,
    meta: {
      title: 'questions.title',
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'questions',
        component: () => import(/* webpackChunkName: "questions" */ '@/views/questions/questions-view.vue'),
      },
    ],
  },
  {
    path: '/operatingNumbers',
    component: DefaultLayout,
    meta: {
      title: 'operatingNumbers.title',
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'operatingNumbers',
        component: () =>
          import(/* webpackChunkName: "operatingNumbers" */ '@/views/operatingNumbers/operating-numbers-view.vue'),
      },
    ],
  },
  {
    path: '/about',
    component: HomeLayout,
    meta: {
      title: 'aboutUs.title',
    },
    children: [
      {
        path: '',
        name: 'aboutUs',
        component: () => import(/* webpackChunkName: "ImpressPage" */ '@/views/legal/about-us-view.vue'),
      },
    ],
  },
  {
    path: '/privacyPolicy',
    component: HomeLayout,
    meta: {
      title: 'privacyPolicy.title',
    },
    children: [
      {
        path: '',
        name: 'privacyPolicy',
        component: () => import(/* webpackChunkName: "DatenschutzPage" */ '@/views/legal/privacy-policy-view.vue'),
      },
    ],
  },
  {
    path: '/termsOfUse',
    component: HomeLayout,
    meta: {
      title: 'termsOfUse.title',
    },
    children: [
      {
        path: '',
        name: 'termsOfUse',
        component: () => import(/* webpackChunkName: "TermsOfUsePage" */ '@/views/legal/terms-of-use-view.vue'),
      },
    ],
  },

  {
    path: '*',
    redirect: '/',
  },
]

export default routes
