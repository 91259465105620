import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import store, { useNotify } from '@/store'

const { addNotification } = useNotify()

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: false })],
    beforeSend(event, hint) {
      const currentUser = store.state.auth.currentUser

      event.user = {
        ...event.user,
        ...{
          email: currentUser?.email || '',
          id: currentUser?.id.toString() || '',
        },
      }

      const message =
        (hint?.originalException as any)?.userMessage || (hint?.originalException as any)?.message || 'Unbekannt'

      addNotification({
        type: 'error',
        text: `Error: ${message}`,
        sentryEventId: event.event_id,
      })

      return event
    },
  })
}
