import { useAxios, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { BaseModel } from '.'
import { ZoneType } from './zoneTypes'

const version = 'v1'
const resource = 'zones'

export interface ZoneInput {
  division: number
  name: string
  responsibleUser: number | null
  type: number
  unofficialName: string
  client?: number
}

export interface Zone extends BaseModel {
  client: number
  division: number
  name: string
  responsibleUser: number | null
  type: ZoneType
  unofficialName: string
}

export const useGetZones = () => {
  const axios = useAxiosPaginated<Zone>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getZones = (paginationParams: PaginationParams) => axios.exec({ params: { ...paginationParams } })

  return {
    ...axios,
    getZones,
  }
}

export const useGetZone = () => {
  const axios = useAxios<Zone>({
    method: 'get',
  })

  const getZone = (zoneId: number) => axios.exec({ url: `/${version}/${resource}/${zoneId}` })

  return {
    ...axios,
    getZone,
  }
}

export const useCreateZone = () => {
  const axios = useAxios<Zone>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createZone = (newZone: ZoneInput) => axios.exec({ data: newZone })

  return {
    ...axios,
    createZone,
  }
}

export const useUpdateZone = () => {
  const axios = useAxios<Zone>({
    method: 'put',
  })

  const updateZone = (zoneId: number, zone: ZoneInput) =>
    axios.exec({ url: `/${version}/${resource}/${zoneId}`, data: zone })

  return {
    ...axios,
    updateZone,
  }
}

export const useDeleteZone = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteZone = (zoneId: number) => axios.exec({ url: `/${version}/${resource}/${zoneId}` })

  return {
    ...axios,
    deleteZone,
  }
}

export interface UpdateZonePartialInput {
  division?: number
  name?: string
  responsibleUser?: number
  type?: number
  unofficialName?: string
}

export const useUpdateZonePartial = () => {
  const axios = useAxios<Zone>({
    method: 'patch',
  })

  const updateZonePartial = (zone: UpdateZonePartialInput & { id: number }) =>
    axios.exec({ url: `/${version}/${resource}/${zone.id}`, data: zone })

  return {
    ...axios,
    updateZonePartial,
  }
}
