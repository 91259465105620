
import { defineComponent, reactive, ref } from '@vue/composition-api'

import { useAuthGetters, useNotify } from '@/store'

import { Contact, ContactLoggedIn } from '@/types/contact'
import { useSendMessage } from '@/api/contact'

import { ValidationForm } from '@/utils/validation'
import { required, email as emailRule } from '@/utils/validation'

export default defineComponent({
  name: 'contact-form',
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const { isAuthenticated } = useAuthGetters()

    const { sendMessage: sendMessageXhr } = useSendMessage()

    const formElement = ref<ValidationForm | null>(null)
    const isFormValid = ref(false)

    function createFormData(): Contact | ContactLoggedIn {
      const formData = {
        subject: '',
        message: '',
      }

      if (isAuthenticated.value) return formData
      else
        return {
          ...formData,
          subject: '',
          message: '',
        }
    }

    const formData: Contact | ContactLoggedIn = reactive(createFormData())

    async function sendMessage(): Promise<void> {
      if (formElement.value?.validate()) {
        try {
          await sendMessageXhr(formData)

          addNotification({
            text: root.$t('contact.messageSent.success') as string,
            type: 'success',
            timeout: 2500,
          })

          formElement.value.reset()
          formElement.value.resetValidation()
        } catch (error) {
          error.userMessage = root.$t('contact.messageSent.error') as string

          throw error
        }
      }
    }

    return reactive({
      formElement,
      state: {
        isAuthenticated,

        isFormValid,
        formData,
      },
      functions: {
        sendMessage,
      },
      formRules: {
        required,
        emailRule,
      },
    })
  },
})
