import vuexStore from '@/store'

import { Actions, Notification } from './index'

// export const useNotifyGetters = () => {
//   // const doubleState = computed((): number => store.getters[Getters.doubleState])

//   // return {
//   //   doubleState,
//   // }
// }

export const useNotify = (store = vuexStore) => {
  const addNotification = (notification: Notification): Promise<number> => {
    return store.dispatch(Actions.addNotification, notification)
  }
  const deleteNotification = (id: number) => {
    store.dispatch(Actions.deleteNotification, id)
  }
  const shiftNotifications = () => {
    store.dispatch(Actions.shiftNotifications)
  }

  return {
    addNotification,
    deleteNotification,
    shiftNotifications,
  }
}
