
import { useGetClients, useGetZoneTypes as useGetZoneTypesForClient } from '@/api/clients'
import { QuestionnaireInput } from '@/api/questionnaires'
import { Rights } from '@/api/rights'
import { useGetZone } from '@/api/zones'
import { useGetZoneTypes, ZoneType } from '@/api/zoneTypes'
import { useAuthGetters } from '@/store'
import { required } from '@/utils/validation'
import { defineComponent, PropType, reactive, ref, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'questionnaire-form',

  props: {
    value: {
      type: Object as PropType<QuestionnaireInput>,
      required: true,
    },
  },

  setup: (props, { root, emit }) => {
    const { hasRights } = useAuthGetters(root.$store)

    const { getZone } = useGetZone()

    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasZoneTypeReadRight = hasRights.value([Rights.ZONE_TYPE_READ, Rights.ZONE_TYPE_CLIENT_READ])

    const { getClients, data: clients } = useGetClients()
    const { getZoneTypes: getZoneTypesRequest } = useGetZoneTypes()
    const { getZoneTypes: getZoneTypesForClientRequest } = useGetZoneTypesForClient()

    const zoneTypes = ref<ZoneType[]>([])

    const questionnaire = reactive({ ...props.value })

    const updateQuestionnaire = () => {
      emit('input', questionnaire)
    }

    const getZoneTypes = () => {
      getZoneTypesRequest({ page: 0, size: 9999 }, { public: true }).then((data) => {
        zoneTypes.value = data
      })
    }
    const getZoneTypesForClient = (clientId: number) => {
      getZoneTypesForClientRequest({ page: 0, size: 9999 }, clientId).then((data) => {
        zoneTypes.value = data
      })
    }

    const onClientUpdated = (clientId: number | null) => {
      questionnaire.zoneType = 0
      if (clientId) getZoneTypesForClient(clientId)
      else getZoneTypes()
    }

    if (hasClientReadRight) {
      getClients({ page: 0, size: 9999 })
    } else {
      if (!questionnaire.client) {
        questionnaire.client = root.$store.state.auth.currentUser.client
        updateQuestionnaire()
      }
    }
    if (hasZoneTypeReadRight) {
      if (questionnaire.client) {
        getZoneTypesForClient(questionnaire.client)
      } else {
        getZoneTypes()
      }
    } else {
      getZone(root.$store.state.auth.currentUser.zone).then((zone) => {
        questionnaire.zoneType = typeof zone.type === 'number' ? zone.type : zone.type.id
        updateQuestionnaire()
      })
    }

    return {
      ...toRefs(questionnaire),
      hasClientReadRight,
      hasZoneTypeReadRight,
      required,
      clients,
      zoneTypes,
      updateQuestionnaire,
      onClientUpdated,
    }
  },
})
