
import { computed, defineComponent, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { useGetDivisions } from '@/api/divisions'
import { Division } from '@/api/divisions'
import { Rights } from '@/api/rights'
import { useAuthGetters } from '@/store'
import { TableHeader } from '@/types'

import { mdiPencil, mdiDelete, mdiPlus } from '@mdi/js'

export default defineComponent({
  name: 'admin-divisions-view',
  components: {
    AddDivisionDialog: () => import('./add-division-dialog.vue'),
    EditDivisionDialog: () => import('./edit-division-dialog.vue'),
    DeleteDivisionDialog: () => import('./delete-division-dialog.vue'),
  },
  setup: (_, { root }) => {
    const { getDivisions, data: divisions, isLoading: divisionsLoading } = useGetDivisions()
    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasCreateRight = hasRights.value([
      Rights.DIVISION_CREATE,
      Rights.DIVISION_CLIENT_CREATE,
      Rights.DIVISION_DIVISION_CREATE,
    ])
    const hasUpdateRight = hasRights.value([
      Rights.DIVISION_UPDATE,
      Rights.DIVISION_CLIENT_UPDATE,
      Rights.DIVISION_DIVISION_UPDATE,
    ])
    const hasDeleteRight = hasRights.value([Rights.DIVISION_DELETE, Rights.DIVISION_CLIENT_DELETE])

    // Client select for superadmins
    const clients = computed(() => root.$store.state.clients.clients)
    const selectedClients = ref<number[]>([])

    // Divisions filtered by selected clients
    const filteredDivisions = ref<Division[]>([])

    // update divisions to display
    const filterDivisions = () => {
      filteredDivisions.value = divisions.value.filter((division) => selectedClients.value.includes(division.client))
      createTreeStructure()
    }

    const treeDivisions = ref<Division[]>([])
    const removedDivisions = ref<Division[]>([])

    const populateNumberedChildren = (intChildren: number[]): Division[] => {
      const children: Division[] = intChildren
        .map((child) => filteredDivisions.value.find((division) => division.id === child))
        .filter((child) => child !== undefined) as Division[]
      return children
    }

    const populateDivisionedChildren = (intChildren: Division[]) => {
      const children: Division[] = intChildren.map((child) =>
        removedDivisions.value.find((division) => division.id === child.id)
      ) as Division[]
      return children
    }

    const populateInitialDivisions = () => {
      treeDivisions.value = filteredDivisions.value.map((division) => ({
        ...division,
        childDivisions: populateNumberedChildren(division.childDivisions as number[]),
      }))
    }

    const removeAllDivisionsWithNoChildren = () => {
      let leafDivisions: Division[] = []
      leafDivisions = treeDivisions.value.filter((division) => division.childDivisions.length === 0)
      for (const leaf of leafDivisions) {
        treeDivisions.value = treeDivisions.value.filter((division) => division.id !== leaf.id)
      }

      removedDivisions.value = cloneDeep(leafDivisions)
    }

    const setRootDivisionsAfterTreePopulation = () => {
      treeDivisions.value = removedDivisions.value
    }

    const createTreeStructure = () => {
      populateInitialDivisions()
      removeAllDivisionsWithNoChildren()

      while (treeDivisions.value.length) {
        treeDivisions.value.forEach((division) => populateParentOnlyIfAllChildrenArePopulated(division))
      }

      setRootDivisionsAfterTreePopulation()
    }

    const getNumberOfUnpopulatedChildren = (children: Division[] | number[]): number => {
      let numberOfUnpopulatedChildren = 0
      children.forEach((child) => {
        if (
          treeDivisions.value.find((division) => {
            return child.id === division.id
          })
        )
          numberOfUnpopulatedChildren++
      })
      return numberOfUnpopulatedChildren
    }

    const populateParentOnlyIfAllChildrenArePopulated = (parent: Division) => {
      let numberOfUnpopulatedChildren = 0
      numberOfUnpopulatedChildren = getNumberOfUnpopulatedChildren(parent.childDivisions)
      if (numberOfUnpopulatedChildren === 0) {
        const index = treeDivisions.value.findIndex((division) => division.id === parent.id)
        parent.childDivisions = populateDivisionedChildren(parent.childDivisions as Division[])
        treeDivisions.value.splice(index, 1)
        removedDivisions.value.push(parent)
        for (const child of parent.childDivisions) {
          removedDivisions.value = removedDivisions.value.filter((division) => division.id !== child.id)
        }
      }
    }

    // fetch new divisions
    const loadDivisions = (clientId: number) => {
      clientIdsOfLoadedDivisions.push(clientId)
      getDivisions(clientId).then(() => {
        filterDivisions()
      })
    }

    // list of clients for which divisions have been loaded
    const clientIdsOfLoadedDivisions: number[] = []

    // load divisions for every client not already loaded
    const onSelectedClientsChanged = (clientIds: number[]) => {
      filterDivisions()

      for (const clientId of clientIds) {
        if (!clientIdsOfLoadedDivisions.includes(clientId)) {
          loadDivisions(clientId)
        }
      }
    }

    // if user can't read clients use the users client
    if (!hasClientReadRight) {
      selectedClients.value = [root.$store.state.auth.currentUser.client]
      loadDivisions(root.$store.state.auth.currentUser.client)
    }

    // Edit Division
    const isEditActive = ref(false)
    const divisionToEdit = ref<Division | null>(null)

    const openEditDialog = (division: Division) => {
      if (hasUpdateRight) {
        divisionToEdit.value = division
        isEditActive.value = true
      }
    }

    const updateDivision = (editedDivision: Division) => {
      loadDivisions(editedDivision.client)
    }

    // Add Division
    const isCreateActive = ref(false)

    const newDivisionParent = ref<Division | null>(null)

    const openAddDialog = (division: Division) => {
      if (hasCreateRight) {
        if (division) {
          newDivisionParent.value = division
          isCreateActive.value = true
        }
      }
    }

    const addDivision = (newDivision: Division) => {
      loadDivisions(newDivision.client)
    }

    //Delete Division
    const isDeleteActive = ref(false)
    const divisionToDelete = ref<Division | null>(null)

    const openDeleteDialog = (division: Division) => {
      if (hasDeleteRight) {
        divisionToDelete.value = division
        isDeleteActive.value = true
      }
    }

    const removeDivision = (divisionToRemove: Division) => {
      loadDivisions(divisionToRemove.client)
    }

    return {
      icons: { mdiPencil, mdiDelete, mdiPlus },
      selectedClients,
      clients,
      divisionsLoading,
      filteredDivisions,
      onSelectedClientsChanged,
      hasClientReadRight,
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      openEditDialog,
      updateDivision,
      isCreateActive,
      openAddDialog,
      newDivisionParent,
      addDivision,
      isEditActive,
      divisionToEdit,
      isDeleteActive,
      divisionToDelete,
      openDeleteDialog,
      removeDivision,
      treeDivisions,
    }
  },
})
