
import { defineComponent } from '@vue/composition-api'

import ProfileCard from './profile-card.vue'
import ChangePasswordCard from './change-password-card.vue'

export default defineComponent({
  name: 'profile-view',
  components: {
    ProfileCard,
    ChangePasswordCard,
  },
})
