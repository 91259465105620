
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { Zone, useUpdateZone, ZoneInput } from '@/api/zones'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

import { mdiPencil } from '@mdi/js'

import ZoneForm from './zone-form.vue'

export default defineComponent({
  name: 'edit-zone-dialog',
  components: {
    ZoneForm,
  },
  props: {
    zone: {
      type: Object as PropType<Zone>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { updateZone, isLoading } = useUpdateZone()

    const { addNotification } = useNotify()

    const editZoneForm = ref<ValidationForm | null>(null)

    const zoneToEdit = ref<ZoneInput>({
      ...props.zone,
      type: props.zone.type.id,
    })

    const editZone = () => {
      if (editZoneForm.value?.validate()) {
        updateZone(props.zone.id, zoneToEdit.value)
          .then((editedZone) => {
            addNotification({
              type: 'success',
              text: root.$t('zones.update.success', {
                name: editedZone.name,
              }) as string,
            })
            emit('zone-edited', editedZone)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('zones.update.error', {
              name: zoneToEdit.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPencil },
      editZoneForm,
      zoneToEdit,
      editZone,
      isLoading,
    }
  },
})
