<template>
  <v-container>
    <v-card>
      <v-card-title class="text-h4 primary onPrimary--text"> {{ $t('privacyPolicy.title') }}</v-card-title>
      <v-card-text> <p v-html="$t('privacyPolicy.content')" /> </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  export default defineComponent({
    name: 'privacy-policy-view',
  })
</script>

<style></style>
