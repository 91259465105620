import { render, staticRenderFns } from "./questionnaires-view.vue?vue&type=template&id=461490be&scoped=true&"
import script from "./questionnaires-view.vue?vue&type=script&lang=ts&"
export * from "./questionnaires-view.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461490be",
  null
  
)

export default component.exports