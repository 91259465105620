import { useAxios } from '@/utils/axios'

const version = 'v1'

export interface LoginCredentials {
  username: string
  password: string
}

export interface TokenResponse {
  token: string
}

export interface SetPasswordRequest {
  password: string
  resetToken: string
}

export const useGetToken = () => {
  const axios = useAxios<TokenResponse>({
    method: 'post',
    url: '/auth/token',
  })

  const getToken = (credentials: LoginCredentials) => axios.exec({ data: credentials })

  return {
    ...axios,
    getToken,
  }
}

export const useRenewToken = () => {
  const axios = useAxios<TokenResponse>({
    method: 'post',
    url: '/auth/renew',
  })

  const renewToken = () => axios.exec()

  return {
    ...axios,
    renewToken,
  }
}

export const useResetPassword = () => {
  const axios = useAxios<void>({
    method: 'post',
    headers: {
      'content-type': 'text/plain',
    },
    url: `/${version}/reset-credentials`,
  })

  const resetPassword = (username: string) => axios.exec({ data: username })

  return {
    ...axios,
    resetPassword,
  }
}

export const useSetPassword = () => {
  const axios = useAxios<void>({
    method: 'post',
    url: `/${version}/set-password`,
  })

  const setPassword = (request: SetPasswordRequest) => axios.exec({ data: request })

  return {
    ...axios,
    setPassword,
  }
}
