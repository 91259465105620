import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#00b5d6',
        onPrimary: '#ffffff',
        secondary: '#5e5e5e',
        accent: '#FFEB3B',
        error: '#D0021B',
        info: '#2196F3',
        success: '#7ED321',
        warning: '#F5A623',
      },
      dark: {
        primary: '#00b5d6',
        onPrimary: '#ffffff',
        secondary: '#5e5e5e',
        accent: '#FFEB3B',
        error: '#D0021B',
        info: '#2196F3',
        success: '#7ED321',
        warning: '#F5A623',
      },
    },
  },
})

export default vuetify
