
import { computed, defineComponent, PropType, ComputedRef, reactive, toRefs } from '@vue/composition-api'

import { resolveCountry, tf } from '@/plugins/i18n'
import { rolesApi } from '@/api'
import { useGetClients, useGetDivisionZones } from '@/api/clients'
import { useGetDivisions } from '@/api/divisions'
import { User, UserUpdate } from '@/api/users'
import { UserRoleDisplay } from '@/api/roles'
import { useAuthGetters } from '@/store'

import { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiKey } from '@mdi/js'
import { required, email as emailRule } from '@/utils/validation'

import CommonFlagIcon from '@/components/common/common-flag-icon.vue'
import CommonNewPasswordInput from '@/components/common/common-new-password-input.vue'
import { Rights } from '@/api/rights'

export default defineComponent({
  name: 'user-form',
  components: {
    CommonFlagIcon,
    CommonNewPasswordInput,
  },
  props: {
    value: {
      type: Object as PropType<UserUpdate>,
      required: true,
    },
    isCreate: {
      type: Boolean,
      required: false,
    },
  },
  setup: (props, { root, emit }) => {
    const currentUser = computed<User>(() => root.$store.state.auth.currentUser)
    const { hasRights } = useAuthGetters(root.$store)

    const { exec: getClients, data: clients } = useGetClients()
    const { getDivisions, data: divisions } = useGetDivisions()
    const { getDivisionZones, data: zones } = useGetDivisionZones()

    const emptyUser: UserUpdate = {
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      locale: '',
      phone: '',
      mobile: '',
      enabled: false,
      role: undefined,
      password: '',
      client: undefined,
      division: undefined,
      zone: undefined,
    }

    const user = reactive({ ...emptyUser, ...props.value })

    const { getRoles, data: roles } = rolesApi.useGetRoles()
    getRoles()

    const translatedRoles: ComputedRef<UserRoleDisplay[]> = computed(() =>
      roles.value.map((r) => Object.assign(r, { displayName: tf(`roles.names.${r.name}`, r.name).toString() }))
    )

    const updateUser = () => {
      emit('input', user)
    }

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasDivisionReadRight = hasRights.value([Rights.DIVISION_READ, Rights.DIVISION_CLIENT_READ])
    const hasZoneReadRight = hasRights.value([Rights.ZONE_READ, Rights.ZONE_CLIENT_READ, Rights.ZONE_DIVISION_READ])

    /**
     * load initial data based on admin level
     *
     * superadmin can choose all client, division, and zone
     * clientadmin can choose division, and zone since client is set
     * divisionadmin can choose zone since client and division are set
     * zoneadmin and down can't choose anything
     */
    if (hasClientReadRight) {
      getClients()

      // if data is already set (edit user) load other options as well
      if (user.client) {
        getDivisions(user.client)
        if (user.division) getDivisionZones(user.client, user.division)
      }
    } else if (hasDivisionReadRight) {
      if (user.client === undefined) {
        user.client = currentUser.value.client
        updateUser()
      }

      getDivisions(user.client)

      // if data is already set (edit user) load other options as well
      if (user.division) getDivisionZones(user.client, user.division)
    } else if (hasZoneReadRight) {
      if (user.client === undefined) {
        user.client = currentUser.value.client
      }
      if (user.division === undefined) {
        user.division = currentUser.value.division
      }
      updateUser()

      getDivisionZones(currentUser.value.client, currentUser.value.division)
    } else {
      if (user.client === undefined) {
        user.client = currentUser.value.client
      }
      if (user.division === undefined) {
        user.division = currentUser.value.division
      }
      if (user.zone === undefined) {
        user.zone = currentUser.value.zone
      }
      updateUser()
    }

    const onClientInput = (clientId: number) => {
      getDivisions(clientId)
      user.division = undefined
      user.zone = undefined
      updateUser()
    }
    const onDivisionInput = (divisionId: number) => {
      getDivisionZones(user.client || currentUser.value.client, divisionId)
      user.zone = undefined
      updateUser()
    }

    return {
      icons: { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiKey },
      ...toRefs(user),
      updateUser,
      required,
      emailRule,
      roles: translatedRoles,
      implementedLocales,
      resolveCountry,
      breakpoint: root.$vuetify.breakpoint,
      clients,
      divisions,
      zones,
      onClientInput,
      onDivisionInput,
      hasClientReadRight,
      hasDivisionReadRight,
      hasZoneReadRight,
    }
  },
})
