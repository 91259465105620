import { Ref, ref } from '@vue/composition-api'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { axiosInstance } from '@/plugins/axios'

import { Page, PaginationParams, PaginationResponse } from '@/utils/pagination'

export interface UseAxios<T> {
  data: Ref<T | null>
  status: Ref<number>
  statusText: Ref<string>
  isLoading: Ref<boolean>
  error: Ref<any>
  exec: (config?: AxiosRequestConfig, throwException?: boolean) => Promise<T>
}

export const useAxios = <T = any>(baseCfg: AxiosRequestConfig = {}, instance = axiosInstance) => {
  const status = ref(0)
  const statusText = ref('')

  const data: Ref<T | null> = ref(null)

  const isLoading = ref(false)

  const error = ref<any>(null)

  const exec = async (cfg: AxiosRequestConfig = {}, throwException = true) => {
    isLoading.value = true
    try {
      const result: AxiosResponse<T> = await instance.request({ ...baseCfg, ...cfg })
      data.value = result.data
      status.value = result.status
      return result.data
    } catch (e) {
      error.value = e
      if (throwException) throw e
      return Promise.reject(e)
    } finally {
      isLoading.value = false
    }
  }

  return {
    data,
    status,
    statusText,
    isLoading,
    error,
    exec,
  }
}

export const useAxiosArray = <T = any>(baseCfg: AxiosRequestConfig = {}, instance = axiosInstance) => {
  const axios = useAxios<T[]>(baseCfg, instance)

  axios.data.value = []
  const data = axios.data as Ref<T[]>

  return {
    ...axios,
    data,
  }
}

export interface UseAxiosPaginated<T> {
  data: Ref<T[]>
  status: Ref<number>
  statusText: Ref<string>
  isLoading: Ref<boolean>
  error: Ref<any>
  paginationParams: Ref<PaginationParams>
  exec: (config?: AxiosRequestConfig, throwException?: boolean) => Promise<T[]>
}

export const useAxiosPaginated = <T = any>(baseCfg: AxiosRequestConfig = {}, instance = axiosInstance) => {
  const paginationResponse = ref<PaginationResponse>({
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      sort: {
        sorted: false,
        unsortet: false,
        empty: false,
      },
      unpaged: false,
    },
    size: 0,
    sort: {
      sorted: false,
      unsortet: false,
      empty: false,
    },
    totalElements: 0,
    totalPages: 0,
  })

  const data: Ref<T[]> = ref([])

  const axios = useAxios<Page<T>>(baseCfg, instance)

  const exec = async (cfg: AxiosRequestConfig = {}, throwException = true) =>
    axios.exec(cfg, throwException).then((response) => {
      const { content, ...paginationParams } = response
      data.value = content
      paginationResponse.value = paginationParams
      return content
    })

  return {
    ...axios,
    data,
    exec,
    paginationResponse,
  }
}
