var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 primary onPrimary--text"},[_vm._v(" "+_vm._s(_vm.$t('admin.roles.title'))+" "),_c('v-spacer'),(_vm.hasCreateRight)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.isCreateActive = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('form.action.addRole'))+" ")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"hoverable":"","items":_vm.roles,"loading":_vm.isLoading,"server-items-length":_vm.totalRoles,"options":_vm.vuetifyTableOptions},on:{"update:options":[function($event){_vm.vuetifyTableOptions=$event},function($event){return _vm.getRoles()}]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tf(("roles.names." + (item.name)), item.name))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tf(("roles.descriptions." + (item.name)), item.description || '–'))+" ")]}},{key:"item.protected",fn:function(ref){
var item = ref.item;
return [(item.protected)?_c('v-icon',{attrs:{"title":"Protected"}},[_vm._v(_vm._s(_vm.icons.mdiShieldLock))]):_vm._e()]}},{key:"item.rights",fn:function(ref){
var item = ref.item;
return _vm._l((item.rights),function(right){return _c('v-chip',{key:right.id,staticClass:"ma-1",attrs:{"small":"","ripple":false}},[_c('v-tooltip',{attrs:{"top":"","disabled":!right.description},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(right.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(right.description))])])],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasUpdateRight && !item.protected)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e(),(_vm.hasDeleteRight && !item.protected)?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"scrollable":"","width":"1000"},model:{value:(_vm.isCreateActive),callback:function ($$v) {_vm.isCreateActive=$$v},expression:"isCreateActive"}},[(_vm.isCreateActive)?_c('add-role-dialog',{on:{"role-added":function($event){return _vm.getRoles()},"close":function($event){_vm.isCreateActive = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"scrollable":"","width":"1000"},model:{value:(_vm.isEditActive),callback:function ($$v) {_vm.isEditActive=$$v},expression:"isEditActive"}},[(_vm.isEditActive)?_c('edit-role-dialog',{attrs:{"role":_vm.roleToEdit},on:{"role-edited":_vm.onRoleEdited,"close":function($event){_vm.isEditActive = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.isDeleteActive),callback:function ($$v) {_vm.isDeleteActive=$$v},expression:"isDeleteActive"}},[(_vm.isDeleteActive)?_c('delete-role-dialog',{attrs:{"role":_vm.roleToDelete},on:{"role-deleted":function($event){return _vm.getRoles()},"close":function($event){_vm.isDeleteActive = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }