
import { computed, defineComponent, PropType, reactive, ref, toRefs } from '@vue/composition-api'

import { QuestionCategories, QuestionInput } from '@/api/questions'
import { useAuthGetters, useClientsGetters } from '@/store'
import { Rights } from '@/api/rights'
import { useGetClients, useGetZoneTypes as useGetZoneTypesForClient } from '@/api/clients'
import { useGetZoneTypes, ZoneType } from '@/api/zoneTypes'
import { User } from '@/api/users'

import { required } from '@/utils/validation'

export default defineComponent({
  name: 'question-form',

  props: {
    value: {
      type: Object as PropType<QuestionInput>,
      required: true,
    },
    lockSettings: {
      type: Boolean,
      required: false,
    },
  },

  setup: (props, { emit, root }) => {
    const { hasRights } = useAuthGetters(root.$store)
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])

    const currentUser: User = root.$store.state.auth.currentUser

    const { exec: getClients, data: clients } = useGetClients()
    const { getZoneTypes: getZoneTypesRequest } = useGetZoneTypes()
    const { getZoneTypes: getZoneTypesForClientRequest } = useGetZoneTypesForClient()
    const zoneTypes = ref<ZoneType[]>([])

    const getZoneTypes = () => {
      // filter for zone types without client because only global zonetypes can be choosen for global questions
      getZoneTypesRequest({ page: 0, size: 9999 }, { public: true }).then((newZoneTypes) => {
        zoneTypes.value = newZoneTypes
      })
    }

    const getZoneTypesForClient = (clientId: number) => {
      getZoneTypesForClientRequest({ page: 0, size: 9999 }, clientId).then((newZoneTypes) => {
        zoneTypes.value = newZoneTypes
      })
    }

    const question = reactive({ ...props.value })

    const updateQuestion = () => {
      emit('input', question)
    }

    if (hasClientReadRight) {
      getClients()
      if (!question.client) getZoneTypes()
      else getZoneTypesForClient(question.client)
    } else {
      if (!question.client) question.client = currentUser.client
      getZoneTypesForClient(question.client)
    }

    const onClientInput = (clientId: number | undefined) => {
      if (clientId) {
        getZoneTypesForClient(clientId)
      } else {
        getZoneTypes()
      }
      question.client = clientId
      question.zoneType = 0
      updateQuestion()
    }

    const { ownClient } = useClientsGetters(root.$store)
    const isAuditingModeFiveS = computed(() => ownClient.value?.auditingMode === 'FIVE_S')

    const categories = reactive([
      {
        text: root.$tc(`questions.category.${QuestionCategories.SORT}`, isAuditingModeFiveS ? 1 : 2),
        value: QuestionCategories.SORT,
      },
      {
        text: root.$tc(`questions.category.${QuestionCategories.SET_IN_ORDER}`, isAuditingModeFiveS ? 1 : 2),
        value: QuestionCategories.SET_IN_ORDER,
      },
      {
        text: root.$tc(`questions.category.${QuestionCategories.SHINE}`, isAuditingModeFiveS ? 1 : 2),
        value: QuestionCategories.SHINE,
      },
      {
        text: root.$tc(`questions.category.${QuestionCategories.STANDARDIZE}`, isAuditingModeFiveS ? 1 : 2),
        value: QuestionCategories.STANDARDIZE,
      },
      {
        text: root.$tc(`questions.category.${QuestionCategories.SUSTAIN}`, isAuditingModeFiveS ? 1 : 2),
        value: QuestionCategories.SUSTAIN,
      },
    ])

    return {
      ...toRefs(question),
      hasClientReadRight,
      updateQuestion,
      required,
      clients,
      zoneTypes,
      onClientInput,
      categories,
    }
  },
})
