
import { defineComponent } from '@vue/composition-api'

import ContactForm from './contact-form.vue'

export default defineComponent({
  name: 'contact-view',
  components: {
    ContactForm,
  },
})
