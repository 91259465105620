
import { defineComponent, ref, computed } from '@vue/composition-api'

import { tf } from '@/plugins/i18n'
import { useAuthGetters } from '@/store'
import { User, useGetUsers } from '@/api/users'
import { Rights } from '@/api/rights'
import { DataTableHeader } from 'vuetify'

import { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete } from '@mdi/js'
import { usePagination } from '@/utils/pagination'

import EditUserDialog from './edit-user-dialog.vue'
import AddUserDialog from './add-user-dialog.vue'
import DeleteUserDialog from './delete-user-dialog.vue'
import { Client, useGetClient } from '@/api/clients'
import { Division, useGetDivision } from '@/api/divisions'
import { Zone, useGetZone } from '@/api/zones'

interface TableHeader extends DataTableHeader {
  show: () => boolean
}

export default defineComponent({
  name: 'admin-users-view',
  components: {
    AddUserDialog,
    EditUserDialog,
    DeleteUserDialog,
  },
  setup(props, { root }) {
    const { getUsers: getUsersRequest, data: users, isLoading, paginationResponse } = useGetUsers()

    const totalUsers = computed(() => paginationResponse.value.totalElements)

    const { vuetifyTableOptions, paginationParams } = usePagination()

    const { hasRights } = useAuthGetters()
    const hasCreateRight = hasRights.value([
      Rights.USER_CREATE,
      Rights.USER_CLIENT_CREATE,
      Rights.USER_DIVISION_CREATE,
      Rights.USER_ZONE_CREATE,
    ])
    const hasUpdateRight = hasRights.value([
      Rights.USER_UPDATE,
      Rights.USER_CLIENT_UPDATE,
      Rights.USER_DIVISION_UPDATE,
      Rights.USER_ZONE_UPDATE,
    ])
    const hasDeleteRight = hasRights.value([
      Rights.USER_DELETE,
      Rights.USER_CLIENT_DELETE,
      Rights.USER_DIVISION_DELETE,
      Rights.USER_ZONE_DELETE,
    ])
    const hasClientReadRight = hasRights.value([Rights.CLIENT_READ])
    const hasDivisionReadRight = hasRights.value([Rights.DIVISION_READ, Rights.DIVISION_CLIENT_READ])
    const hasZoneReadRight = hasRights.value([Rights.ZONE_READ, Rights.ZONE_CLIENT_READ, Rights.ZONE_DIVISION_READ])

    /**
     * Base Headers of the table every user that can visit this site can see
     */
    const defaultHeaders: TableHeader[] = [
      {
        text: root.$t('form.field.id') as string,
        value: 'id',
        show: () => true,
      },
      {
        text: root.$t('form.field.firstName') as string,
        value: 'firstName',
        show: () => true,
      },
      {
        text: root.$t('form.field.lastName') as string,
        value: 'lastName',
        show: () => true,
      },
      {
        text: root.$t('form.field.enabled') as string,
        value: 'enabled',
        show: () => true,
      },
      {
        text: root.$t('form.field.email') as string,
        value: 'email',
        show: () => true,
      },
      {
        text: root.$tc('form.field.client', 1) as string,
        value: 'client',
        show: () => hasClientReadRight,
      },
      {
        text: root.$tc('form.field.division', 1) as string,
        value: 'division',
        show: () => hasDivisionReadRight,
      },
      {
        text: root.$tc('form.field.zone', 1) as string,
        value: 'zone',
        show: () => hasZoneReadRight,
      },
      {
        text: root.$t('form.field.phone') as string,
        value: 'phone',
        show: () => true,
      },
      {
        text: root.$t('form.field.mobile') as string,
        value: 'mobile',
        show: () => true,
      },
      {
        text: root.$tc('form.field.locale', 1) as string,
        value: 'locale',
        show: () => true,
      },
      {
        text: root.$t('form.field.role') as string,
        value: 'role',
        show: () => true,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        show: () => hasUpdateRight || hasDeleteRight,
      },
    ]

    /**
     * Table Headers with the Actions tab if the unser can either edit or delete
     */
    const tableHeaders = defaultHeaders.filter((header) => header.show())

    const { getClient } = useGetClient()
    const { getDivision } = useGetDivision()
    const { getZone } = useGetZone()

    const clients = ref<{ [key: string]: Client }>({})
    const divisions = ref<{ [key: string]: Division }>({})
    const zones = ref<{ [key: string]: Zone }>({})

    const getUsers = async () => {
      try {
        getUsersRequest(paginationParams.value).then((users) => {
          // load data (names) which are not in the user objects and which arent loaded already
          const clientsToFetch = hasClientReadRight ? [...new Set(users.map((user) => user.client))] : []
          const divisionsToFetch = hasDivisionReadRight ? [...new Set(users.map((user) => user.division))] : []
          const zonesToFetch = hasZoneReadRight ? [...new Set(users.map((user) => user.zone))] : []

          clientsToFetch.map((client) =>
            clients.value[client]
              ? ''
              : getClient(client).then((client) => {
                  root.$set(clients.value, client.id, client)
                })
          )
          divisionsToFetch.map((division) =>
            divisions.value[division]
              ? ''
              : getDivision(division).then((division) => {
                  root.$set(divisions.value, division.id, division)
                })
          )
          zonesToFetch.map((zone) =>
            zones.value[zone]
              ? ''
              : getZone(zone).then((zone) => {
                  root.$set(zones.value, zone.id, zone)
                })
          )
        })
      } catch (error) {
        error.userMessage = root.$t('users.get.error')

        throw error
      }
    }

    // Edit User
    const isEditActive = ref(false)
    const userToEdit = ref<User | null>(null)

    const openEditDialog = (user: User) => {
      if (hasUpdateRight) {
        userToEdit.value = user
        isEditActive.value = true
      }
    }

    const onUserEdited = (editedUser: User) => {
      const index = users.value.findIndex((user) => user.id === editedUser.id)
      if (index >= 0) {
        root.$set(users.value, index, editedUser)
      }
      isEditActive.value = false
    }

    // Add User
    const isCreateActive = ref(false)

    // Delete User
    const isDeleteActive = ref(false)
    const userToDelete = ref<User | null>(null)

    const openDeleteDialog = (user: User) => {
      if (hasDeleteRight) {
        userToDelete.value = user
        isDeleteActive.value = true
      }
    }

    return {
      icons: { mdiAccountPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete },
      hasCreateRight,
      hasUpdateRight,
      hasDeleteRight,
      tableHeaders,
      vuetifyTableOptions,
      isLoading,
      users,
      totalUsers,
      getUsers,
      isEditActive,
      userToEdit,
      openEditDialog,
      onUserEdited,
      isCreateActive,
      isDeleteActive,
      userToDelete,
      openDeleteDialog,
      clients,
      divisions,
      zones,
      tf,
    }
  },
})
