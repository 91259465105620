var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.tableHeaders,"hoverable":"","items":_vm.audits,"loading":_vm.isLoading || _vm.isUserLoading || _vm.isZoneLoading,"server-items-length":_vm.serverItemsLength,"options":_vm.vuetifyTableOptions},on:{"update:options":function($event){_vm.vuetifyTableOptions=$event},"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"item.questionnaire",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionnaire.name)+" ")]}},{key:"item.auditor",fn:function(ref){
var item = ref.item;
return [(_vm.users[item.auditor])?_c('v-chip',[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccount))])],1),_vm._v(" "+_vm._s(((_vm.users[item.auditor].firstName) + " " + (_vm.users[item.auditor].lastName)))+" ")],1):_vm._e()]}},{key:"item.auditee",fn:function(ref){
var item = ref.item;
return [(_vm.users[item.auditee])?_c('v-chip',[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccount))])],1),_vm._v(" "+_vm._s(((_vm.users[item.auditee].firstName) + " " + (_vm.users[item.auditee].lastName)))+" ")],1):_vm._e()]}},{key:"item.zone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.zones[item.zone] ? _vm.zones[item.zone].unofficialName || _vm.zones[item.zone].name : '')+" ")]}},{key:"item.scheduledDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocaleDateString(new Date(item.scheduledDate)))+" ")]}},{key:"item.actualDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.actualDate ? _vm.toLocaleDateString(new Date(item.actualDate)) : '')+" ")]}},{key:"item.done",fn:function(ref){
var item = ref.item;
return [(item.done)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.icons.mdiCheck))]):_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("audits.audit.type." + (item.type))))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }