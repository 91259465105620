
import { computed, defineComponent, ref } from '@vue/composition-api'
import { useNotify } from '@/store'
import { NotificationObject } from '@/store/modules/notify'

import SentryFeedbackDialog from './sentry-feedback-dialog.vue'

export default defineComponent({
  name: 'notify',
  components: {
    SentryFeedbackDialog,
  },
  setup: (props, { root }) => {
    const { shiftNotifications } = useNotify()
    const notifications = computed((): NotificationObject[] => root.$store.state.notify.notifications)

    const onHideSnackbar = () => {
      notifications.value[0].visible = false
      setTimeout(() => {
        shiftNotifications()
      }, 500)
    }

    const notification = computed<NotificationObject | null>(
      () => notifications.value[0] || { visible: false, timeout: 5000, id: 9000, text: '' }
    )

    // Sentry Feedback logic
    const showFeedbackDialog = ref(false)
    const eventId = ref('')
    const openFeedbackDialog = (event: string) => {
      eventId.value = event

      showFeedbackDialog.value = true
    }

    return {
      notification,
      onHideSnackbar,
      showFeedbackDialog,
      openFeedbackDialog,
      eventId,
    }
  },
})
