
import { defineComponent, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { QuestionnaireInput, useCreateQuestionnaire } from '@/api/questionnaires'
import { ValidationForm } from '@/utils/validation'

import { mdiPlus } from '@mdi/js'

import QuestionnaireForm from './questionnaire-form.vue'

export default defineComponent({
  name: 'add-questionnaire',
  components: {
    QuestionnaireForm,
  },
  setup(props, { root, emit }) {
    const { createQuestionnaire, isLoading } = useCreateQuestionnaire()

    const { addNotification } = useNotify()

    const addQuestionnaireForm = ref<ValidationForm | null>(null)

    const questionnaireToAdd = ref<QuestionnaireInput>({
      name: '',
      questionInstances: [],
      zoneType: 0,
      client: undefined,
    })

    const addQuestionnaire = () => {
      if (addQuestionnaireForm.value?.validate()) {
        createQuestionnaire(questionnaireToAdd.value)
          .then((newQuestionnaire) => {
            addNotification({
              type: 'success',
              text: root.$t('questionnaires.create.success', {
                name: newQuestionnaire.name,
              }) as string,
            })
            emit('questionnaire-added', newQuestionnaire)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('questionnaires.create.error', {
              name: questionnaireToAdd.value.name,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiPlus },
      addQuestionnaireForm,
      questionnaireToAdd,
      addQuestionnaire,
      isLoading,
    }
  },
})
