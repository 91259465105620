import { useAxios, useAxiosArray, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { BaseModel } from '.'
import { Question } from './questions'

const version = 'v1'
const resource = 'questionnaires'

export interface QuestionInstances {
  template: number
  position: number
  optional: boolean
}

export interface QuestionnaireInput {
  client?: number
  name: string
  questionInstances: QuestionInstances[]
  zoneType: number
}

export interface Questionnaire extends BaseModel, QuestionnaireInput {
  deleted: boolean
}

export interface QuestionnaireFilter {
  public?: boolean
  type?: number
}

export const useGetQuestionnaires = () => {
  const axios = useAxiosPaginated<Questionnaire>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getQuestionnaires = (paginationParams: PaginationParams, filters?: QuestionnaireFilter) =>
    axios.exec({ params: { ...paginationParams, ...filters } })

  return {
    ...axios,
    getQuestionnaires,
  }
}

export const useGetQuestionnaire = () => {
  const axios = useAxios<Questionnaire>({
    method: 'get',
  })

  const getQuestionnaire = (questionnaireId: number) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}` })

  return {
    ...axios,
    getQuestionnaire,
  }
}

export const useCreateQuestionnaire = () => {
  const axios = useAxios<Questionnaire>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createQuestionnaire = (newQuestionnaire: QuestionnaireInput) => axios.exec({ data: newQuestionnaire })

  return {
    ...axios,
    createQuestionnaire,
  }
}

export const useUpdateQuestionnaire = () => {
  const axios = useAxios<Questionnaire>({
    method: 'put',
  })

  const updateQuestionnaire = (questionnaire: Questionnaire) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaire.id}`, data: questionnaire })

  return {
    ...axios,
    updateQuestionnaire,
  }
}

export const useDeleteQuestionnaire = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteQuestionnaire = (questionnaireId: number) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}` })

  return {
    ...axios,
    deleteQuestionnaire,
  }
}

export interface QuestionInstanceWithQuestion extends BaseModel {
  template: Question
  position: number
  optional: boolean
}

export const useGetQuestionInstances = () => {
  const axios = useAxiosArray<QuestionInstanceWithQuestion>({
    method: 'get',
  })

  const getQuestionInstances = (questionnaireId: number) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}/questions` })

  return {
    ...axios,
    getQuestionInstances,
  }
}

export interface AddQuestionInput {
  optional: boolean
  position: number
  question: number
}

export const useAddQuestion = () => {
  const axios = useAxios<QuestionInstanceWithQuestion>({
    method: 'post',
  })

  const addQuestion = (questionnaireId: number, question: AddQuestionInput) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}/questions`, data: question })

  return {
    ...axios,
    addQuestion,
  }
}

export const useDeleteQuestion = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteQuestion = (questionnaireId: number, questionInstanceId: number) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}/questions/${questionInstanceId}` })

  return {
    ...axios,
    deleteQuestion,
  }
}

export const useUpdateQuestion = () => {
  const axios = useAxios<QuestionInstanceWithQuestion>({
    method: 'put',
  })

  const updateQuestion = (questionnaireId: number, quesitonInstanceId: number, questionInstance: AddQuestionInput) =>
    axios.exec({
      url: `/${version}/${resource}/${questionnaireId}/questions/${quesitonInstanceId}`,
      data: questionInstance,
    })

  return {
    ...axios,
    updateQuestion,
  }
}

export const useAddQuestions = () => {
  const axios = useAxios<QuestionInstanceWithQuestion>({
    method: 'post',
  })

  const addQuestions = (questionnaireId: number, questions: AddQuestionInput[]) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}/questions/batch`, data: questions })

  return {
    ...axios,
    addQuestions,
  }
}

export const useUpdateQuestions = () => {
  const axios = useAxios<QuestionInstanceWithQuestion>({
    method: 'put',
  })

  const updateQuestions = (questionnaireId: number, questions: (AddQuestionInput & { id: number })[]) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}/questions/batch`, data: questions })

  return {
    ...axios,
    updateQuestions,
  }
}

export const useDeleteQuestions = () => {
  const axios = useAxios<QuestionInstanceWithQuestion>({
    method: 'delete',
  })

  const deleteQuestions = (questionnaireId: number, questions: number[]) =>
    axios.exec({ url: `/${version}/${resource}/${questionnaireId}/questions/batch`, data: questions })

  return {
    ...axios,
    deleteQuestions,
  }
}
