
import { defineComponent, ref } from '@vue/composition-api'
import { mdiEye, mdiEyeOff } from '@mdi/js'

import { required, passwordsMatch, isPasswordStrong } from '@/utils/validation'

export default defineComponent({
  name: 'common-new-password-input',
  props: {
    value: {
      type: String,
      required: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
    },
  },
  setup: (props) => {
    const password = ref(props.value)
    const repeatPassword = ref('')
    const showPassword = ref(false)

    return {
      icons: {
        mdiEye,
        mdiEyeOff,
      },
      password,
      repeatPassword,
      showPassword,
      required,
      passwordsMatch,
      isPasswordStrong,
    }
  },
})
