import { useAxios } from '@/utils/axios'

import { Contact, ContactLoggedIn } from '@/types/contact'

const VERSION = 'v1'
const RESOURCE = 'contact'

const useSendMessage = () => {
  const axios = useAxios({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const sendMessage = (data: Contact | ContactLoggedIn) => axios.exec({ data })

  return {
    ...axios,
    sendMessage,
  }
}

export { useSendMessage }
