
import { useGetAudit } from '@/api/audits'
import { defineComponent } from '@vue/composition-api'
import AuditSummary from './audit-summary.vue'
import ConfigureAudit from './configure-audit.vue'

export default defineComponent({
  name: 'audit-view',

  components: {
    ConfigureAudit,
    AuditSummary,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup: (props) => {
    const { getAudit, data: audit, isLoading: isAuditLoading } = useGetAudit()

    getAudit(props.id)

    return {
      audit,
      isAuditLoading,
    }
  },
})
