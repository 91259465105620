
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { QuestionCategories, QuestionInput, useCreateQuestion } from '@/api/questions'
import { ValidationForm } from '@/utils/validation'

import { mdiCheck } from '@mdi/js'

import QuestionForm from './question-form.vue'

export default defineComponent({
  name: 'add-question',

  components: {
    QuestionForm,
  },

  props: {
    question: {
      type: Object as PropType<QuestionInput>,
      required: false,
      default: () => ({
        category: QuestionCategories.SORT,
        client: undefined,
        description: '',
        text: '',
        zoneType: 0,
        paragons: [],
      }),
    },
    lockSettings: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, { root, emit }) {
    const { createQuestion, isLoading } = useCreateQuestion()

    const { addNotification } = useNotify()

    const addQuestionForm = ref<ValidationForm | null>(null)

    const editQuestion = ref<QuestionInput>({
      ...props.question,
    })

    const addQuestion = () => {
      if (addQuestionForm.value?.validate()) {
        createQuestion(editQuestion.value)
          .then((newQuestion) => {
            addNotification({
              type: 'success',
              text: root.$t('questions.create.success') as string,
            })
            emit('question-added', newQuestion)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('questions.create.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      addQuestionForm,
      editQuestion,
      addQuestion,
      isLoading,
    }
  },
})
