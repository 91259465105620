import { useAxios } from '@/utils/axios'
import { User, UserUpdate } from './users'
import { UserRole } from './roles'
import { UserRight } from './rights'

const version = 'v1'
const resource = 'users/me'

export const useGetCurrentUser = () => {
  const axios = useAxios<User>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getCurrentUser = () => axios.exec({})

  return {
    ...axios,
    getCurrentUser,
  }
}

export const useUpdateCurrentUser = () => {
  const axios = useAxios<User>({
    method: 'put',
    url: `/${version}/${resource}`,
  })

  const updateCurrentUser = (user: UserUpdate) => axios.exec({ data: user })

  return {
    ...axios,
    updateCurrentUser,
  }
}

export const useUpdateCurrentUserPartial = () => {
  const axios = useAxios<User>({
    method: 'patch',
    url: `/${version}/${resource}`,
  })

  const updateCurrentUserPartial = (user: UserUpdate) => axios.exec({ data: user })

  return {
    ...axios,
    updateCurrentUserPartial,
  }
}

export const useChangePassword = () => {
  const axios = useAxios<void>({
    method: 'put',
    url: `/${version}/${resource}/password`,
  })

  const changePassword = (newPassword: string, oldPassword: string) =>
    axios.exec({
      data: {
        password: newPassword,
        verification: oldPassword,
      },
    })

  return {
    ...axios,
    changePassword,
  }
}

export const useGetCurrentUserRole = () => {
  return {
    ...useAxios<UserRole>({
      method: 'get',
      url: `/${version}/${resource}/role`,
    }),
  }
}

export const useGetCurrentUserRights = () => {
  return {
    ...useAxios<UserRight[]>({
      method: 'get',
      url: `/${version}/${resource}/role/rights`,
    }),
  }
}
