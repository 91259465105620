
import { defineComponent, ref } from '@vue/composition-api'

import { useChangePassword } from '@/api/me'
import { useNotify } from '@/store'
import { ValidationForm, required } from '@/utils/validation'

import { mdiCheck } from '@mdi/js'

import CommonNewPasswordInput from '@/components/common/common-new-password-input.vue'

export default defineComponent({
  name: 'change-password-card',
  components: {
    CommonNewPasswordInput,
  },
  props: {
    resetToken: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { root }) {
    const { addNotification } = useNotify()
    const { changePassword: changePasswordRequest } = useChangePassword()

    const changePasswortForm = ref<ValidationForm | null>(null)

    const newPassword = ref('')
    const oldPassword = ref('')

    const changePassword = () => {
      if (changePasswortForm.value?.validate()) {
        changePasswordRequest(newPassword.value, oldPassword.value)
          .then(() => {
            addNotification({
              type: 'success',
              text: root.$t('changePassword.success') as string,
            })
          })
          .catch((error) => {
            error.userMessage = root.$t('changePassword.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      changePasswortForm,
      required,
      oldPassword,
      newPassword,
      changePassword,
    }
  },
})
