<script>
  import { defineComponent } from '@vue/composition-api'

  import CommonAppBar from '@/layouts/common/app-bar.vue'
  import CommonFooter from '@/layouts/common/common-footer.vue'
  import Notify from '@/components/notify/notify.vue'

  export default defineComponent({
    name: 'admin-layout',
    components: {
      CommonAppBar,
      CommonFooter,
      Notify,
    },
  })
</script>

<template>
  <v-app>
    <common-app-bar />

    <notify />

    <v-main>
      <router-view />
    </v-main>
    <common-footer />
  </v-app>
</template>

<style scoped></style>
