
import { computed, defineComponent, ref } from '@vue/composition-api'

import { useGetQuestionnaire, useGetQuestionInstances, QuestionInstanceWithQuestion } from '@/api/questionnaires'
import { Question, QuestionCategories, QuestionFilter, useGetQuestions } from '@/api/questions'
import { useClientsGetters } from '@/store'

import { mdiClose, mdiChevronUp, mdiChevronDown } from '@mdi/js'

import QuestionCategory from './question-category.vue'

export default defineComponent({
  name: 'questionnaire-view',

  components: {
    QuestionCategory,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup: (props, { root }) => {
    const { getQuestionnaire, data: questionnaire } = useGetQuestionnaire()

    const { ownClient } = useClientsGetters(root.$store)

    const { getQuestions } = useGetQuestions()
    const { getQuestionInstances } = useGetQuestionInstances()

    const sortQuestionPool = ref<Question[]>([])
    const sortQuestions = ref<QuestionInstanceWithQuestion[]>([])
    const setInOrderQuestionPool = ref<Question[]>([])
    const setInOrderQuestions = ref<QuestionInstanceWithQuestion[]>([])
    const shineQuestionPool = ref<Question[]>([])
    const shineQuestions = ref<QuestionInstanceWithQuestion[]>([])
    const standardizeQuestionPool = ref<Question[]>([])
    const standardizeQuestions = ref<QuestionInstanceWithQuestion[]>([])
    const sustainQuestionPool = ref<Question[]>([])
    const sustainQuestions = ref<QuestionInstanceWithQuestion[]>([])

    const filterPoolQuestions = (questionsInQuestionnaire: Question[], questions: Question[]) => {
      const questionsInQuestionnaireIds = questionsInQuestionnaire.map((question) => question.id)
      return questions.filter((question) => !questionsInQuestionnaireIds.includes(question.id))
    }

    const initialDataLoading = ref(true)

    const loadInitialData = () => {
      Promise.all([getQuestionnaire(props.id), getQuestionInstances(props.id)]).then(
        ([questionnaire, questionInstances]) => {
          const questionsInQuestionnaire = questionInstances.map((questionInstance) => questionInstance.template)
          sortQuestions.value = questionInstances.filter(
            (questionInstance) => questionInstance.template.category === QuestionCategories.SORT
          )
          setInOrderQuestions.value = questionInstances.filter(
            (questionInstance) => questionInstance.template.category === QuestionCategories.SET_IN_ORDER
          )
          shineQuestions.value = questionInstances.filter(
            (questionInstance) => questionInstance.template.category === QuestionCategories.SHINE
          )
          standardizeQuestions.value = questionInstances.filter(
            (questionInstance) => questionInstance.template.category === QuestionCategories.STANDARDIZE
          )
          sustainQuestions.value = questionInstances.filter(
            (questionInstance) => questionInstance.template.category === QuestionCategories.SUSTAIN
          )

          let promises: Promise<Question[]>[] = []

          const filter: QuestionFilter = {
            zoneType: questionnaire.zoneType,
          }

          if (questionnaire.client) {
            filter.client = questionnaire.client
            filter.public = true
          } else {
            filter.public = true
          }

          promises = [
            getQuestions(
              { page: 0, size: 9999 },
              {
                ...filter,
                category: QuestionCategories.SORT,
              }
            ),
            getQuestions(
              { page: 0, size: 9999 },
              {
                ...filter,
                category: QuestionCategories.SET_IN_ORDER,
              }
            ),
            getQuestions(
              { page: 0, size: 9999 },
              {
                ...filter,
                category: QuestionCategories.SHINE,
              }
            ),
            getQuestions(
              { page: 0, size: 9999 },
              {
                ...filter,
                category: QuestionCategories.STANDARDIZE,
              }
            ),
            getQuestions(
              { page: 0, size: 9999 },
              {
                ...filter,
                category: QuestionCategories.SUSTAIN,
              }
            ),
          ]

          Promise.all(promises).then((questions) => {
            sortQuestionPool.value = filterPoolQuestions(questionsInQuestionnaire, questions[0])
            setInOrderQuestionPool.value = filterPoolQuestions(questionsInQuestionnaire, questions[1])
            shineQuestionPool.value = filterPoolQuestions(questionsInQuestionnaire, questions[3])
            standardizeQuestionPool.value = filterPoolQuestions(questionsInQuestionnaire, questions[2])
            sustainQuestionPool.value = filterPoolQuestions(questionsInQuestionnaire, questions[4])
            initialDataLoading.value = false
          })
        }
      )
    }

    loadInitialData()

    return {
      icons: { mdiClose, mdiChevronUp, mdiChevronDown },
      isAuditingModeFiveS: computed(() => ownClient.value?.auditingMode === 'FIVE_S'),
      initialDataLoading,
      questionnaire,
      sortQuestionPool,
      sortQuestions,
      setInOrderQuestionPool,
      setInOrderQuestions,
      shineQuestionPool,
      shineQuestions,
      standardizeQuestionPool,
      standardizeQuestions,
      sustainQuestionPool,
      sustainQuestions,
      QuestionCategories,
    }
  },
})
