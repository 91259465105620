
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { Question, useUpdateQuestion } from '@/api/questions'
import { useNotify } from '@/store'
import { ValidationForm } from '@/utils/validation'

import { mdiCheck } from '@mdi/js'

import QuestionForm from './question-form.vue'

export default defineComponent({
  name: 'admin-question-detail',
  components: {
    QuestionForm,
  },
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()
    const { updateQuestion, isLoading } = useUpdateQuestion()

    const editQuestionForm = ref<ValidationForm | null>(null)

    const questionToEdit = ref<Question>({
      ...props.question,
    })

    const editQuestion = () => {
      if (editQuestionForm.value?.validate()) {
        updateQuestion({
          ...questionToEdit.value,
        })
          .then((editedQuestion) => {
            addNotification({
              type: 'success',
              text: root.$t('questions.update.success') as string,
            })
            emit('question-edited', editedQuestion)
          })
          .catch((error) => {
            error.userMessage = root.$t('questions.update.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      editQuestionForm,
      questionToEdit,
      editQuestion,
      isLoading,
    }
  },
})
