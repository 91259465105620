
import { Audit, AuditInput, useUpdateAudit } from '@/api/audits'
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { mdiCheck, mdiArrowLeft } from '@mdi/js'

import AuditForm from '@/views/audits/audit-form.vue'
import { ValidationForm } from '@/utils/validation'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'configure-audit',

  components: {
    AuditForm,
  },

  props: {
    audit: {
      type: Object as PropType<Audit>,
      required: true,
    },
  },

  setup: (props, { root }) => {
    const { updateAudit, isLoading } = useUpdateAudit()
    const { addNotification } = useNotify()

    const auditToEdit = ref<AuditInput>({
      ...props.audit,
      questionnaire: props.audit.questionnaire.id,
    })

    const editAuditForm = ref<ValidationForm | null>(null)

    const editAudit = () => {
      if (editAuditForm.value?.validate()) {
        updateAudit(props.audit.id, auditToEdit.value)
          .then(() => {
            addNotification({
              type: 'success',
              text: root.$t('audits.update.success') as string,
            })
            root.$router.back()
          })
          .catch((error) => {
            error.userMessage = root.$t('audits.update.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck, mdiArrowLeft },
      auditToEdit,
      editAuditForm,
      editAudit,
      isLoading,
    }
  },
})
