<template>
  <v-container>
    <v-card>
      <v-card-title class="text-h4 primary onPrimary--text"> {{ $t('termsOfUse.title') }}</v-card-title>
      <v-card-text>
        <br />
        <h2> § 1 Geltungsbereich </h2>
        <br />
        <p
          >(1) Die folgenden Bedingungen gelten für die Nutzung der Software "5S Audits", welche von der Frachtwerk GmbH
          – im folgenden "Anbieter" genannt – als Software as a Service bereitgestellt wird.
        </p>

        <h2> § 2 Vertragsgegenstand </h2>
        <br />
        <p>
          (1) Vertragsgegenstand ist die Überlassung von Software zur Nutzung über das Internet. Das beinhaltet
          ausschließlich:
        </p>
        <ul>
          <li>
            Die Bereitstellung einer App zur Durchführung von 5S-Audits in den Appstores von Google Inc. und Apple Inc.
          </li>
          <li> Die Bereitstellung einer Webanwendung zur Auswertung der durchgeführten 5S-Audits </li>
          <li> Die Speicherung der erhobenen Daten im Auftrag durch den Anbieter. </li>
        </ul>
        <br />
        <p>
          (2) Der Anbieter stellt die vertragsgegenständlichen Leistungen, insbesondere den Zugang zur Software, in
          ihrem Verfügungsbereich (ab Schnittstelle Server zum Internet bzw. ab Einstellung in die Appstores) bereit. Es
          obliegt dem Kunden, die technischen Voraussetzungen zur Empfangnahme der Software am Übergabepunkt und ihrer
          Nutzung zu schaffen.
        </p>

        <p>
          (3) Darüber hinausgehende Leistungen, etwa die Entwicklung kundenindividueller Lösungen oder erforderliche
          Anpassungen bedürfen eines gesonderten Vertrages.
        </p>

        <p> (4) Der Anbieter kann jederzeit aktualisierte Versionen der Software bereitstellen. </p>

        <p>
          (5) Der Anbieter wird den Kunden über aktualisierte Versionen und entsprechende Nutzungshinweise auf
          elektronischem Wege informieren und diese entsprechend verfügbar machen.
        </p>

        <h2> § 3 Vergütung und Zahlungsbedingungen </h2>
        <br />
        <p>
          (1) Der Anbieter stellt dem Kunden die 5S-App für drei Tage kostenfrei zur Verfügung, ab dem 4. Tag ist zur
          Nutzung der Erwerb einer Lizenz notwendig. Lizenzen können als einzelne Lizenzen über die Appstores von Google
          Inc. und Apple Inc. erworben werden. Die Zahlung erfolgt in diesem Fall über die Zahlungsfunktionen der
          App-Stores.
        </p>

        <p>
          (2) Volumenlizenzen werden über die Vertriebswebsite der Anwendung oder über den Enterprise Sales vertrieben.
          Die Verrechnung erfolgt in diesem Fall per Überweisung und ist ohne Abzug binnen 30 Tagen zu überweisen.
        </p>

        <h2> § 4 Nutzungsrechte </h2>
        <br />
        <h3> § 4.1 Rechte an der Software </h3>
        <br />
        <p>
          (1) Der Anbieter stellt dem Kunden ein auf die Dauer der Vertragslaufzeit zeitlich begrenztes,
          nicht-exklusives Nutzungsrecht an der Software inkl. der App zur Verfügung. Das Nutzungsrecht wird dabei in
          Form einer Einzelplatz-Lizenz vergeben.
        </p>

        <p>
          (2) Die vertragsgegenständlichen Leistungen dürfen nur durch den Kunden und nur zu den im Vertrag vereinbarten
          Zwecken verwendet werden. Der Kunde darf während der Laufzeit des Vertrages auf die vertragsgegenständlichen
          Leistungen mittels Telekommunikation (über das Internet) zugreifen und mittels eines Browsers oder einer
          anderen geeigneten Anwendung (z.B. „App“) die mit der Software verbundenen Funktionalitäten vertragsgemäß
          nutzen. Darüber hinausgehende Rechte, insbesondere an der Software oder den ggf. bereitgestellten
          Infrastrukturleistungen im jeweiligen Rechenzentrum erhält der Kunde nicht. Jede weitergehende Nutzung bedarf
          der vorherigen schriftlichen Zustimmung des Anbieters.
        </p>

        <p>
          (3) Der Kunde darf die Software insbesondere nicht über den vereinbarten Nutzungsumfang hinaus in Anspruch
          nehmen oder von Dritten nutzen lassen oder sie Dritten zugänglich machen. Insbesondere ist es dem Kunden nicht
          gestattet, Software oder Teile davon zu vervielfältigen, zu veräußern oder zeitlich begrenzt zu überlassen, zu
          vermieten oder zu verleihen.
        </p>
        <h3> § 4.2 Rechte an den Daten </h3>
        <br />
        <p>
          (1) Die vom Kunden erfassten Daten werden durch den Anbieter gespeichert. Der Kunde behält zu jeder Zeit alle
          Rechte an den Daten und kann daher vom Anbieter jederzeit Herausgabe oder Löschung verlangen. Die Herausgabe
          erfolgt durch einen digitalen Versand durch den Anbieter. Der Kunde hat keinen Anspruch an ein bestimmtes
          Format oder auf eine Software zur Interpretation der Daten.
        </p>

        <h2> § 5 Mitwirkungspflicht des Kunden </h2>
        <br />
        <p>
          (1) Der Kunde hat die ihm bzw. den Nutzern zugeordneten Zugangsberechtigungen sowie Identifikations und
          Authentifikationsinformationen vor dem Zugriff durch Dritte zu schützen und nicht an Unberechtigte
          weiterzugeben. Sofern der Kunden Kenntnis von unberechtigter Einsichtnahme der eigenen Zugangsdaten durch
          Dritte erlangt, ist er verpflichtet, diese unverzüglich zu ändern. Ein Verlust der Zugangsdaten ist
          unverzüglich dem Anbieter anzuzeigen.
        </p>

        <p>
          (2) Der Kunde ist verpflichtet, den Anbieter von sämtlichen Ansprüchen Dritter aufgrund von Rechtsverletzungen
          freizustellen, die auf einer rechtswidrigen Verwendung des Leistungsgegenstands durch ihn beruhen oder mit
          seiner Billigung erfolgen. Erkennt der Kunde oder muss er erkennen, dass ein solcher Verstoß droht, besteht
          die Pflicht zur unverzüglichen Unterrichtung des Anbieters.
        </p>

        <p>
          (3) Der Kunde hat vom Anbieter zur Verfügung gestellte Möglichkeiten zu nutzen, seine Daten in seinem
          originären Verantwortungsbereich zu sichern.
        </p>

        <p>
          (4) Der Kunde ist verpflichtet, Fehlfunktionen und Sicherheitslücken an den Anbieter zu melden, sobald diese
          dem Kunden bekannt werden.
        </p>

        <h2> § 6 Zuwiderhandlungen </h2>
        <br />
        <p>
          (1) Der Anbieter ist berechtigt, angemessene technische Maßnahmen zum Schutz vor einer nicht vertragsgemäßen
          Nutzung zu treffen. Der vertragsgemäße Einsatz der Leistungen darf dadurch nicht mehr als nur unwesentlich
          beeinträchtigt werden.
        </p>

        <p>
          (2) Im Falle eines vertragswidrigen Überschreitens des Nutzungsumfangs durch einen Nutzer oder im Falle einer
          unberechtigten Nutzungsüberlassung hat der Kunde dem Anbieter auf Verlangen unverzüglich sämtliche ihm
          verfügbaren Angaben zur Geltendmachung der Ansprüche wegen der vertragswidrigen Nutzung zu machen,
          insbesondere Name und Anschrift des Nutzers mitzuteilen.
        </p>
        <p>
          (3) Der Anbieter kann die Zugangsberechtigung des Kunden widerrufen und / oder den Vertrag kündigen, wenn der
          Kunde die ihm gestattete Nutzung erheblich überschreitet oder gegen Regelungen zum Schutz vor unberechtigter
          Nutzung verstößt. Damit verbunden kann der Anbieter den Zugriff auf die vertraglichen Leistungen unterbrechen
          bzw. sperren. Der Anbieter hat dem Kunden vorher grundsätzlich eine angemessene Nachfrist zur Abhilfe zu
          setzen. Der alleinige Widerruf der Zugangsberechtigung gilt nicht zugleich als Kündigung des Vertrages. Den
          Widerruf der Zugangsberechtigung ohne Kündigung kann der Anbieter nur für eine angemessene Frist, maximal 3
          Monate, aufrechterhalten.
        </p>
        <p>
          (4) Der Anspruch des Anbieters auf eine Vergütung für die über die vereinbarte Nutzung hinausgehende Nutzung
          bleibt unberührt.
        </p>
        <p>
          (5) Der Kunde hat einen Anspruch auf Wiedereinräumung der Zugangsberechtigung und der Zugriffsmöglichkeit,
          nachdem er nachgewiesen hat, dass er die vertragswidrige Nutzung eingestellt und eine zukünftige
          vertragswidrige Nutzung unterbunden hat.
        </p>
        <p>
          (6) Für jeden Fall, in dem im Verantwortungsbereich des Kunden unberechtigt eine vertragsgegenständliche
          Leistung in Anspruch genommen wird, hat der Kunde jeweils Schadensersatz in Höhe derjenigen Vergütung zu
          leisten, die für die vertragsgemäße Nutzung im Rahmen der für diese Leistung geltenden Mindestvertragsdauer
          angefallen wäre. Der Nachweis, dass der Kunde die unberechtigte Nutzung nicht zu vertreten hat oder kein oder
          ein wesentlich geringerer Schaden vorliegt, bleibt dem Kunden vorbehalten. Der Anbieter bleibt berechtigt,
          einen weitergehenden Schaden geltend zu machen.
        </p>
        <h2> § 7 Servicelevel </h2>
        <br />
        <p>
          (1) Der Anbieter überlässt dem Kunden die Software mit einer Verfügbarkeit von 99% im Monatsmittel. Der
          Anbieter verpflichtet sich, Wartungen an der Software außerhalb der Kernzeiten von 9 bis 17 Uhr durchzuführen
          und diese auf digitalem Wege im voraus anzukündigen. Davon ausgenommen sind Wartungen, die notwendig sind, um
          unmittelbar schwere Fehlfunktionen oder Sicherheitsmängel zu beseitigen. Angekündigte Wartungsfenster bleiben
          bei der Berechnung der Verfügbarkeitsquote unberücksichtigt.
        </p>
        <p>
          (2) Bei einer nur unerheblichen Minderung der Tauglichkeit der Leistungen zum vertragsgemäßen Gebrauch
          bestehen keine Ansprüche des Kunden wegen Mängeln. Die verschuldensunabhängige Haftung des Anbieters wegen
          Mängeln, die bereits zum Zeitpunkt des Vertragsschlusses vorhanden waren, ist ausgeschlossen.
        </p>
        <h2> § 8 Störungsmanagement </h2>
        <br />
        <p>
          (1) Der Anbieter wird Störungsmeldungen des Kunden entgegennehmen, den hier vereinbarten Störungskategorien
          zuordnen und anhand dieser Zuordnung die vereinbarten Maßnahmen zur Analyse und Bereinigung von Störungen
          durchführen.
        </p>
        <p>
          (2) Der Anbieter wird während seiner üblichen Geschäftszeiten ordnungsgemäße Störungsmeldungen des Kunden
          entgegennehmen und jeweils mit einer Kennung versehen. Auf Anforderung des Kunden bestätigt ihm der Anbieter
          den Eingang einer Störungsmeldung unter Mitteilung der vergebenen Kennung.
        </p>
        <p>
          (3) Soweit nichts anderes vereinbart ist, wird der Anbieter entgegengenommene Störungsmeldungen nach erster
          Sichtung einer der folgenden Kategorien zuordnen:
        </p>
        <ul>
          <li>
            <i>Schwerwiegende Störung</i>
            <br />
            Die Störung beruht auf einem Fehler der vertragsgegenständlichen Leistungen, der die Nutzung der
            vertragsgegenständlichen Leistungen, insbesondere der Software, unmöglich macht oder nur mit schwerwiegenden
            Einschränkungen erlaubt. Der Kunde kann dieses Problem nicht in zumutbarer Weise umgehen und deswegen
            unaufschiebbare Aufgaben nicht erledigen.
          </li>
          <li>
            <i>Sonstige Störung</i>
            <br />
            Die Störung beruht auf einem Fehler der vertragsgegenständlichen Leistungen, der die Nutzung der
            vertragsgegenständlichen Leistungen, insbesondere der Software, durch den Kunden mehr als nur unwesentlich
            einschränkt, ohne dass eine schwerwiegende Störung vorliegt.
          </li>
          <li>
            <i>Sonstige Meldung</i>
            <br />
            Störungsmeldungen, die nicht in die genannten Kategorien fallen, werden den sonstigen Meldungen zugeordnet.
            Sonstige Meldungen werden vom Anbieter nur nach den dafür getroffenen Vereinbarungen behandelt.
          </li>
        </ul>
        <br />
        <p>
          (4) Bei Meldungen über schwerwiegende Störungen und sonstige Störungen wird der Anbieter binnen acht Stunden
          (schwerwiegende Störungen) bzw. 24 Stunden (sonstige Störungen) anhand der vom Kunden mitgeteilten Umstände
          entsprechende Maßnahmen einleiten, um zunächst die Störungsursache zu lokalisieren.
        </p>
        <p>
          (5) Stellt sich die mitgeteilte Störung nach erster Analyse nicht als Fehler der vertragsgegenständlichen
          Leistungen, insbesondere der bereitgestellten Software, dar, teilt der Anbieter dies dem Kunden unverzüglich
          mit.
        </p>
        <p>
          (6) Sonst wird der Anbieter entsprechende Maßnahmen zur weitergehenden Analyse und zur Bereinigung der
          mitgeteilten Störung veranlassen oder – bei Drittsoftware – die Störungsmeldung zusammen mit seinen
          Analyseergebnissen dem Vertreiber oder Hersteller der Drittsoftware mit der Bitte um Abhilfe übermitteln.
        </p>
        <p>
          (7) Der Anbieter wird dem Kunden ihm vorliegende Maßnahmen zur Umgehung oder Bereinigung eines Fehlers der
          vertragsgegenständlichen Leistungen, insbesondere der bereitgestellten Software, etwa Handlungsanweisungen
          oder Korrekturen der bereitgestellten Software, unverzüglich zur Verfügung stellen. Der Kunde wird solche
          Maßnahmen zur Umgehung oder Bereinigung von Störungen unverzüglich übernehmen und dem Anbieter bei deren
          Einsatz etwa verbleibende Störungen unverzüglich erneut melden.
        </p>

        <h2> § 9 Gewährleistung </h2>
        <br />
        <p>
          (1) Die Software wird durch den Anbieter nach Stand der Technik entwickelt, trotzdem lassen sich Fehler nicht
          grundsätzlich ausschließen. Der Anbieter verpflichtet sich, Fehler, durch die eine Einschränkung der
          Nutzungsmöglichkeiten erfolgt, zeitnah und unentgeltlich zu beseitigen.
        </p>
        <p>
          (2) Gewährleistungsansprüche, die aus Fehlern resultieren, die aus einer nicht vertragsgemäßen Nutzung der
          Software entstehen, sind ausgeschlossen.
        </p>
        <h2> § 10 Haftungsbeschränkung </h2>
        <br />
        <p>
          (1) Der Anbieter haftet für Schäden, die durch die Nutzung der Software entstehen und die durch den Anbieter
          verursacht wurden bis zu einer Höhe von 100.000€ bei Personenschäden, 50.000€ bei Vermögens- und Sachschäden
          sowie bis zu einer Höhe von 10.000€ für Datenverlustschäden.
        </p>
        <p>
          (2) Eine Haftung für entgangenen Gewinn ist ausgeschlossen. Ebenso kann keine Haftung für Schäden übernommen
          werden, die dadurch entstehen, dass Zugangsdaten Dritten zugänglich gemacht wurden.
        </p>

        <h2> § 11 Verarbeitung personenbezogener Daten </h2>
        <br />
        <p>
          (1) Soweit der Anbieter auf personenbezogene Daten des Kunden oder aus dessen Bereich zugreifen kann, wird er
          ausschließlich als Auftragsverarbeiter tätig und diese Daten nur zur Vertragsdurchführung verarbeiten und
          nutzen. Der Anbieter wird Weisungen des Kunden für den Umgang mit diesen Daten beachten. Der Kunde trägt
          etwaige nachteilige Folgen solcher Weisungen für die Vertragsdurchführung. Der Kunde wird mit dem Anbieter die
          Details für den Umgang des Anbieters mit den Daten des Kunden nach den datenschutzrechtlichen Anforderungen
          vereinbaren.
        </p>
        <p>
          (2) Der Kunde bleibt sowohl allgemein im Auftragsverhältnis als auch im datenschutzrechtlichen Sinne der
          Verantwortliche. Verarbeitet der Kunde im Zusammenhang mit dem Vertrag personenbezogene Daten (einschließlich
          Erhebung und Nutzung), so steht er dafür ein, dass er dazu nach den anwendbaren, insbesondere
          datenschutzrechtlichen Bestimmungen berechtigt ist und stellt im Falle eines Verstoßes den Anbieter von
          Ansprüchen Dritter frei.
        </p>
        <p>
          (3) Für das Verhältnis zwischen Anbieter und Kunde gilt: Gegenüber der betroffenen Person trägt die
          Verantwortung für die Verarbeitung (einschließlich Erhebung und Nutzung) personenbezogener Daten der Kunde,
          außer soweit der Anbieter etwaige Ansprüche der betroffenen Person wegen einer ihm zuzurechnenden
          Pflichtverletzung zu vertreten hat. Der Kunde wird etwaige Anfragen, Anträge und Ansprüche der betroffenen
          Person verantwortlich prüfen, bearbeiten und beantworten. Das gilt auch bei einer Inanspruchnahme des
          Anbieters durch die betroffene Person. Der Anbieter wird den Kunden im Rahmen seiner Pflichten unterstützen.
        </p>

        <p>
          (4) Der Anbieter gewährleistet, dass Daten des Kunden ausschließlich im Gebiet der Bundesrepublik Deutschland,
          in einem Mitgliedsstaat der Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den
          Europäischen Wirtschaftsraum gespeichert werden, soweit nichts anderes vereinbart ist.
        </p>

        <p> (5) Im Übrigen gelten die Datenschutzbestimmungen des Anbieters. </p>

        <h2> § 12 Vertragsdauer und Kündigung </h2>
        <br />
        <p>
          (1) Die Erbringung der vertraglich vereinbarten Leistungen erfolgt ab dem im Vertrag bezeichneten Datum
          zunächst für die Dauer von einem Jahr. Während dieser Mindestlaufzeit ist eine vorzeitige ordentliche
          Kündigung beidseitig ausgeschlossen.
        </p>

        <p>
          (2) Der Vertrag kann mit einer Frist von drei Monaten zum Ende von jeweils einem Jahr gekündigt werden,
          frühestens zum Ablauf der Mindestlaufzeit. Geschieht dies nicht, verlängert sich der Vertrag jeweils um ein
          weiteres Jahr, sofern er nicht mit einer Frist von 3 Monaten zum Ablauf des jeweiligen Verlängerungszeitraums
          ordentlich gekündigt wurde.
        </p>

        <p>
          (3) Das Recht jedes Vertragspartners zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
        </p>

        <p>
          (4) Der Kunde wird rechtzeitig vor Beendigung des Vertrages seine Datenbestände eigenverantwortlich sichern
          (etwa durch Download). Auf Wunsch wird der Anbieter den Kunden dabei unterstützen. Eine Zugriffsmöglichkeit
          des Kunden auf diese Datenbestände wird nach Beendigung des Vertrages schon aus datenschutzrechtlichen Gründen
          regelmäßig nicht mehr gegeben sein.
        </p>

        <p> (5) Der Anbieter wird alle Daten bis spätestens 30 Tage nach Vertragsende unwiderruflich löschen. </p>

        <h2> § 13 Schlussbestimmungen </h2>
        <br />
        <p> (1) Es gilt deutsches Recht. Die Anwendung des UN-Kaufrechts ist ausgeschlossen. </p>
        <p>
          (2) Der Anbieter erbringt seine Leistungen unter Zugrundelegung dieser Allgemeinen Geschäftsbedingungen (AGB).
          AGB des Kunden finden keine Anwendung, auch wenn der Anbieter solchen nicht ausdrücklich widersprochen hat.
        </p>
        <p>
          (3) Die Annahme der Leistungen durch den Kunden gilt als Anerkennung der AGB des Anbieters unter Verzicht auf
          AGB des Kunden.
        </p>
        <p>
          (4) Andere Bedingungen sind nur verbindlich, wenn der Anbieter sie schriftlich anerkannt hat; ergänzend gelten
          dann die AGB des Anbieters.
        </p>
        <p>
          (5) Gerichtsstand gegenüber einem Kaufmann, einer juristischen Person des öffentlichen Rechts oder einem
          öffentlichrechtlichen Sondervermögen ist der Sitz des Anbieters. Der Anbieter kann den Kunden auch an dessen
          Sitz verklagen.
        </p>
        <p>
          (6) Sollte eine Bestimmung des Vertrags unwirksam sein oder werden, so berührt dies die Gültigkeit des
          Vertrags im Übrigen nicht. Die Vertragspartner verpflichten sich, die unwirksame Bestimmung durch eine solche
          zu ersetzen, die dem wirtschaftlich Gewollten am nächsten kommt. Gleiches gilt, wenn eine Vertragslücke
          offenbar werden sollte.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  export default defineComponent({
    name: 'terms-of-use-view',
  })
</script>

<style></style>
