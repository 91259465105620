
import { defineComponent, ref } from '@vue/composition-api'

import { authApi } from '@/api'
import { useNotify } from '@/store'
import { ValidationForm, required, email as emailRule } from '@/utils/validation'

import { mdiEmail } from '@mdi/js'

export default defineComponent({
  name: 'reset-password-view',
  props: {
    email: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup: (props, { root }) => {
    const { resetPassword } = authApi.useResetPassword()

    const { addNotification } = useNotify()

    const resetPasswordForm = ref<ValidationForm | null>(null)

    const emailInput = ref(props.email)

    const reset = () => {
      if (resetPasswordForm.value?.validate()) {
        resetPassword(emailInput.value)
          .then(() => {
            addNotification({ text: root.$t('resetPassword.success') as string, type: 'success' })
            root.$router.push('/login')
          })
          .catch((error) => {
            error.userMessage = root.$t('resetPassword.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiEmail },
      resetPasswordForm,
      required,
      emailRule,
      emailInput,
      reset,
    }
  },
})
