
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

import { translationsApi } from '@/api'
import { useNotify } from '@/store'
import { ValidationForm, required } from '@/utils/validation'

import { mdiUpload, mdiDownload, mdiEarth } from '@mdi/js'

import CommonFlagIcon from '@/components/common/common-flag-icon.vue'
import { resolveCountry } from '@/plugins/i18n'

export default defineComponent({
  name: 'admin-import-export-translations',
  components: {
    CommonFlagIcon,
  },
  props: {
    allLocales: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  setup: (props, { emit, root }) => {
    const { addNotification } = useNotify()
    const localeFileUploadForm = ref<ValidationForm | null>(null)

    const fileLocale = ref('')
    const fileInput = ref<File | null>(null)

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const { uploadTranslationsFile } = translationsApi.useUploadTranslationsFile()

    const uploadTranslationFile = () => {
      if (localeFileUploadForm.value?.validate()) {
        if (fileInput.value) {
          uploadTranslationsFile(fileInput.value, fileLocale.value)
            .then(() => {
              addNotification({
                type: 'success',
                text: root.$t('translations.upload.success') as string,
              })

              // fileModalActive.value = false

              //TODO: Fix ugly reload
              emit('file-uploaded')
              location.reload()
            })
            .catch((error) => {
              error.userMessage = root.$t('translations.upload.error')
              throw error
            })
        }
      }
    }

    return {
      icons: { mdiUpload, mdiDownload, mdiEarth },
      localeFileUploadForm,
      fileInput,
      fileLocale,
      uploadTranslationFile,
      implementedLocales,
      resolveCountry,
      required,
    }
  },
})
