import { useAxios, useAxiosPaginated } from '@/utils/axios'
import { PaginationParams } from '@/utils/pagination'
import { BaseModel } from '.'

const version = 'v1'
const resource = 'divisions'

export interface DivisionInput {
  client: number
  name: string
  parentDivision: number | null
  responsibleUser: number | null
  site: string
}

export interface Division extends BaseModel, DivisionInput {
  childDivisions: number[] | Division[]
}

export const useGetDivisions = () => {
  const axios = useAxiosPaginated<Division>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getDivisions = (clientId: number, paginationParams?: PaginationParams) =>
    axios.exec({ params: { ...paginationParams, client: clientId } })

  return {
    ...axios,
    getDivisions,
  }
}

export const useGetDivision = () => {
  const axios = useAxios<Division>({
    method: 'get',
  })

  const getDivision = (divisionId: number) => axios.exec({ url: `/${version}/${resource}/${divisionId}` })

  return {
    ...axios,
    getDivision,
  }
}

export const useCreateDivision = () => {
  const axios = useAxios<Division>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createDivision = (newDivision: DivisionInput) => axios.exec({ data: newDivision })

  return {
    ...axios,
    createDivision,
  }
}

export const useUpdateDivision = () => {
  const axios = useAxios<Division>({
    method: 'put',
  })

  const updateDivision = (division: Division) =>
    axios.exec({ url: `/${version}/${resource}/${division.id}`, data: division })

  return {
    ...axios,
    updateDivision,
  }
}

export const useDeleteDivision = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteDivision = (divisionId: number) => axios.exec({ url: `/${version}/${resource}/${divisionId}` })

  return {
    ...axios,
    deleteDivision,
  }
}

export interface UpdateDivisionPartialInput {
  client?: number
  name?: string
  parentDivision?: number
  responsibleUser?: number
  site?: string
}

export const useUpdateDivisionPartial = () => {
  const axios = useAxios<Division>({
    method: 'patch',
  })

  const updateDivisionPartial = (division: UpdateDivisionPartialInput & { id: number }) =>
    axios.exec({ url: `/${version}/${resource}/${division.id}`, data: division })

  return {
    ...axios,
    updateDivisionPartial,
  }
}
