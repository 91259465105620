
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useGetAudits } from '@/api/audits'
import { useGetClients } from '@/api/clients'
import { ClientEntity, DivisionEntity, EntityType, useGetRatingGraph, ZoneEntity } from '@/api/ratingGraphs'
import { User } from '@/api/users'
import { usePagination } from '@/utils/pagination'
import { ratingColor } from '@/utils/ratingColor'
import { computed, defineComponent, onMounted, ref, watch, Ref } from '@vue/composition-api'
import { mdiPrinter } from '@mdi/js'

import * as d3 from 'd3'
import AuditsTable from '../audits/audits-table.vue'

function parseBooleanQuery(query: any, paramName: string, defaultVal: boolean): boolean {
  try {
    return JSON.parse(query[paramName])
  } catch (e) {
    return defaultVal
  }
}

export default defineComponent({
  name: 'operating-numbers-view',

  components: { AuditsTable },

  setup: (_, { root }) => {
    const currentUser: User = root.$store.state.auth.currentUser

    const { getRatingGraph, data: ratingData } = useGetRatingGraph()

    const { vuetifyTableOptions, paginationParams } = usePagination({
      page: root.$route.query.page ? +root.$route.query.page + 1 : 1,
      itemsPerPage: root.$route.query.size ? +root.$route.query.size : 15,
      sortBy: root.$route.query.sort ? [root.$route.query.sort.toString().split(',')[0]] : ['id'],
      sortDesc: root.$route.query.sort ? [root.$route.query.sort.toString().split(',')[1] === 'desc'] : [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true,
    })

    const showAllUnits: Ref<boolean> = ref(!parseBooleanQuery(root.$route.query, 'auditedOnly', false))
    watch(
      () => showAllUnits.value,
      () =>
        root.$router
          .replace({
            query: { ...root.$root.$route.query, auditedOnly: JSON.stringify(!showAllUnits.value) },
          })
          .then(() => {
            audits.value = []
            getGraphData()
          })
    )

    // Get selection of clients for the superadmin
    const { getClients, data: clients, isLoading: isClientsLoading } = useGetClients()
    const client = ref(0)
    if (currentUser.role.name === 'ADMIN') {
      getClients({ page: 0, size: 9999 })
    }

    const onClientSelected = (clientId: number) => {
      if (clientId !== +root.$route.query.entityId || 'CLIENT' !== root.$route.query.entityType) {
        root.$router
          .replace({
            query: { ...root.$route.query, entityId: clientId.toString(), entityType: 'CLIENT', zone: undefined },
          })
          .then(() => {
            audits.value = []
            getGraphData()
          })
      }
    }

    onMounted(() => {
      // prefill client selection if query params are set
      if (root.$route.query.entityType === 'CLIENT' && +root.$route.query.entityId)
        client.value = +root.$route.query.entityId

      // load data if query params are set
      if (+root.$route.query.entityId && root.$route.query.entityType) {
        getGraphData()

        if (root.$route.query.zone) {
          getAudits()
        }

        // set query params and load data if role isn't ADMIN
      } else if (currentUser.role.name !== 'ADMIN') {
        let entityType = 'ZONE'
        let entityId = currentUser.zone.toString()
        let zone: string | undefined = undefined
        client.value = currentUser.client
        if (currentUser.role.name === 'CLIENT_ADMIN') {
          entityType = 'CLIENT'
          entityId = currentUser.client.toString()
        } else if (currentUser.role.name === 'DIVISION_ADMIN') {
          entityType = 'DIVISION'
          entityId = currentUser.division.toString()
        } else if (currentUser.role.name === 'ZONE_ADMIN') {
          entityType = 'ZONE'
          entityId = currentUser.zone.toString()
          zone = currentUser.zone.toString()
        }

        root.$router.replace({ query: { ...root.$route.query, entityId, entityType, zone } }).then(() => {
          getGraphData()
        })
      }
    })

    const getGraphData = () => {
      const auditedOnly = parseBooleanQuery(root.$route.query, 'auditedOnly', false)

      getRatingGraph(+root.$route.query.entityId, root.$route.query.entityType as EntityType, auditedOnly).then(
        (graphData) => {
          createGraph()

          if (graphData.entityType === 'ZONE') {
            selectedZone.value = graphData
            getAudits()
          }
        }
      )
    }

    const selectedZone = ref<ZoneEntity | null>(null)

    const onNodeClicked = (node: d3.HierarchyPointNode<ClientEntity | DivisionEntity | ZoneEntity>) => {
      //return if client or division clicked
      if (node.data.entityType === 'CLIENT' || node.data.entityType === 'DIVISION') return

      selectedZone.value = node.data

      // Reset sorting options
      vuetifyTableOptions.value = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['id'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      }

      if (root.$route.query.zone !== node.data.entityId.toString()) {
        root.$router
          .replace({ query: { ...root.$route.query, zone: node.data.entityId.toString() } })
          .then(() => getAudits())
      } else getAudits()
    }

    const {
      getAudits: getAuditsRequest,
      data: audits,
      isLoading: isAuditsLoading,
      paginationResponse,
    } = useGetAudits()

    const getAudits = () => {
      getAuditsRequest(paginationParams.value, { zone: +root.$route.query.zone }).then(() => {
        // don't update route query if its the save as pagination params (e.g. on reload of the site)
        if (
          paginationParams.value.page !== +root.$route.query.page ||
          paginationParams.value.size !== +root.$route.query.size ||
          paginationParams.value.sort !== root.$route.query.sort
        ) {
          root.$router.replace({ query: { ...root.$route.query, ...(paginationParams.value as any) } })
        }
      })
    }

    const isDark = computed<boolean>(() => (root.$vuetify.theme as any).isDark)

    watch(isDark, () => {
      createGraph()
    })

    const createGraph = () => {
      const nodes = d3.hierarchy(ratingData.value)

      const width = (document.querySelector('#graphContainer') as HTMLDivElement).offsetWidth,
        height = 250 + nodes.height * 200

      const nodeWidth = 75,
        nodeHeight = 50

      var treemap = d3.tree<ClientEntity | DivisionEntity | ZoneEntity>().size([width, (height * 3) / 4])

      const nodemap = treemap(d3.hierarchy<ClientEntity | DivisionEntity | ZoneEntity>(ratingData.value!))

      const svg = d3.select<SVGSVGElement, unknown>('#graphSVG')

      // remove old diagramm
      svg.select('g').remove()

      const g = svg.attr('width', width).attr('height', height).append('g').attr('transform', `translate(0,50)`)

      const linkGen = d3
        .linkVertical<any, d3.HierarchyPointNode<ClientEntity | DivisionEntity | ZoneEntity>, [number, number]>()
        .source((d) => [d.x, d.y - nodeHeight / 2])
        .target((d) => [d.parent!.x, d.parent!.y + nodeHeight / 2])

      // add links
      g.selectAll('.link')
        .data(nodemap.descendants().slice(1))
        .enter()
        .append('path')
        .attr('class', 'link')
        .attr('d', linkGen)

      // add weight text to middle of link
      g.selectAll('.node')
        .data(nodemap.descendants().slice(1))
        .enter()
        .append('rect')
        .attr('x', (d) => d.x + (d.parent!.x - d.x) / 2 - 15)
        .attr('y', (d) => d.y + (d.parent!.y - d.y) / 2 - 13)
        .attr('width', 30)
        .attr('height', 16)
        .attr('fill', isDark.value ? '#1E1E1E' : '#fff')

      // add background to link text
      g.selectAll('.node')
        .data(nodemap.descendants().slice(1))
        .enter()
        .append('text')
        .attr('x', (d) => d.x + (d.parent!.x - d.x) / 2)
        .attr('y', (d) => d.y + (d.parent!.y - d.y) / 2)
        .style('text-anchor', 'middle')
        .style('fill', isDark.value ? '#fff' : '#000')
        .text((d) => `${d.data.weight}/${d.parent!.data.weight}`)

      // adds each node as a group
      var node = g
        .selectAll('.node')
        .data(nodemap.descendants())
        .enter()
        .append('g')
        .attr('class', function (d) {
          return 'node' + (d.children ? ' node--internal' : ' node--leaf')
        })
        .attr('transform', function (d) {
          return 'translate(' + d.x + ',' + d.y + ')'
        })
        .style('cursor', (d) => (d.data.entityType === 'ZONE' ? 'pointer' : 'auto'))
        .on('click', (_, d) => onNodeClicked(d))

      /**
       * adds box to the node colored by type
       * 'CLIENT' = '#BBDEFB'
       * 'DIVISION' = '#DCEDC8'
       * 'ZONE' = '#FFE0B2'
       *
       * Color depending on Ratings
       * */
      node
        .append('rect')
        .attr('width', nodeWidth)
        .attr('height', nodeHeight)
        .attr('stroke', '#ccc')
        .attr('stroke-width', 1)
        .attr('fill', (d) => ratingColor(d.data.rating.meanRating / 5))
        .attr('transform', `translate(-${nodeWidth / 2},-${nodeHeight / 2})`)

      // Adds Name to the node
      node
        .append('text')
        .attr('dy', '.35em')
        .attr('y', function (d) {
          return d.children ? -35 : 35
        })
        .style('font-size', '1rem')
        .style('text-anchor', 'middle')
        .style('fill', isDark.value ? '#fff' : '#000')
        .text(function (d) {
          return d.data.entityName
        })

      // Adds Rating to center of nodes
      node
        .append('text')
        .attr('dy', '.35em')
        .style('font-size', '1.75rem')
        .style('text-anchor', 'middle')
        .style('fill', 'currentColor')
        .text(function (d) {
          return `${Math.round((d.data.rating.meanRating / 5) * 100)}%`
        })
    }

    function onPrint(): void {
      window.print()
    }

    return {
      icons: {
        mdiPrinter,
      },
      getAudits,
      currentUser,
      client,
      clients,
      isClientsLoading,
      onClientSelected,
      ratingData,
      audits,
      showAllUnits,
      isAuditsLoading,
      paginationResponse,
      vuetifyTableOptions,
      onPrint,
    }
  },
})
